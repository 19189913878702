import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import PushContext from "../../../context/PushContext";

const DashBoardCrisisCard = ({
  country,
  countryId,
  needHelp,
  reason,
  className,
  editId,
  latitude,
  longitude,
  shareLocation,
}) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { pushNotifcations, setPushNotifications } = useContext(PushContext);

  const [go, setGo] = useState(false);

  const onClickHandler = () => {
    const pshObject = {
      country,
      countryId,
      needHelp,
      reason,
      editId,
      latitude,
      longitude,
      shareLocation,
    };
    setPushNotifications(() => pshObject);
    setGo(true);
  };

  useEffect(() => {
    if (go) {
      history(ApplicationRoute.pushEditFromDashboard);
    }
  }, [pushNotifcations, go]);

  return (
    <div className={`dashb-crisiscard-container ${className ?? ""}`}>
      <div className="dashb-crisiscard-container__alert-tri-wrapper">
        <img
          className="dashb-crisiscard-container__alert-tri"
          src="./images/common/redcrisis.png"
          alt=""
        />
      </div>

      <p className="dashb-crisiscard-container__title">
        {t("pages.dashboardCrisisCard.crisisStatus")} {t(country)}
      </p>

      <HorizontalRule className="dashb-crisiscard-container__hr1" />

      <p className="dashb-crisiscard-container__content">{t(reason)}</p>

      <HorizontalRule className="dashb-crisiscard-container__hr2" />

      <div className="dashb-crisiscard-container__status-title">
        {t("pages.dashboardCrisisCard.myStatus")}
      </div>

      <div className="dashb-crisiscard-container__status">
        <div className="dashb-crisiscard-container__status__text">
          {t(needHelp ? "pages.dashboardCrisisCard.helpYes" : "pages.dashboardCrisisCard.helpNo")}
        </div>

        <span onClick={onClickHandler}>
          <img
            src="./images/common/editpen.png"
            className="dashb-crisiscard-container__status__img"
            alt=""
          />
        </span>
      </div>
    </div>
  );
};

export default DashBoardCrisisCard;