import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";

const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <ErrorBoundary>
        <HashRouter basename="/">
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </HashRouter>
    </ErrorBoundary>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();