import { useTranslation } from "react-i18next";

function InfoCard({id, onClick, city, ArrowRight, type, isHidden, style}) {
  const { t } = useTranslation();

  return (
    <div hidden={isHidden} aria-hidden={isHidden}>
      <li className="info-cards" id={id}>
        <div className="upper-text">
          {t(type)}
          <div className="info-card__main-text" style={style}>
            {city}
          </div>
        </div>
        <div className="arrow__right" onClick={onClick}>
          {ArrowRight}
        </div>
      </li>
    </div>
  );
}

export default InfoCard;