import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import apipath from "../apipath";
import MainButton from "../common/MainButton";
import ApplicationRoute from "../config/routes";
import HeaderContext from "../context/HeaderContext";
import SuccessContext from "../context/SuccessContext";
import fetcher from "../services/fetcher";
import MetaDecorator from "../utils/MetaDecorator";
import { getUIID } from "../utils/helpers";

const ForgottenPaswordSecondPage = () => {
  const { setHeader } = useContext(HeaderContext);
  const { setSuccessBox } = useContext(SuccessContext);
  const { t } = useTranslation();

  const [, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [timerDissable, setTimerDissable] = useState(false);

  const history = useNavigate();

  const submittingNow = () => {
    return submitting && loading;
  };

  const timerDissabling = () => {
    setTimeout(() => {
      setTimerDissable(() => false);
    }, 30000);
  };

  useEffect(() => {
    if (!loading && !error) {
      timerDissabling();
    }
  }, [loading, error]);

  const resetPassword = () => {
    fetcher(
      `${apipath.path}users/passwordreset`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify({ Email: localStorage.getItem("restoreEmail") }),
      false
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: "no",
        title: t("pages.passwortVergessen.title"),
      };
    });
  }, []);

  const onClickReset = () => {
    setTimerDissable(() => true);
    resetPassword();
  };

  useEffect(() => {
    if (!loading && !error) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.passwortVergessen.success").replace("%email%", localStorage.getItem("restoreEmail")),
          trigger: getUIID(),
        };
      });
    }
  }, [loading, error]);

  const onClickGoHome = () => {
    history(ApplicationRoute.home);
  };

  return (
    <div className="forgotten-password-second-container registration-middle-container">
      <MetaDecorator title="pageTitle.forgottenPassword" />
      <h1 className="forgotten-password-second-container__heading">
        {t("pages.passwortVergessen.title")}
      </h1>

      <p className="forgotten-password-second-container__info">
        {t("pages.passwortVergessen.infoSecond").replace("%email%", localStorage.getItem("restoreEmail"))}
      </p>

      <MainButton
        path={ApplicationRoute.home}
        text="pages.register5.home"
        positioned="static"
        className="forgotten-password-second-container__weiter-button"
        onClick={onClickGoHome}
        disabled={false}
        color="transperant"
      />

      <MainButton
        path={ApplicationRoute.renewPassword}
        text="pages.passwortVergessen.send"
        positioned="static"
        className="forgotten-password-second-container__go-home-button"
        onClick={onClickReset}
        disabled={submittingNow() || timerDissable}
      />
    </div>
  );
};

export default ForgottenPaswordSecondPage;