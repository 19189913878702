import dateAndTime from "date-and-time";
import { Component, createRef } from "react";
import {DayPicker, rangeIncludesDate} from "react-day-picker";
import "react-day-picker/style.css";
import { withTranslation } from "react-i18next";

class DateModalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dayClick: 0,
      selectedRange: {
        from: props.startDate,
        to: props.endDate
      },
      startRef: createRef(),
    };
    this.close = () => {
      this.props.close();
    };

    this.dayClickHandler = (day, modifiers) => {
      if (!modifiers.disabled) {
        if (this.state.dayClick === 0) {
          this.setState({
            dayClick: ++this.state.dayClick,
            selectedRange: {from: day, to: null}
          });
        } else if (this.state.dayClick === 1) {
          let stateObj = {
            dayClick: 0,
            selectedRange: {from: this.state.selectedRange.from, to: day}
          };

          if (day < this.state.selectedRange.from) {
            stateObj.selectedRange = {from: day, to: this.state.selectedRange.from}
          }

          this.setState(stateObj);
        }
      }
    };
  }

  componentDidMount() {
    this.state.startRef.current.focus();
  }

  render() {
    const { t } = this.props;

    const MONTHS = [
      t("input.calendar.months.janner"),
      t("input.calendar.months.februar"),
      t("input.calendar.months.marz"),
      t("input.calendar.months.april"),
      t("input.calendar.months.mai"),
      t("input.calendar.months.juni"),
      t("input.calendar.months.juli"),
      t("input.calendar.months.august"),
      t("input.calendar.months.september"),
      t("input.calendar.months.oktober"),
      t("input.calendar.months.november"),
      t("input.calendar.months.dezember"),
    ];

    const WEEKDAYS_LONG = [
      t("input.calendar.weekdaysLong.sonntag"),
      t("input.calendar.weekdaysLong.montag"),
      t("input.calendar.weekdaysLong.dienstag"),
      t("input.calendar.weekdaysLong.mittwoch"),
      t("input.calendar.weekdaysLong.donnerstag"),
      t("input.calendar.weekdaysLong.freitag"),
      t("input.calendar.weekdaysLong.samstag"),
    ];

    const WEEKDAYS_SHORT = [
      t("input.calendar.weekdaysShort.so"),
      t("input.calendar.weekdaysShort.mo"),
      t("input.calendar.weekdaysShort.di"),
      t("input.calendar.weekdaysShort.mi"),
      t("input.calendar.weekdaysShort.do"),
      t("input.calendar.weekdaysShort.fr"),
      t("input.calendar.weekdaysShort.sa"),
    ]

    let heading = " ";
    if (this.state.selectedRange.from)
      heading = `${dateAndTime.format(this.state.selectedRange.from, "DD.MM.")} - ${this.state.selectedRange.to ? dateAndTime.format(this.state.selectedRange.to, "DD.MM.") : ""}`;

    return (
      <section id="date-modal" className="date-modal">
        <header className="date-modal__header">
          <button className="date-modal__close-button" onClick={this.close} type="button" ref={this.state.startRef}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <g fill="none" fillRule="evenodd">
                <g fill="#FFF">
                  <g>
                    <path
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                      transform="translate(-24 -21) translate(19 16)"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </button>

          <button
            aria-label={t("terms.save")}
            className="date-modal__save-button"
            type="button"
            onClick={() =>
              this.props.sendData(
                this.state.selectedRange.from,
                this.state.selectedRange.to == null ? this.state.selectedRange.from : this.state.selectedRange.to
              )
            }
          >
            {t("terms.save")}
          </button>

          <div className="date-modal__header-title-wrapper">
            <p className="date-modal__label">{t("input.travelTime.text")}</p>
            <h3 className="date-modal__heading">{t(heading)}</h3>
          </div>
        </header>

        <article className="date-modal__body">
          <DayPicker
            locale={t("input.calendar.locale")}
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            firstDayOfWeek={0}
            numberOfMonths={2}
            modifiers={{
              selected: this.state.selectedRange,
              range_start: this.state.selectedRange?.from,
              range_end: this.state.selectedRange?.to,
              range_middle: (date) =>
                  this.state.selectedRange ? rangeIncludesDate(this.state.selectedRange, date, true) : false
            }}
            onDayClick={this.dayClickHandler}
          />
        </article>
      </section>
    );
  }
}

export default withTranslation()(DateModalDialog)