import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import TravelContext from "../../../../context/TravelContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { hasMiddleName } from "../../../../utils/helpers";
import { hasNumber, isValidFirstAndLastName } from "../../../../utils/validation";
import Form from "../../../travel-registration/utils/Form";
import Input from "../../../travel-registration/utils/Input";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import WeiterButton from "../../../travel-registration/utils/WeiterButton";
import CustomSelectList from "../../utils/CustomSelectList";

export function AddRelativesName() {
  const history = useNavigate();
  const { setHeader } = useContext(HeaderContext);
  const { travel, setTravel } = useContext(TravelContext);
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  const relationships = useMemo(
    () => [
      t("input.relative.options.parent"),
      t("input.relative.options.sibling"),
      t("input.relative.options.child"),
      t("input.relative.options.partner"),
      t("input.relative.options.etc"),
    ],
    [t]
  );

  const relationshipEnums = useMemo(
    () => {
      const rel = {};

      rel[t("input.relative.options.parent")] = 100000000;
      rel[t("input.relative.options.sibling")] = 100000001;
      rel[t("input.relative.options.child")] = 100000002;
      rel[t("input.relative.options.partner")] = 100000003;
      rel[t("input.relative.options.etc")] = 100000004;

      return rel;
    },
    [t]
  );

  const relationshipInvertEnums = useMemo(
    () => {
      return {
        100000000: t("input.relative.options.parent"),
        100000001: t("input.relative.options.sibling"),
        100000002: t("input.relative.options.child"),
        100000003: t("input.relative.options.partner"),
        100000004: t("input.relative.options.etc")
      }
    },
    [t]
  );

  let index = travel.TravelGroupMember.length - 1;

  const [fullName, setFullName] = useState(
    travel.TravelGroupMember[index].FirstLastName ?? ""
  );
  const [relationship, setRelationship] = useState(
    travel.TravelGroupMember[index].DegreeOfRelationship
      ? relationshipInvertEnums[travel.TravelGroupMember[index].DegreeOfRelationship]
      : ""
  );


  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
        progress: parseInt((10 / 17) * 100),
      };
    });
  }, []);

  const changeNameHandler = (e) => {
    setActive(true);
    setFullName(e.target.value);
  };
  const onChangeHandlerRelationship = (value) => {
    setRelationship(value);
  };
  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };
  const isValid = () => {
    return (
      fullName.length > 0 && isValidFirstAndLastName(fullName) && isValidRelationShip() && !hasMiddleName(fullName)
    );
  };
  const saveData = (e) => {
    e.preventDefault();
    if (isValid()) {
      let travelCopy = Object.assign({}, travel);

      travelCopy.TravelGroupMember[index].FirstLastName = fullName;
      travelCopy.TravelGroupMember[index].DegreeOfRelationship = relationshipEnums[relationship];

      setTravel(travelCopy);

      history(ApplicationRoute.foreignRegistrationAddRelativesBirthdate);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <PageTitle text="pages.addRelative1.title" />
      <p className="page-paragraph page-paragraph--small">{t("input.relatives.text")}</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" ariaLabel="pages.addRelative1.formLabel" className="max-width-380" onSubmit={saveData}>
        <Input
          labelText="input.vorNachname.text"
          id="firstName"
          placeholder="input.vorNachname.text"
          type="text"
          value={fullName}
          onChange={changeNameHandler}
        />

        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={isValidFirstAndLastName(fullName) || !active || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? "errors.nameHasNumber"
              : "errors.notValidContactPersonFirstAndLastName"
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text="errors.middleName"
          ariaLabel="errors.middleName"
        />
        <CustomSelectList
          id="verwandschaftsgrad"
          label="input.relative.text"
          state={relationship}
          options={relationships}
          onChangeHandler={onChangeHandlerRelationship}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesBirthdate}
        onClick={saveData}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}