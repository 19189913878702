import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import apipath from "../apipath";
import HiddenSubmitInput from "../common/HiddenSubmitInput";
import InputValidation from "../common/InputValidaiton";
import ApplicationRoute from "../config/routes";
import HeaderContext from "../context/HeaderContext";
import fetcher from "../services/fetcher";
import MetaDecorator from "../utils/MetaDecorator";
import {
  arePasswordsValid,
  isPasswordValid,
} from "../utils/validation";
import Form from "./registration/utils/Form";
import Info from "./registration/utils/Info";
import Input from "./registration/utils/Input";
import ValidationChecks from "./registration/utils/ValidationChecks.jsx";
import WeiterButton from "./registration/utils/WeiterButton";

const NewPassword = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const location = useLocation();
  const history = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const resetPassword = () => {
    fetcher(
      `${apipath.path}users/updatepasswordviatoken`,
      "POST",
      () => { },
      setError,
      setLoading,
      () => { },
      false,
      "application/json",
      JSON.stringify({
        NewPassword: passwords.password,
        Token: location.pathname.replace("/newpassword/", ""),
      })
    );
  };

  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const onChangeHandler = (e) => {
    setPasswords((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: "no",
        title: t("pages.passwortVergessen.title") + "%noarrow%",
      };
    });
  }, []);

  const onClickHandler = (e) => {
    e.preventDefault();
    if (arePasswordsValid(passwords)) {
      resetPassword();
    }
  };

  useEffect(() => {
    if (!loading && !error) {
      history(ApplicationRoute.home);
    }
    if (!loading
      && error != null
      && error instanceof Response
      && error.status === 412
    ) {
      history(ApplicationRoute.errorPageResetToken);
    }
  }, [loading, error]);

  return (
    <div className="forgotten-password-container registration-middle-container">
      <MetaDecorator title="pageTitle.forgottenPassword" />
      <h1 className="forgotten-password-container__heading">
        {t("pages.passwortVergessen.title")}
      </h1>
      <Form id="newpasswordform" onSubmit={onClickHandler}>
        <Input
          id="password"
          value={passwords.password}
          onChange={onChangeHandler}
          placeholder="input.passwort.text"
          type="password"
          text="input.passwort.text"
          labelText="input.passwort.text"
          labelStyle={{ backgroundColor: "#f0f4f5" }}
          showValidationBorder={!isPasswordValid(passwords.password)}
        />

        <Info
          style={{ marginTop: "32px", marginBottom: "16px" }}
          text="input.passwort.info"
        />

        <div style={{ marginBottom: "32px" }} role="alert" aria-relevant="all">
          <ValidationChecks passwords={passwords} />
        </div>

        <Input
          id="confirmPassword"
          value={passwords.confirmPassword}
          onChange={onChangeHandler}
          placeholder="input.passwort.confirmText"
          type="password"
          labelText="input.passwort.confirmText"
          labelStyle={{ backgroundColor: "#f0f4f5" }}
          showValidationBorder={
            passwords.confirmPassword.length > 0 &&
            passwords.confirmPassword !== passwords.password
          }
          error={
            passwords.confirmPassword.length > 0 &&
            passwords.confirmPassword !== passwords.password
          }
        />
        <InputValidation
          isHidden={
            !(
              passwords.confirmPassword.length > 0 &&
              passwords.confirmPassword !== passwords.password
            )
          }
          text="errors.passwordAndConfirmationPasswordNotEqual"
        />

        <HiddenSubmitInput />

        <div className="hide-weiter-on-keyboard-content" />
        <WeiterButton
          disabled={!arePasswordsValid(passwords)}
          path="./"
          text="pages.passwortVergessen.send2"
          positioned="static"
          onClick={onClickHandler}
          className="hide-weiter-on-keyboard"
        />
      </Form>
    </div>
  );
};

export default NewPassword;