import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import MainButton from "../../../common/MainButton";
import Cross from "../../../svg/Cross";
import { pluck } from "../../../utils/helpers";
import FilteredSelectList from "../../foreign-registration/utils/FilteredSelectList";
import ContentTitle from "../../registration/utils/ContentTitle";
import Input from "../../registration/utils/Input";
import InputCalendar from "../../travel-registration/utils/InputCalendar";

const labelStyle = {
  backgroundColor: "rgb(240, 244, 245)",
};

const AddRelative = ({ hidden, setHidden, onSubmit }) => {
  const { t } = useTranslation();

  const [atCity, setAtCity] = useState("");
  const [country, setCountry] = useState("");
  const [date, setDate] = useState(Object.assign({}, { stopStart: "", stopEnd: "" }));

  const [countries, setCountries] = useState([]);

  let startRef = useRef();

  useEffect(() => {
    setCountries(() => {
      try {
        return pluck(JSON.parse(localStorage.getItem("countries")), "Name").filter((c) => {
          return !(c === t("terms.austria") || c === t("terms.other_countries"));
        });
      } catch (error) {
        return [];
      }
    });
  }, []);

  const update = () => {
    if (isValid()) {
      let newTravel = {
        Name: country,
        StopEnd: date.stopEnd,
        StopStart: date.stopStart,
        Comment: atCity,
      };

      onSubmit(newTravel);

      setHidden(true);
    }
  };

  const onClickHandler = () => {
    update();
  };

  useEffect(() => {
    if (hidden) {
      setAtCity("");
      setCountry("");
      setDate(() => {
        return { stopStart: "", stopEnd: "" };
      });
    } else {
      startRef?.current.focus();
    }
  }, [hidden]);

  const isValid = () => {
    return !!country && date.stopEnd && date.stopStart;
  };

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          if (isValid()) {
            onClickHandler();
          }
        }
      }}
      style={{ display: hidden ? "none" : "block" }}
      className="add-person__container registration-middle-container"
    >
      <Cross onClick={() => setHidden(true)} className="add-person__container__cross" color="black" />

      <div className="add-person__container__inputs-container">
        <ContentTitle style={{ marginTop: "76px", marginBottom: "41px" }} text="input.destination.addOne" />
        <FilteredSelectList
          id="AT-destination-country"
          label="input.select.text"
          state={country}
          onChangeHandler={setCountry}
          options={countries}
          className="grey-background"
          ref={startRef}
          isTravel={true}
        />
        <div>
          <Input
            labelText="input.city.text2"
            id="ATCity"
            onChange={(e) => {
              setAtCity(e.target.value);
            }}
            placeholder="input.city.placeholderOptional"
            value={atCity}
            labelStyle={labelStyle}
          />
        </div>
        <div>
          <InputCalendar
            labelText={`${t("input.travelTime.text")} ${t("input.destination.oneText")}`}
            id="AT-reisezeitram"
            placeholder="input.travelTime.text"
            startDate={date.stopStart}
            endDate={date.stopEnd}
            cLabelClass="add-person__container__label-style"
            noToggle={true}
            onChange={(startDate, endDate) =>
              setDate((curr) => {
                return { ...curr, stopStart: startDate, stopEnd: endDate };
              })
            }
          />
        </div>
        <div />
        <HiddenSubmitInput />
      </div>

      <MainButton
        type="button"
        ariaLabel="terms.submit"
        disabled={!isValid()}
        onClick={onClickHandler}
        text="terms.save_quit"
        positioned="fixed"
        style={{ bottom: "5.6rem" }}
      />
    </div>
  );
};

export default AddRelative;