import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { hasMiddleName, isValidContactPerson, phoneFormatter } from "../../../utils/helpers";
import {
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../utils/validation";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import Input from "../utils/Input";
import WeiterButton from "../utils/WeiterButton";

const optional = {
  FirstLastName: false,
  PhoneNumber: false,
  Email: false,
};

//HINT* NOT REGISTRATION
const ContactPersonPage = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  const [optionals, setOptionals] = useState(optional);

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const [active, setActive] = useState(false);

  const [contact, setContact] = useState({
    FirstLastName: user.EmergencyFirstLastName ? user.EmergencyFirstLastName : "",
    PhoneNumber: user.EmergencyPhoneNumber ? user.EmergencyPhoneNumber : "",
    Email: user.EmergencyEmail ? user.EmergencyEmail : "",
  });

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((9 / 19) * 100),
        title: t("pageTitle.createTripPages"),
      };
    });
  }, []);

  const isValidPhone = () => {
    return contact.PhoneNumber ? isPhoneValidation(contact.PhoneNumber, true) : true;
  };

  const isValidEmail = () => {
    return contact.Email ? isEmailValidation(contact.Email) : true;
  };

  const isValid = () => {
    return (
      (hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) || contact.Email.length === 0) &&
      isValidFirstAndLastName(contact.FirstLastName) &&
      (isValidPhone() || contact.PhoneNumber.length === 0) &&
      (isValidEmail() || contact.Email.length === 0) &&
      isValidContactPerson(optionals)
    );
  };

  const onChangeHandler = (e) => {
    setActive(true);
    setContact((curr) => {
      if (e.target.id === "PhoneNumber") {
        return {
          ...curr,
          [e.target.id]: phoneFormatter(contact.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const setOptionalPhoneToTrue = (phone, email) => {
    if (email) {
      return true;
    }
    return isPhoneValidation(phone);
  };

  useEffect(() => {
    setOptionals((curr) => {
      return {
        ...curr,
        FirstLastName: !!contact.FirstLastName,
        PhoneNumber: setOptionalPhoneToTrue(contact.PhoneNumber, contact.Email),
        Email: !!contact.Email,
      };
    });
  }, [contact]);

  const onClickHandler = () => {
    let userCopy = Object.assign({}, user);
    userCopy.ContactPerson = { ...contact };
    userCopy.EmergencyFirstLastName = contact.FirstLastName;
    userCopy.EmergencyPhoneNumber = contact.PhoneNumber;
    userCopy.EmergencyEmail = contact.Email;
    setUser(userCopy);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      onClickHandler();
      history(ApplicationRoute.addTravelTourOperator);
    }
  };

  useEffect(() => {
    if (user && user.ContactPerson) {
      setContact((curr) => {
        return {
          ...curr,
          FirstLastName: user.ContactPerson.FirstLastName ? user.ContactPerson.FirstLastName : "",
          PhoneNumber: user.ContactPerson.PhoneNumber ? user.ContactPerson.PhoneNumber : "",
          Email: user.ContactPerson.Email ? user.ContactPerson.Email : "",
        };
      });
    }
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <ContentTitle
        id="register-contactperson"
        text="pages.emergencyContact.contentTitle"
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onsubmitHandler} ariaLabelledBy="register-contactperson">
        <Input
          id="FirstLastName"
          name="FirstLastName"
          type="text"
          htmlFor="FirstLastName"
          value={contact.FirstLastName}
          onChange={onChangeHandler}
          placeholder="input.vorNachname.text"
          labelText="input.vorNachname.text"
          showValidationBorder={active && !isValidFirstAndLastName(contact.FirstLastName)}
        />

        <InputValidation
          isHidden={!active || isValidFirstAndLastName(contact.FirstLastName) || hasMiddleName(contact.FirstLastName)}
          text={
            hasNumber(contact.FirstLastName)
              ? "errors.nameHasNumber"
              : "errors.notValidContactPersonFirstAndLastName"
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(contact.FirstLastName)}
          text="errors.middleName"
          ariaLabel="errors.middleName"
        />

        <Input
          id="PhoneNumber"
          name="PhoneNumber"
          type="text"
          htmlFor="PhoneNumber"
          value={contact.PhoneNumber}
          onChange={onChangeHandler}
          placeholder="input.mobilenum.placeholder"
          ariaRequired={false}
          labelText="input.mobilenum.text"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation isHidden={isValidPhone()} text="errors.notValidPhoneNumber" />

        <Input
          id="Email"
          name="Email"
          type="text"
          htmlFor="Email"
          value={contact.Email}
          onChange={onChangeHandler}
          placeholder="input.email.text"
          ariaRequired={false}
          labelText="input.email.text"
          showValidationBorder={
            contact.Email.length !== 0 &&
            (!hasAtSymbolEmailValidation(contact.Email) ||
              !hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) ||
              !isEmailValidation(contact.Email))
          }
        />

        <InputValidation
          isHidden={
            contact.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contact.Email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) ||
            isEmailValidation(contact.Email)
          }
          text="errors.notValidEmail"
        />

        <InputValidation
          isHidden={contact.Email.length === 0 || hasAtSymbolEmailValidation(contact.Email)}
          text="errors.missingAtSymbolEmail"
        />

        <InputValidation
          isHidden={
            contact.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contact.Email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelTourOperator}
        onClick={onClickHandler}
        ariaLabel={`${t("pages.contactPerson.weiter")} ${ApplicationRoute.addTravelTourOperator}`}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default ContactPersonPage;