import { Space } from "@mantine/core";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import apipath from "../../../apipath";
import AlertBox from "../../../common/AlertBox";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import MainButton from "../../../common/MainButton";
import RelativeModal from "../../../common/RelativeModal";
import Separater from "../../../common/Separater";
import { TravelGroupMember } from "../../../config/defaultTravelValues";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import SuccessContext from "../../../context/SuccessContext";
import TravelContext from "../../../context/TravelContext";
import UserContext from "../../../context/UserContext";
import fetcher from "../../../services/fetcher";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  editOnChangeGroupMembers,
  effectOptionals,
  optionalDefault,
} from "../../../utils/editUtils.js";
import {
  convertAppDateToMiddlewareDateFormat,
  convertMiddlewareDateToAppDateFormat,
  getEnumFromGender,
  getSecondAfterSpace,
  getUIID,
  isValidContactPerson,
  pluck,
  postalCodeFormatter,
  setApiObjectAddTravel,
  setApiUserObjectUpdate2,
  setApiUserObjectUpdate3,
  setUserValuesOnLogin,
} from "../../../utils/helpers";
import { travelGroupMemberValidation } from "../../../utils/validation";
import Form from "../../registration/utils/Form";
import LoadingPage from "../../travel-registration/LoadingPage";
import ProfileEditComponent from "../../travel-registration/steps/ProfileEditComponent";
import TravelGroup from "../../travel-registration/utils/TravelGroup.jsx";
import {
  getCountryFlag,
  getCountryNameFromId,
  getCountryPicture,
  getRegionsByCountryId,
} from "../../travel-registration/utils/countryInfo";
import InfoMailForm from "../utils/InfoMailForm.jsx";

const DashboardForeignEditTravel = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  const { travel, } = useContext(TravelContext);
  const { setHeader } = useContext(HeaderContext);
  const { user, setUser } = useContext(UserContext);
  const { setSuccessBox } = useContext(SuccessContext);

  const [userState, setUserState] = useState(user);
  const [error,] = useState(null);
  const [loading,] = useState(true);

  const [updateProfile, setUpdateProfile] = useState(null);
  const [updateProfileError, setUpdateProfileError] = useState(null);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(true);
  const [submttingUpdateProfile, setSubmttingUpdateProfile] = useState(false);

  const [relativeModal, setRelativeModal] = useState(false);
  const [alertBoxHidden, setAlertBoxHidden] = useState(true);
  const [unsavedChangesAlertHidden, setUnsavedChangesAlertHidden] = useState(true);

  const [GDPRDataRetention,] = useState(
    userState.GDPRDataRetention ? userState.GDPRDataRetention : false
  );
  const [GDPRForeignAustrian,] = useState(
    userState.GDPRForeignAustrian ? userState.GDPRForeignAustrian : false
  );
  const [GDPRForeignAustrianOfficialInfo, setGDPRForeignAustrianOfficialInfo] = useState(
    userState.GDPRForeignAustrianOfficialInfo ? userState.GDPRForeignAustrianOfficialInfo : false
  );
  const [GDPRForeignAustrianInofficialInfo, setGDPRForeignAustrianInofficialInfo] = useState(
    userState.GDPRForeignAustrianInofficialInfo ? userState.GDPRForeignAustrianInofficialInfo : false
  );

  const [deleteRelativeIndex, setDeleteRelativeIndex] = useState(-1);

  const [countries] = useState(JSON.parse(localStorage.getItem("countries")));

  const [regions, setRegions] = useState(
    userState.Country ? pluck(getRegionsByCountryId(userState.Country), "Name") : []
  );

  useEffect(() => {
    setRegions(userState.Country ? pluck(getRegionsByCountryId(userState.Country), "Name") : [])
  }, [userState.Country]);

  const [changed, setChanged] = useState(true);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(counter + 1);

    if (counter + 1 > 2) {
      setChanged(true);
    }
  }, [userState]);

  const [optionals, setOptionals] = useState(optionalDefault);
  useEffect(() => {
    effectOptionals(setOptionals, userState.ContactPerson);
  }, [userState.ContactPerson]);

  const updateUserMiddleware = () => {
    let userCopy = Object.assign({}, userState);
    userCopy.Relatives = [];

    for (const memberItem of groupMembers) {
      userCopy.Relatives.push(Object.assign({}, memberItem));
    }

    let apiObj = setApiUserObjectUpdate3(userCopy, t);

    if ("FamilyGroupMember" in apiObj && !!apiObj.FamilyGroupMember && apiObj.FamilyGroupMember.length > 0) {
      apiObj.FamilyGroupMember = apiObj.FamilyGroupMember.map((fgm) => {
        return {
          ...fgm,
          MobilePhone: fgm.MobilePhone ?? null,
          Email: fgm.Email ?? null,
          Gender: getEnumFromGender(fgm.Gender, t),
          Birthdate: convertAppDateToMiddlewareDateFormat(fgm.Birthdate),
          LastName: getSecondAfterSpace(fgm.FirstLastName.trim()).trim(),
        };
      });
    }

    fetcher(
      `${apipath.path}users/CURRENT`,
      "PUT",
      setUpdateProfile,
      setUpdateProfileError,
      setUpdateProfileLoading,
      setSubmttingUpdateProfile,
      false,
      "application/json",
      JSON.stringify(apiObj)
    );
  };

  const [groupMembers, setGroupMembers] = useState(
    userState.Relatives.map((tg) => {
      return {
        ...tg,
        Birthdate: tg.Birthdate ? convertMiddlewareDateToAppDateFormat(tg.Birthdate) : "",
      };
    })
  );

  const [validPersonalInfo, setValidPersonalInfo] = useState(true);
  const [validGroupMembers, setValidGroupMembers] = useState(true);

  useEffect(() => {
    if (groupMembers.length === 0) {
      setValidGroupMembers(true);
    } else {
      let valid = true;
      groupMembers.forEach((tgm) => {
        if (!travelGroupMemberValidation(tgm)) valid = false;
      });

      setValidGroupMembers(valid);
    }
  }, [groupMembers]);

  useEffect(() => {
    ref.current = userState;
  }, [userState]);

  const goBackHeaderFunc = (usst) => {
    if (JSON.stringify(usst.current) !== localStorage.getItem("tempUser")) {
      setUnsavedChangesAlertHidden(false);
    } else {
      history(-1);
    }
  };

  const ref = useRef("");
  const saveChangeHeaderFunc = () => {
    setUnsavedChangesAlertHidden(true);

    window.scrollTo(0, document.body.scrollHeight);
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "editAO",
        title: t("pageTitle.permanentOtsideCountry"),
        progress: "no",
        customGoBackFunc: () => goBackHeaderFunc(ref),
      };
    });

    localStorage.setItem("tempUser", JSON.stringify(user));

    return () => {
      localStorage.setItem("tempUser", "");
    };
  }, []);

  useEffect(() => {
    if (!updateProfileLoading && !updateProfileError) {
      let userCopy = Object.assign({}, userState);
      userCopy.TravelGroupMember = groupMembers;

      userCopy.TravelGroupMember.forEach((item) => {
        item.Birthdate = convertAppDateToMiddlewareDateFormat(item.Birthdate);
      });

      userCopy = setApiUserObjectUpdate2(userCopy, travel, t);
      userCopy.Email = userState.Email;
      setUser(setUserValuesOnLogin(userCopy));

      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.foreignRegDashEditPage.success"),
          trigger: getUIID(),
        };
      });

      history(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [updateProfile, updateProfileError, updateProfileLoading]);


  const isValid = () => {
    return (
      // !!GDPRForeignAustrian &&
      validGroupMembers &&
      validPersonalInfo &&
      isValidContactPerson(optionals) &&
      userState.Street !== "" &&
      userState.PostalCode !== "" &&
      userState.City !== ""
    );
  };

  const submit = (e) => {
    e.preventDefault();
    if (isValid()) {
      updateUserMiddleware();
    }
  };
  //HINT* POST TRAVELL
  useEffect(() => {
    if (!loading && !error) {
      updateUserMiddleware();
    }
  }, [loading]);

  useEffect(() => {
    if (!updateProfileLoading && !updateProfileError) {
      let userCopy = Object.assign({}, userState);
      userCopy.Relatives = [];

      for (const memberItem of groupMembers) {
        userCopy.Relatives.push(Object.assign({}, memberItem));
      }

      userCopy = setApiUserObjectUpdate3(userCopy, t);
      userCopy.Email = userState.Email;

      setUser(setUserValuesOnLogin(userCopy));

      if (userState.Country == null) {
        successBox(false, t("pages.foreignRegDashEditPage.deleteAuf"));
      }

      history(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [updateProfile, updateProfileError, updateProfileLoading]);

  useEffect(() => {
    setUserState((curr) => {
      return {
        ...curr,
        GDPRDataRetention: GDPRDataRetention,
        GDPRForeignAustrian: GDPRForeignAustrian,
        GDPRForeignAustrianOfficialInfo: GDPRForeignAustrianOfficialInfo,
        GDPRForeignAustrianInofficialInfo: GDPRForeignAustrianInofficialInfo,
      };
    });
  }, [GDPRDataRetention, GDPRForeignAustrian, GDPRForeignAustrianOfficialInfo, GDPRForeignAustrianInofficialInfo]);

  const onClickHandler = (e) => {
    e.preventDefault();
    submit(e);
  };

  const onChangeGroupMembers = (e) => {
    setGroupMembers((curr) => {
      let newArray = editOnChangeGroupMembers(e, curr, t);

      setUserState((curr) => {
        curr.Relatives = newArray;

        return curr;
      });

      return newArray;
    });
  };

  const updateUser = (userInfoState, contactPersonInfoState) => {
    setUserState(() => {
      return {
        ...userInfoState,
        ContactPerson: { ...contactPersonInfoState },
        TempDeletedContact: [],
        TempPerson: "",
        HasPersonInit: "",
        TempDeletedRelatives: [],
        TempRelatives: [],
        SaveChanges: true,
      };
    });
  };

  function deletion(e) {
    e.preventDefault();

    let userCopy = Object.assign({}, userState);
    userCopy.IsLivingAbroad = false;
    userCopy.Country = null;
    userCopy.County = null;
    userCopy.City = null;
    userCopy.PostalCode = null;
    userCopy.Street = null;
    userCopy.Relatives = [];
    userCopy.FamilyGroupMember = [];

    setUserState(() => userCopy);

    fetcher(
      `${apipath.path}users/CURRENT`,
      "PUT",
      setUpdateProfile,
      setUpdateProfileError,
      setUpdateProfileLoading,
      setSubmttingUpdateProfile,
      false,
      "application/json",
      JSON.stringify(setApiUserObjectUpdate3(userCopy, t))
    );
  }

  function CountryChange(country, region, street, postalCode, city) {
    let userCopy = Object.assign({}, userState);

    userCopy.Country = country;
    userCopy.County = region;
    userCopy.Street = street;
    userCopy.PostalCode = postalCode;
    userCopy.City = city;

    setUserState(userCopy);
    successBox(false, t("pages.foreignRegDashEditPage.changeAuf"));
  }

  function setRegion(regionName) {
    let userCopy = Object.assign({}, userState);

    for (const countryItem of countries) {
      if (countryItem.Id === userCopy.Country) {
        for (const regionItem of countryItem.Regions) {
          if (regionItem.Name === regionName) {
            userCopy.County = regionItem.Id;
            break;
          }
        }
        break;
      }
    }

    setUserState(userCopy);
  }

  function getRegionNameByCountryId(countryId, regionId) {
    let regionName = "";
    for (const countryItem of countries) {
      if (countryItem.Id === countryId) {
        for (const regionItem of countryItem.Regions) {
          if (regionItem.Id === regionId) {
            regionName = regionItem.Name;
            break;
          }
        }
        break;
      }
    }
    return regionName;
  }

  function addRelativeModal() {
    setRelativeModal(!relativeModal);

    if (document.getElementsByTagName("body").item(0).classList.contains("overflow-hidden")) {
      document.getElementsByTagName("body").item(0).classList.remove("overflow-hidden");
    } else {
      document.getElementsByTagName("body").item(0).classList.add("overflow-hidden");
    }
  }

  function addRelative(FirstLastName, DegreeOfRelationship, Gender, Nationality, Birthdate, Email, MobilePhone) {
    addRelativeModal();
    let groupMembersCopy = Object.assign([], groupMembers);
    let travelGroupMemberCopy = Object.assign({}, TravelGroupMember);

    travelGroupMemberCopy.FirstLastName = FirstLastName;
    travelGroupMemberCopy.DegreeOfRelationship = DegreeOfRelationship;
    travelGroupMemberCopy.Gender = Gender;
    travelGroupMemberCopy.Birthdate = Birthdate;
    travelGroupMemberCopy.Nationality = Nationality;
    travelGroupMemberCopy.Email = Email;
    travelGroupMemberCopy.MobilePhone = MobilePhone;

    groupMembersCopy.push(travelGroupMemberCopy);

    setGroupMembers(groupMembersCopy);

    setUserState((curr) => {
      curr.Relatives = groupMembersCopy;

      return curr;
    });

    successBox(false, t("pages.foreignRegDashEditPage.addAng"));
  }

  function deleteRelative(index) {
    let groupMembersCopy = Object.assign([], groupMembers);
    groupMembersCopy = groupMembersCopy.filter((element, i) => i !== index);

    setGroupMembers(groupMembersCopy);
    setDeleteRelativeIndex(-1);

    setUserState((curr) => {
      curr.Relatives = groupMembersCopy;

      return curr;
    });

    successBox(false, "pages.foreignRegEditPage.deleteAng");
  }

  function openDeleteRelativeModal(index) {
    setDeleteRelativeIndex(index);
  }

  function successBox(hidden, text) {
    setSuccessBox((curr) => {
      return {
        ...curr,
        hidden,
        text: t(text),
        trigger: getUIID(),
      };
    });
  }

  function changeTravel(id, value) {
    let userCopy = Object.assign({}, userState);

    if (id === "PostalCode") userCopy[id] = postalCodeFormatter(userCopy.PostalCode, value);
    else userCopy[id] = value;

    setUserState(userCopy);
  }

  return (
    <>
      <MetaDecorator title="pageTitle.foreignRegistration" />
      {updateProfileLoading && submttingUpdateProfile ? (
        <LoadingPage />
      ) : (
        <>
          {error && <h1>{JSON.stringify(setApiObjectAddTravel(travel, t), null, 2)}</h1>}
          <AlertBox
            id="alertBox"
            level="attention"
            hidden={alertBoxHidden}
            onClickLeftButton={() => setAlertBoxHidden(true)}
            onClickRightButton={deletion}
            title="pages.foreignRegDashEditPage.alertAuf"
            message=" "
            rightButtonText="pages.foreignRegDashEditPage.alertAufText"
            twoRows={true}
          />
          <AlertBox
            id="alertBox"
            level="attention"
            hidden={deleteRelativeIndex === -1}
            onClickLeftButton={() => setDeleteRelativeIndex(-1)}
            onClickRightButton={() => deleteRelative(deleteRelativeIndex)}
            title={
              deleteRelativeIndex >= 0
                ? `${groupMembers[deleteRelativeIndex].FirstLastName} ${t("pages.foreignRegEditPage.deleteAng2")}`
                : ""
            }
            message=" "
            rightButtonText="terms.delete"
          />

          <AlertBox
            id="alertBox"
            level="attention"
            hidden={unsavedChangesAlertHidden}
            onClickLeftButton={() => history(-1)}
            onClickRightButton={saveChangeHeaderFunc}
            title="text.save_changes_title"
            message=" "
            rightButtonText="text.save_changes"
          />

          <div className="edit-page__title-image">
            <img alt="" src={getCountryPicture(getCountryNameFromId(userState.Country))} />
          </div>
          <Form onSubmit={onClickHandler} className="content-container max-width-780">
            <div className="specialAlertContainer edit-page">
              <div className="edit-page__flag-image">
                <img alt="" src={getCountryFlag(userState.Country)} />
              </div>
              <p className="edit-page__subtitle">{t("pages.foreignRegDashEditPage.subtitle")}</p>

              <Space h='xl' />

              <EditInput
                id="Country"
                placeholder="input.country.placeholder"
                value={getCountryNameFromId(userState.Country)}
                labelText="input.country.text"
                ariaRequired={true}
                modal="country"
                backGroundClassName="country-edit"
                onChange={CountryChange}
                filtered={true}
              />
              <EditInput
                id="Region"
                placeholder="input.region.text"
                value={getRegionNameByCountryId(userState.Country, userState.County)}
                labelText="input.region.text"
                ariaRequired={true}
                options={regions}
                onChange={(value) => setRegion(value)}
                filtered={true}
              />

              <HorizontalRule />
              <EditInput
                id="street"
                placeholder="input.street.text"
                value={userState.Street}
                labelText="input.street.text"
                ariaRequired={false}
                onChange={(e) => changeTravel("Street", e.target.value)}
              />
              <InputValidation text="errors.fillInAddress"
                isHidden={userState.Street !== ""}
              />

              <HorizontalRule />
              <EditInput
                id="postalCode"
                placeholder="input.plz.text"
                value={userState.PostalCode}
                labelText="input.plz.text"
                ariaRequired={false}
                onChange={(e) => changeTravel("PostalCode", e.target.value)}
              />
              <InputValidation text="errors.fillInAddress"
                isHidden={userState.PostalCode !== ""}
              />

              <HorizontalRule />
              <EditInput
                id="city"
                placeholder="input.city.text"
                value={userState.City}
                labelText="input.city.text"
                ariaRequired={false}
                onChange={(e) => changeTravel("City", e.target.value)}
              />
              <InputValidation text="errors.fillInAddress" isHidden={userState.City !== ""} />
              <Separater text="pages.foreignRegDashEditPage.data" />

              <ProfileEditComponent
                user={userState}
                updateUser={updateUser}
                noInitialHorizontalLine={true}
                setValid={setValidPersonalInfo}
              />

              {/* Change to Country not Id */}

              {relativeModal && <RelativeModal close={addRelativeModal} save={addRelative} />}
              {groupMembers.length ? (
                <>
                  <TravelGroup
                    groupMembers={groupMembers}
                    onChange={onChangeGroupMembers}
                    foreign={true}
                    deleteHandler={openDeleteRelativeModal}
                  />
                  <button
                    className="add-relative-link add-relative-link--align-right"
                    type="button"
                    onClick={addRelativeModal}
                  >
                    + {t("input.relatives.addPerson")}
                  </button>
                </>
              ) : (
                <Separater
                  text="input.relatives.foreign"
                  buttonText="input.relatives.addForeign"
                  buttonClickHandler={addRelativeModal}
                />
              )}

              <InfoMailForm
                GDPRForeignAustrianOfficialInfo={GDPRForeignAustrianOfficialInfo}
                setGDPRForeignAustrianOfficialInfo={setGDPRForeignAustrianOfficialInfo}
                GDPRForeignAustrianInofficialInfo={GDPRForeignAustrianInofficialInfo}
                setGDPRForeignAustrianInofficialInfo={setGDPRForeignAustrianInofficialInfo}
              />

              <div className={changed ? "fixed" : ""}>
                <MainButton
                  disabled={!isValid()}
                  ariaLabel={`${t("pages.contactPerson.weiter")} ${ApplicationRoute.TravelDashboardNoPermissions}`}
                  path={ApplicationRoute.TravelDashboardNoPermissions}
                  positioned="static"
                  text="text.save_changes"
                  style={{ marginTop: "3.2rem", marginBottom: "0.8rem" }}
                />
              </div>
              {changed && <div style={{ paddingBottom: "1.6rem" }} />}
              <p className="small">{t("pages.foreignRegDashEditPage.backToA")}</p>
              <Link
                to={ApplicationRoute.TravelDashboardNoPermissions}
                aria-label={ApplicationRoute.TravelDashboardNoPermissions}
                onClick={(e) => {
                  e.preventDefault();
                  setAlertBoxHidden(false);
                }}
                style={{ display: "block" }}
              >
                <div
                  className="delete-button"
                  onClick={() => setAlertBoxHidden(false)}
                  style={{ marginBottom: "90px" }}
                >
                  <span>
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>{t("pages.foreignRegDashEditPage.deleteSVG")}</title>
                      <g id="Icons-/--Trash-Icon-Delete" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect id="ViewBox" x="0" y="0" width="24" height="24" />
                        <g
                          id="icon-bcms_loeschen-2"
                          transform="translate(3.000000, 1.000000)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <g id="icon-bcms_loeschen">
                            <g>
                              <polyline
                                id="Path"
                                stroke="#9e0529"
                                points="15.6429971 2.36076923 14.6856024 22 2.31439765 22 1.35700294 2.36076923"
                              />
                              <g id="Group" stroke="#9e0529">
                                <path
                                  d="M5.25318315,2.36076923 C5.25318315,0.533076923 6.71008815,0 8.5,0 C10.2899119,0 11.7468168,0.533076923 11.7468168,2.36076923"
                                  id="Path"
                                />
                                <line x1="5.25318315" y1="8.58846154" x2="5.25318315" y2="15.7638462" id="Path" />
                                <line x1="8.5" y1="8.58846154" x2="8.5" y2="15.7638462" id="Path" />
                                <line x1="11.7468168" y1="8.58846154" x2="11.7468168" y2="15.7638462" id="Path" />
                                <line x1="7.39423758e-16" y1="2.36076923" x2="17" y2="2.36076923" id="Path" />
                                <line x1="2.65572968" y1="19.6392308" x2="14.3442703" y2="19.6392308" id="Path" />
                                <line x1="1.69833497" y1="4.56076923" x2="15.301665" y2="4.56076923" id="Path" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    {t("pages.foreignRegDashEditPage.alertAufText")}
                  </span>
                </div>
              </Link>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default DashboardForeignEditTravel;