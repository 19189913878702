import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import TravelContext from "../../../../context/TravelContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { getTravelGroupMemberLastIndex_Array, phoneFormatter } from "../../../../utils/helpers";
import { isPhoneValidation } from "../../../../utils/validation";
import Input from "../../../registration/utils/Input";
import WeiterButton from "../../../registration/utils/WeiterButton";
import Form from "../../../travel-registration/utils/Form";
import PageTitle from "../../../travel-registration/utils/PageTitle";

export function AddRelativesNumber() {
  const history = useNavigate();
  const { t } = useTranslation();

  const { setHeader } = useContext(HeaderContext);
  const { travel, setTravel } = useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initMobilePhone = travel?.TravelGroupMember[groupMemberIndex]?.MobilePhone;

  const [phoneNumber, setPhoneNumber] = useState(initMobilePhone || "");

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.documentElement.addEventListener("click", activate);

    return () => document.documentElement.removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
        progress: parseInt((14 / 17) * 100),
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber, true);
  };

  const updateTravel = () => {
    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      MobilePhone: phoneNumber.trim() || null,
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateTravel();

    history(ApplicationRoute.foreignRegistrationAddRelativesNationality);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history(ApplicationRoute.foreignRegistrationAddRelativesNationality);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <PageTitle text="pages.number.title" />
      <p className="page-paragraph page-paragraph--small">{t("input.relatives.text")}</p>

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onSubmitHandler} className="max-width-380">
        <Input
          id="phoneNumber"
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder="input.mobilenum.placeholder"
          type="tel"
          labelText="input.mobilenum.text"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text="errors.notValidPhoneNumber"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesNationality}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}