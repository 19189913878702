import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import apipath from "../../../../apipath";
import ApplicationRoute from "../../../../config/routes";
import UserContext from "../../../../context/UserContext";
import { addToAutoCache } from "../../../../services/caching";
import PageNotFound from "../../../PageNotFound";
import NotfalltippView from "./NotfalltippView";

const notfalltipps = [
  { path: ApplicationRoute.erreichbarkeitInNotfallen, title: "pages.notfalltipps.navigationTitle.erreichbarkeitInNotfallen", tippId: "1" },
  { path: ApplicationRoute.finanzielleNotlage, title: "pages.notfalltipps.navigationTitle.finanzielleNotlage", tippId: "4" },
  { path: ApplicationRoute.katastrophenPolitischeUnruhen, title: "pages.notfalltipps.navigationTitle.katastrophenPolitischeUnruhen", tippId: "7" },
  { path: ApplicationRoute.ministeriumAuswartige, title: "pages.notfalltipps.navigationTitle.ministeriumAuswartige", tippId: "10" },
  { path: ApplicationRoute.passverlust, title: "pages.notfalltipps.navigationTitle.passverlust", tippId: "2" },
  { path: ApplicationRoute.regressforderungenSchutzmassnahmen, title: "pages.notfalltipps.navigationTitle.regressforderungenSchutzmassnahmen", tippId: "9" },
  { path: ApplicationRoute.reisewarnungen, title: "pages.notfalltipps.navigationTitle.reisewarnungen", tippId: "8" },
  { path: ApplicationRoute.unfallErkrankungUndSterbefalle, title: "pages.notfalltipps.navigationTitle.unfallErkrankungUndSterbefalle", tippId: "5" },
  { path: ApplicationRoute.verloreneFahrzeugpapiereFuhrerschein, title: "pages.notfalltipps.navigationTitle.verloreneFahrzeugpapiereFuhrerschein", tippId: "3" },
  { path: ApplicationRoute.vermissteAngehorige, title: "pages.notfalltipps.navigationTitle.vermissteAngehorige", tippId: "6" }
]

const NotfalltippsRouter = () => {
  return (
    <Routes>
      {notfalltipps.map((route) => (
        <Route path={route.path} key={route.path} element={ <NotfalltippView title={route.title} tippId={route.tippId} /> } />
      ))}
      <Route path="*" element={<PageNotFound/>} />
    </Routes>
  )
};

export const usePrefetchNotfallTipps = () => {
  const { offline } = useContext(UserContext);

  return () => {
    if (offline) return;
    for (const tipp of notfalltipps) {
      addToAutoCache(
        `${apipath.pathOrig}app-inhalte/tipp-${tipp.tippId}/type/json/`,
        { method: "GET" },
        true
      )
    }
  }
};

export default NotfalltippsRouter;