import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "../../../App.scss";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  convertAppDateToMiddlewareDateFormat,
  convertMiddlewareDateToAppDateFormat,
  dateFormatter,
  getTravelGroupMemberLastIndex_Array,
} from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation,
} from "../../../utils/validation";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import Form from "../utils/Form";
import PageTitle from "../utils/PageTitle";

const AddTravelCompanionDateOfBirth = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const history = useNavigate();
  const { travel, setTravel } = useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initDate = travel?.TravelGroupMember[groupMemberIndex]?.Birthdate;
  initDate = initDate ? convertMiddlewareDateToAppDateFormat(initDate) : "";

  const [date, setDate] = useState(initDate);
  const [placeHolder, setPlaceHolder] = useState("input.date.birthdate");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((13 / 19) * 100),
      };
    });
  }, []);

  const isValidDate = () => {
    return isDateValidation(date);
  };

  const IsDateSmallerThan1900 = () => {
    return IsDateSmallerThan_1900_1_1_Validation(date);
  };

  const IsDateBiggerThanToday = () => {
    return IsDateBiggerThanTodayValidation(date);
  };

  const isValid = () => {
    return isValidDate() && IsDateBiggerThanToday() && !IsDateSmallerThan1900();
  };
  const updateTravel = () => {
    const groupMemberIndex =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Birthdate: convertAppDateToMiddlewareDateFormat(date),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    let newDate = dateFormatter(date, e.target.value);

    setDate(newDate);
  };

  const onClickInputHandler = () => {
    setPlaceHolder("input.date.placeholder");
  };
  const onClickHandler = () => {
    updateTravel();
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history(ApplicationRoute.addTravelGender);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <PageTitle text="input.date.birthdateInfo" />

      <p
        className="page-paragraph page-paragraph--small"
        aria-label={t("input.mitreisende.many")}
      >
        {t("input.mitreisende.one")}
      </p>

      {/* <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.many")}</p> */}
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabel="input.date.birthdateInfoRelative"
      >
        <Input
          id="date"
          value={date}
          onChange={onChangeHandler}
          onClick={onClickInputHandler}
          placeholder={placeHolder}
          labelText="input.date.birthdate"
          showValidationBorder={date.length > 0 && !isValid()}
        />
        <InputValidation
          isHidden={!date.length > 0 || isValidDate()}
          text="errors.notValidDate"
        />

        <InputValidation
          isHidden={
            !date.length > 0 || !IsDateSmallerThan1900() || !isValidDate()
          }
          text="errors.dateOfBirthLessThan_01_01_1900"
        />

        <InputValidation
          isHidden={
            !date.length > 0 || IsDateBiggerThanToday() || !isValidDate()
          }
          text="errors.dateBiggerThanToday"
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelGender}
        onClick={onClickHandler}
        className="floating-weiter-buton"
      />
    </div>
  );
};

export default AddTravelCompanionDateOfBirth;