import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { dateFormatter, prefilledUserData } from "../../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation,
  isOlderOrIsEighteenYearsOlds,
} from "../../../../utils/validation";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Form from "../../../registration/utils/Form";
import Input from "../../../registration/utils/Input";
import WeiterButton from "../../../registration/utils/WeiterButton";

//HINT* NOT REGISTRATION
export function ForeignBirhtdate() {
  const history = useNavigate();
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((3 / 19) * 100),
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
      };
    });
  }, []);

  const [date, setDate] = useState("");
  const [placeHolder, setPlaceHolder] = useState("input.date.birthdate");

  const isValidDate = () => {
    return isDateValidation(date);
  };

  const IsDateSmallerThan1900 = () => {
    return IsDateSmallerThan_1900_1_1_Validation(date);
  };

  const IsDateBiggerThanToday = () => {
    return IsDateBiggerThanTodayValidation(date);
  };

  const isOlderThan_18_years = () => {
    return isOlderOrIsEighteenYearsOlds(date);
  };

  const isValid = () => {
    return (
      isValidDate() &&
      isOlderThan_18_years() &&
      IsDateBiggerThanToday() &&
      !IsDateSmallerThan1900()
    );
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, BirthDate: date };
    });
  };

  const onChangeHandler = (e) => {
    let newDate = dateFormatter(date, e.target.value);

    setDate(newDate);
  };

  const onClickInputHandler = () => {
    setPlaceHolder("input.date.placeholder");
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history(ApplicationRoute.foreignRegistrationRegistrationCounty);
    }
  };

  useEffect(() => {
    setDate(user.BirthDate ?? "");
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.permanentOtsideCountry" />
      <ContentTitle
        id="foreign-registration-birthdate"
        text={prefilledUserData("prefilledUserData.geburtsdatum")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy="foreign-registration-birthdate"
      >
        <Input
          id="date"
          name="data"
          type="text"
          htmlFor="date"
          value={date}
          onClick={onClickInputHandler}
          onChange={onChangeHandler}
          placeholder={placeHolder}
          labelText="input.date.birthdate"
          showValidationBorder={date.length > 0 && !isValid()}
        />
        <InputValidation
          isHidden={!date.length > 0 || isValidDate()}
          text="errors.notValidDate"
        />
        <InputValidation
          isHidden={
            !date.length > 0 ||
            !IsDateBiggerThanToday() ||
            isOlderThan_18_years() ||
            !isValidDate()
          }
          text="errors.ageLessThan_18"
        />

        <InputValidation
          isHidden={
            !date.length > 0 || !IsDateSmallerThan1900() || !isValidDate()
          }
          text="errors.dateOfBirthLessThan_01_01_1900"
        />

        <InputValidation
          isHidden={
            !date.length > 0 || IsDateBiggerThanToday() || !isValidDate()
          }
          text="errors.dateBiggerThanToday"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationCounty}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}