import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { getTravelGroupMemberLastIndex_Array, phoneFormatter } from "../../../utils/helpers";
import { isPhoneValidation } from "../../../utils/validation";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageDescription from "../utils/Description";
import Form from "../utils/Form";
import PageTitle from "../utils/PageTitle";

const AddTravelMobilePhone = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  const { setHeader } = useContext(HeaderContext);
  const { travel, setTravel } = useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initMobilePhone = travel?.TravelGroupMember[groupMemberIndex]?.MobilePhone;

  const [phoneNumber, setPhoneNumber] = useState(initMobilePhone || "");

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.documentElement.addEventListener("click", activate);

    return () => document.documentElement.removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((15 / 19) * 100),
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber, true);
  };

  const updateTravel = () => {
    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      MobilePhone: phoneNumber || null,
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateTravel();

    history(ApplicationRoute.addTravelEmail);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history(ApplicationRoute.addTravelEmail);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <PageTitle id="contactperson-phone" text="pages.addRelative4.title" />

      <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p>
      <PageDescription text="input.mitreisende.extraDataPerson" />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="contactperson-phone">
        <Input
          id="phoneNumber"
          name="phoneNumber"
          htmlFor="phoneNumber"
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder="input.mobilenum.placeholder"
          type="tel"
          labelText="input.mobilenum.text"
          labelStyle={{ display: "inline-block" }}
          showValidationBorder={active && !isValid()}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text="errors.notValidPhoneNumber"
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelEmail}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default AddTravelMobilePhone;