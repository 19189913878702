import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ApiRequestErrorMessage from "../../common/ApiRequestErrorMessage";
import ApplicationRoute from "../../config/routes";
import LoggedContext from "../../context/LoggedContext";
import UserContext from "../../context/UserContext";
import Cross from "../../svg/Cross";
import MetaDecorator from "../../utils/MetaDecorator";
import Form from "../registration/utils/Form";
import Input from "../registration/utils/Input";
import WeiterButton from "../registration/utils/WeiterButton";
import { login, loginUser } from "../registration/utils/loginHelper.js";
import { getCountries__with__loader, getVB__with__loader } from "../travel-registration/utils/GetCountries";
import Loader from "../travel-registration/utils/Loader";

const PushLoginBeforeNF = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useNavigate();
  const { t } = useTranslation();

  const onPasswordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const onEmailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const { setUser } = useContext(UserContext);
  const { setIsLogged } = useContext(LoggedContext);

  const [loginData, setLoginData] = useState(null);
  const [loginError, setLogingError] = useState(null);
  const [logingLoading, setLogintLoading] = useState(true);
  const [submittingLogin, setSubmittingLogin] = useState(false);

  const [disableButton, setDisableButton] = useState(false);

  const [vbLoader, setVBLoader] = useState(false);
  const [countryDataLoaded, setCountryDataLoaded] = useState(false);

  const initLoginCred = { email: false, password: false };
  const [noLoginCred, setNoLoginCred] = useState(initLoginCred);

  const submittedLogginWithNoErrors = () => {
    return !logingLoading && !loginError;
  };

  const submittingLoggin = () => {
    return submittingLogin && logingLoading;
  };

  const onLoginHandler = (e) => {
    e.preventDefault();
    login(email, password, setNoLoginCred, setLoginData, setLogingError, setLogintLoading, setSubmittingLogin);
  };

  useEffect(() => {
    if (!logingLoading && !loginError) {
      const path = JSON.parse(localStorage.getItem("linkToGo"));
      history(path);
    }
  }, [logingLoading, loginError]);

  useEffect(() => {
    if (!logingLoading) {
      if (submittedLogginWithNoErrors()) {
        setDisableButton(true);

        loginUser(loginData, setUser, setIsLogged, history, t);
      }
    }
  }, [loginData, loginError, logingLoading, t]);

  const onCrossClickHandler = () => {
    history(ApplicationRoute.home);
  };

  useEffect(() => {
    getCountries__with__loader(setCountryDataLoaded, t);
    getVB__with__loader(setVBLoader);
  }, []);

  if (!vbLoader) return <Loader />;
  if (!countryDataLoaded) return <Loader />;

  return (
    <div className="push-login-before-container">
      <MetaDecorator title="pageTitle.crisisWarningPage" />

      <Cross
        className="push-login-before-container__cross"
        height="3.7rem"
        width="3.7rem"
        color="black"
        onClick={onCrossClickHandler}
      />

      <div>
        <div className="push-login-before-container__top--container">
          <img
            src="./images/common/redcrisis.png"
            className="push-login-before-container__top--container__alert-triangle"
            alt=""
          />
        </div>

        <p className="push-login-before-container__message">
          {t("pages.pushLoginBeforeNF.message")}
        </p>
      </div>

      <div className="push-login-before-container_login-text">{t("pageTitle.loginPage")}</div>

      <Form id="form">
        <Input
          id="email"
          placeholder="input.email.text"
          type="text"
          labelText="input.email.text"
          onChange={onEmailChangeHandler}
          value={email}
          inputStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          labelStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          error={noLoginCred.email || loginError?.status === 401}
        />

        {(noLoginCred.email || noLoginCred.password || loginError?.status === 401) &&
          <ApiRequestErrorMessage
            style={{ marginTop: "-1.3rem", marginBottom: "1.6rem" }}
            text="errors.401-login"
          />
        }

        <Input
          id="password"
          placeholder="input.passwort.text"
          type="password"
          labelText="input.passwort.text"
          onChange={onPasswordChangeHandler}
          value={password}
          inputStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          labelStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          error={noLoginCred.password || loginError?.status === 401}
        />

        <WeiterButton
          disabled={submittingLoggin() || disableButton}
          path="./"
          ariaLabel="pages.pushLoginBeforeNF.login"
          text="pages.pushLoginBeforeNF.login"
          positioned="static"
          linkId="loginLinkId"
          onClick={onLoginHandler}
          style={{ marginBottom: "0px" }}
        />
      </Form>

      <Link
        to={ApplicationRoute.registerForgottenPassword}
        aria-label={t("text.passwort_vergessen") + ' ' + t("pages.passwortVergessen.title")}
        style={{ display: "block" }}
      >
        <div className="centered-link__container">
          <div className="centered-link__container__link">{t("text.passwort_vergessen")}</div>
        </div>
      </Link>
    </div>
  );
};

export default PushLoginBeforeNF;