import { asyncResponse } from "./Helpers/HttpDemoResponses"
import { httpClient, isDemo } from "./HttpClient"

function getLocalTranslationFile(language: string) {
    return asyncResponse<Record<string, any>>({
        data: require('../Locales/' + language.toLowerCase() + '.json')
    })
}

const getTranslations = async (language: string) => {
    if (isDemo) {
        return getLocalTranslationFile(language)
    }

    if(process.env?.REACT_APP_DOT_USE_DB_TRANSLATIONS === 'false') {
        return getLocalTranslationFile(language)
    }

    return httpClient.get('/translations/' + language)
        .catch(() => getLocalTranslationFile(language))
}

const TranslationService = {
    getTranslations
}

export default TranslationService;
