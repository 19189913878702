import localforage from "localforage";
import { useEffect, useState } from "react";
import { useQuery } from 'react-query';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "../src/App.scss";
import ActionInformation from "./common/ActionInformation.jsx";
import BodyContainer from "./common/BodyContainer.jsx";
import Footer from "./common/Footer.jsx";
import Header from "./common/Header.jsx";
import DotWebsite from "./components/DotWebsite/index.tsx";
import EditTravelFromDashboard from "./components/EditTravel/EditTravel.jsx";
import AddRelative1 from "./components/EditTravel/utils/AddRelative-1.jsx";
import AddRelative2 from "./components/EditTravel/utils/AddRelative-2.jsx";
import AddRelative3 from "./components/EditTravel/utils/AddRelative-3.jsx";
import AddRelative4 from "./components/EditTravel/utils/AddRelative-4.jsx";
import AddRelative5 from "./components/EditTravel/utils/AddRelative-5.jsx";
import AddRelative6 from "./components/EditTravel/utils/AddRelative-6.jsx";
import ErrorPage from "./components/ErrorPage.jsx";
import ErrorPageNotVerified from "./components/ErrorPageNotVerified.jsx";
import ErrorPageResetTokenInvalid from "./components/ErrorPageResetTokenInvalid.jsx";
import ForgottenPassword from "./components/ForgottenPassword.jsx";
import ForgottenPasswordSecondPage from "./components/ForgottenPasswordSecondPage.jsx";
import NewPassword from "./components/NewPassword.jsx";
import OfflineManager from "./components/OfflineManager/index.jsx";
import PageNotFound from "./components/PageNotFound.jsx";
import PushLogin from "./components/PushLogin/PushLogin.jsx";
import PushLoginBeforeNF from "./components/PushLogin/PushLoginBeforeNF.jsx";
import RedirectPage from "./components/RedirectPage.jsx";
import AllgemeineInformationen from "./components/countryOverview/pages/AllgemeineInformationen.jsx";
import Bildquellen from "./components/countryOverview/pages/Bildquellen.jsx";
import BotschaftenKonsulate from "./components/countryOverview/pages/BotschaftenKonsulate.jsx";
import BotschaftenKonsulateContacts from "./components/countryOverview/pages/BotschaftenKonsulateContacts.jsx";
import CountryDetailedInfo from "./components/countryOverview/pages/CountryDetailedInfo.jsx";
import CountryInformation from "./components/countryOverview/pages/CountryInformation.jsx";
import CountryList from "./components/countryOverview/pages/CountryList.jsx";
import DashboardInfos from "./components/countryOverview/pages/DashboardInfos.jsx";
import DashboardInfosBodytext from "./components/countryOverview/pages/DashboardInfosBodytext.jsx";
import ImpresumInfos from "./components/countryOverview/pages/ImpresumInfos.jsx";
import InfoBarrierefreiheit from "./components/countryOverview/pages/InfoBarrierefreiheit.jsx";
import InfoDatenschutz from "./components/countryOverview/pages/InfoDatenschutz.jsx";
import InfoPass from "./components/countryOverview/pages/InfoPass.jsx";
import InfoReiseregistrierung from "./components/countryOverview/pages/InfoReiseregistrierung.jsx";
import Notfalltipps from "./components/countryOverview/pages/Notfalltipps.jsx";
import Vertretungen from "./components/countryOverview/pages/Vertretungen.jsx";
import NotfalltippsRouter, { usePrefetchNotfallTipps } from "./components/countryOverview/pages/notfalltipps/NotfalltippsRouter.jsx";
import DataProtection from "./components/dataProtection/DataProtection.jsx";
import DataProtectionBeforeLogin from "./components/dataProtection/DataProtectionBeforeLogin.jsx";
import ChangePassword from "./components/edit-profile/ChangePassword.jsx";
import EditProfileSecond from "./components/edit-profile/EditProfileSecond.jsx";
import FAQsPage from "./components/faqs/FAQsPage.jsx";
import DashboardForeignEditTravel from "./components/foreign-registration/pages/dashboard-edit-page.jsx";
import ForeignEditTravel from "./components/foreign-registration/pages/edit-page.jsx";
import RegionSelection from "./components/foreign-registration/pages/region-selection.jsx";
import { ForeignBirhtdate } from "./components/foreign-registration/pages/registration-steps/birthdate.jsx";
import { ForeignCounty } from "./components/foreign-registration/pages/registration-steps/county.jsx";
import { ForeignEmail } from "./components/foreign-registration/pages/registration-steps/email.jsx";
import { ForeignEmergencyContact } from "./components/foreign-registration/pages/registration-steps/emergencyContact.jsx";
import { ForeignGender } from "./components/foreign-registration/pages/registration-steps/gender.jsx";
import { ForeignName } from "./components/foreign-registration/pages/registration-steps/name.jsx";
import { ForeignNumber } from "./components/foreign-registration/pages/registration-steps/number.jsx";
import { ForeignPassport } from "./components/foreign-registration/pages/registration-steps/passport.jsx";
import AddRelatives from "./components/foreign-registration/pages/relatives/add-relatives.jsx";
import { AddRelativesBirthdate } from "./components/foreign-registration/pages/relatives/add-relatives-birthdate.jsx";
import { AddRelativesEmail } from "./components/foreign-registration/pages/relatives/add-relatives-email.jsx";
import { AddRelativesGender } from "./components/foreign-registration/pages/relatives/add-relatives-gender.jsx";
import { AddRelativesName } from "./components/foreign-registration/pages/relatives/add-relatives-name.jsx";
import { AddRelativesNationality } from "./components/foreign-registration/pages/relatives/add-relatives-nationality.jsx";
import { AddRelativesNumber } from "./components/foreign-registration/pages/relatives/add-relatives-number.jsx";
import { AddRelativesSuccess } from "./components/foreign-registration/pages/relatives/add-relatives-success.jsx";
import DseConfirmationPage from "./components/login/pages/DseConfirmationPage.jsx";
import InfoMailPage from "./components/login/pages/InfoMailPage.jsx";
import FirstPushStep from "./components/push-notifications/1-push-step.jsx";
import SecondPushStep from "./components/push-notifications/2-push-step.jsx";
import PushEditFromDashBoard from "./components/push-notifications/PushEditFromDashboard.jsx";
import ActivatedAccountPage from "./components/registration/pages/ActivatedAccountPage.jsx";
import AustrianCitizenshipPage from "./components/registration/pages/AustrianCitizenshipPage.jsx";
import ConfirmationPage from "./components/registration/pages/ConfirmationPage.jsx";
import ContactPersonPage from "./components/registration/pages/ContactPersonPage.jsx";
import DatePage from "./components/registration/pages/DatePage.jsx";
import EmailAdressPage from "./components/registration/pages/EmailAdress.jsx";
import EmailAdressRegistration from "./components/registration/pages/EmailAdressRegistration.jsx";
import EmailVerifiedPage from "./components/registration/pages/EmailVerifiedPage.jsx";
import GenderPage from "./components/registration/pages/GenderPage.jsx";
import IdaEmailPage from "./components/registration/pages/IdaEmailPage.jsx";
import IdaLinkExistingPage from "./components/registration/pages/IdaLinkExistingPage.jsx";
import IdaLinkLoginPage from "./components/registration/pages/IdaLinkLoginPage.jsx";
import IdaLinkManualPage from "./components/registration/pages/IdaLinkManualPage.jsx";
import IdaWelcomePage from "./components/registration/pages/IdaWelcomePage.jsx";
import LivingInPage from "./components/registration/pages/LivingInPage.jsx";
import LoginPage from "./components/registration/pages/LoginPage.jsx";
import LoginSignInPage from "./components/registration/pages/LoginSignInPage.jsx";
import MobilePhonePage from "./components/registration/pages/MobilePhonePage.jsx";
import RegistrationNamePage from "./components/registration/pages/NamePage.jsx";
import NationalityPage from "./components/registration/pages/NationalityPage.jsx";
import PassNumberPage from "./components/registration/pages/PassNumberPage.jsx";
import PasswordPage from "./components/registration/pages/PasswordPage.jsx";
import SentEmailPage from "./components/registration/pages/SentEmailPage.jsx";
import AddTravel from "./components/travel-registration/add-travel.jsx";
import TravelDashboard from "./components/travel-registration/dashboard.jsx";
import AddTravelCompanion from "./components/travel-registration/steps/1-step-companion.jsx";
import AddTravelCompanionName from "./components/travel-registration/steps/2-step-companion-details.jsx";
import AddTravelCompanionDateOfBirth from "./components/travel-registration/steps/3-step-companion-date-of-birth.jsx";
import AddTravelGender from "./components/travel-registration/steps/4-step-gender.jsx";
import AddTravelMobilePhone from "./components/travel-registration/steps/5-step-mobile-phone.jsx";
import AddTravelEmail from "./components/travel-registration/steps/6-step-email.jsx";
import AddTravelNationality from "./components/travel-registration/steps/7-step-nationality.jsx";
import AddTravelCompanionAddedSuccess from "./components/travel-registration/steps/8-step-companion-added-success.jsx";
import EditTravel from "./components/travel-registration/steps/9-step-edit.jsx";
import TourOperator from "./components/travel-registration/steps/tour-operator.jsx";
import Loader from "./components/travel-registration/utils/Loader.jsx";
import cvb from "./components/travel-registration/utils/countriesVB.json";
import defaultTravelValue from "./config/defaultTravelValues.js";
import defaultUserValue from "./config/defaultUserValues.js";
import ApplicationRoute from "./config/routes.js";
import HeaderContext from "./context/HeaderContext.js";
import LanguageContext from "./context/LanguageContext.js";
import LoggedContext from "./context/LoggedContext.js";
import PushContext from "./context/PushContext.js";
import SuccessContext from "./context/SuccessContext.js";
import TravelContext from "./context/TravelContext.js";
import UserContext from "./context/UserContext.js";
import './languages/config.ts';
import { getLanguages } from './languages/languages.ts';
import "./services/caching.js";
import { areIdaRoutesEnabled, compare, getIsLogged, removeIsLoggedStorage, setLocalStorage } from "./utils/helpers.js";

function App() {
  const [isSet, setIsSet] = useState(false);
  const isDotEnabled = process.env.REACT_APP_DOT_ENABLED === "true";
  const currentVersion = process.env.REACT_APP_VERSION;
  const notfallTippsPrefetchFn = usePrefetchNotfallTipps();

  const { isLoading: isLanguageLoading, data: languages } = useQuery(
    'getPASSLanguages', 
    () => getLanguages(), 
    { // Optionen --> 1 mal holen, dann nur noch auf LanguagePicker hören
      staleTime: Infinity, 
      cacheTime: Infinity, 
      refetchOnMount: false, 
      refetchOnReconnect: false, 
      refetchOnWindowFocus: false 
    }
  )

  useEffect(() => {
    localforage.setItem("init", "true");
  }, [localforage])

  useEffect(() => {
    if (!isSet) {
      setIsSet(true);

      if (window.innerHeight > window.innerWidth) {
        localStorage.setItem("orient", "norm");
      } else {
        localStorage.setItem("orient", "x");
      }

      let body = document.body,
        html = document.documentElement;

      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      localStorage.setItem("fh", height);
      height = height - 226;

      if (height) {
        height += "px";
      } else {
        height = "calc(100vh - 276px)";
      }

      localStorage.setItem("h", height);

      //width
      let width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);

      if (width) {
        width += "px";
      } else {
        width = "100vw";
      }

      localStorage.setItem("w", width);
    }

    setTimeout(notfallTippsPrefetchFn, 1000);
  }, [isSet])

  let travelValue = defaultTravelValue;
  try {
    const localStorageAddTravel = JSON.parse(localStorage.getItem("addTravel"));

    if (localStorageAddTravel) {
      travelValue = localStorageAddTravel;
    }
  } catch { }

  let localStorageUser = defaultUserValue;
  try {
    localStorageUser = JSON.parse(localStorage.getItem("registerUser"));

    localStorageUser
      ? setLocalStorage("registerUser", localStorageUser)
      : setLocalStorage("registerUser", defaultUserValue);
  } catch {
    setLocalStorage("registerUser", defaultUserValue);
  }

  const resetPushLocalStorage = () => {
    try {
      const localStoragePush = JSON.parse(localStorage.getItem("pushNotifcations"));

      setPushNotifications(() => localStoragePush);
    } catch { }
  };

  const [user, setUser] = useState(localStorageUser);
  const [offline, setOffline] = useState(false);
  const [pushNotifcations, setPushNotifications] = useState({});
  const [successBox, setSuccessBox] = useState({ hidden: true, text: "" });
  const [isLogged, setIsLogged] = useState();
  const [travel, setTravel] = useState(travelValue);
  const [header, setHeader] = useState({
    inUse: "",
    title: "",
    progress: "",
    events: {},
  });

  const resetLocalStorageOnVersionChange = () => {
    if (localStorage.getItem("Version") !== currentVersion) {
      removeIsLoggedStorage();
      localStorage.clear();
      localStorage.setItem("Version", currentVersion);
    }
  };

  const resetStateFromLocalStorageUser = () => {
    try {
      const localStorageUser = JSON.parse(localStorage.getItem("registerUser"));

      if (!localStorageUser) {
        setLocalStorage("registerUser", user);
      } else {
        setUser(() => localStorageUser);
      }
    } catch {
      setLocalStorage("registerUser", user);
    }
  };

  //Keeping alive the user and addtravel states on pages refresh
  useEffect(() => {
    resetLocalStorageOnVersionChange();
    resetStateFromLocalStorageUser();
    resetPushLocalStorage();
  }, []);

  //Keeping the login state on page refresh
  useEffect(() => {
    try {
      const isLoggedIn = getIsLogged();
      setIsLogged(isLoggedIn);
    } catch {
      setIsLogged(false);
      removeIsLoggedStorage();
    }
  }, []);

  useEffect(() => {
    setLocalStorage("registerUser", user);
  }, [user]);

  useEffect(() => {
    setLocalStorage("addTravel", travel);
  }, [travel]);

  useEffect(() => {
    setLocalStorage("pushNotifcations", pushNotifcations);
  }, [pushNotifcations]);

  const [value, setValue] = useState("");

  //INPUT SEARCH MAP*
  const [hidden, setHidden] = useState(true);

  ///country list
  const [valueC, setValueC] = useState("");

  //INPUT SEARCH MAP*
  const [hiddenC, setHiddenC] = useState(true);

  const resetInputsCountryMapSearch = () => {
    setValue("");
    setHidden(true);
    setValueC("");
    setHiddenC(true);
  };

  const cActive = "#9e0529";
  const cInActive = "#919191";

  const initColor = {
    1: cInActive,
    2: cInActive,
    3: cInActive,
    4: cInActive,
    5: cInActive,
    6: cInActive,
  };

  const [color, setColor] = useState(initColor);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("travel-dashboard")) {
      setColor(() => {
        return { ...initColor, 1: cActive };
      });
    } else {
      switch (location.pathname) {
        case "/":
          setColor(() => {
            return { ...initColor, 1: cActive };
          });
          break;
        case ApplicationRoute.countryList:
          setColor(() => {
            return { ...initColor, 2: cActive };
          });
          break;
        case ApplicationRoute.notfalltipps:
          setColor(() => {
            return { ...initColor, 3: cActive };
          });
          break;
        case ApplicationRoute.vertretungen:
          setColor(() => {
            return { ...initColor, 4: cActive };
          });
          break;
        case ApplicationRoute.dashboardInfos:
          setColor(() => {
            return { ...initColor, 5: cActive };
          });
          break;
        case ApplicationRoute.dot:
          setColor(() => {
            return { ...initColor, 6: cActive };
          });
          break;

        default:
          setColor((curr) => {
            return { ...curr };
          });
      }
    }
  }, [location]);

  const getCountriesVB = () => {
    try {
      let x = JSON.parse(localStorage.getItem("countriesVB")).countries;
      if (!x || x.length === 0) {
        let vbJSON = cvb;
        vbJSON.countries.sort(compare);
        return vbJSON.countries;
      }

      return x;
    } catch (error) {
      let vbJSON = cvb;
      vbJSON.countries.sort(compare);
      return vbJSON.countries;
    }
  };

  const [vbData, setVBData] = useState("");

  useEffect(() => {
    setVBData(() => {
      return getCountriesVB().filter((c) => c.representations.length !== 0);
    });
  }, []);

  if (!vbData || isLanguageLoading) {
    return <Loader />;
  }

  return (
    <>
      <LanguageContext.Provider value={{ languages }}>
        <LoggedContext.Provider value={{ isLogged, setIsLogged }}>
          <HeaderContext.Provider value={{ header, setHeader }}>
            <UserContext.Provider value={{ user, setUser, offline, setOffline }}>
              <OfflineManager />
              <TravelContext.Provider value={{ travel, setTravel }}>
                <SuccessContext.Provider value={{ successBox, setSuccessBox }}>
                  <PushContext.Provider value={{ pushNotifcations, setPushNotifications }}>
                    <ActionInformation
                      text={successBox.text}
                      hidden={successBox.hidden}
                      onClickClose={() =>
                        setSuccessBox((curr) => {
                          return { ...curr, hidden: true, text: "", trigger: "" };
                        })
                      }
                      set={setSuccessBox}
                      trigger={successBox.trigger}
                      alert={successBox.alert ? successBox.alert : false}
                    />
                    <Header
                      value={value}
                      setValue={setValue}
                      hidden={hidden}
                      setHidden={setHidden}
                      valueC={valueC}
                      setValueC={setValueC}
                      hiddenC={hiddenC}
                      setHiddenC={setHiddenC}
                      vb={vbData}
                    />
                    <BodyContainer className={BrowserRouter}>
                      <Routes>
                        <Route
                          path="/"
                          element={<LoginSignInPage clearSearchInput={resetInputsCountryMapSearch} />}
                        />
                        <Route path={ApplicationRoute.register} element={<LoginSignInPage/>} />
                        <Route path={ApplicationRoute.registerCitizenship} element={<AustrianCitizenshipPage/>} />
                        <Route path={ApplicationRoute.registerName} element={<RegistrationNamePage/>} />
                        <Route path={ApplicationRoute.registerGender} element={<GenderPage/>} />
                        <Route path={ApplicationRoute.registerForgottenPassword} element={<ForgottenPassword/>} />
                        <Route path={ApplicationRoute.registerMobilePhone} element={<MobilePhonePage/>} />
                        <Route path={ApplicationRoute.registerBirthday} element={<DatePage/>} />
                        <Route path={ApplicationRoute.registerEmail} element={<EmailAdressPage/>} />
                        <Route path={ApplicationRoute.registerSentEmail} element={<SentEmailPage/>} />
                        <Route path={ApplicationRoute.registerVerifiedEmail} element={<EmailVerifiedPage/>} />
                        <Route path={ApplicationRoute.registerPassword} element={<PasswordPage/>} />
                        <Route path={ApplicationRoute.registerNationality} element={<NationalityPage/>} />
                        {/* <Route path={ApplicationRoute.registerForeignCountry} element={<AuslandPage/>} /> */}
                        <Route path={ApplicationRoute.registerLivingIn} element={<LivingInPage/>} />
                        <Route path={ApplicationRoute.registerPassNumber} element={<PassNumberPage/>} />
                        <Route path={ApplicationRoute.registerContactPerson} element={<ContactPersonPage/>} />
                        {/* <Route path={ApplicationRoute.registerUserInfo} element={<UserInfoPage/>} /> */}
                        <Route path={ApplicationRoute.registerConfirmationPage} element={<ConfirmationPage/>} />
                        <Route path={ApplicationRoute.dseConfirmationPage} element={<DseConfirmationPage/>} />
                        <Route path={ApplicationRoute.infoMailPage} element={<InfoMailPage/>} />
                        {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.loginPage} element={<LoginPage/>} />) : false}
                        {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.linkAccountExisting} element={<IdaLinkExistingPage/>} />) : false}
                        {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.linkAccountManual} element={<IdaLinkManualPage/>} />) : false}
                        {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.linkAccountLogin} element={<IdaLinkLoginPage/>} />) : false}
                        {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.idaEmailPage} element={<IdaEmailPage/>} />) : false}
                        {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.idaWelcomePage} element={<IdaWelcomePage/>} />) : false}
                        <Route path={ApplicationRoute.TravelDashboardNoPermissions} element={<TravelDashboard/>} />
                        <Route path={ApplicationRoute.addTravel} element={<AddTravel/>} />
                        <Route path={ApplicationRoute.addTravelTourOperator} element={<TourOperator/>} />
                        {/* <Route path={ApplicationRoute.editProfile} element={<EditProfilePage/>} /> */}
                        <Route path={ApplicationRoute.travelDashboard} element={<TravelDashboard/>} />
                        <Route path={ApplicationRoute.addTravel} element={<AddTravel/>} />
                        <Route path={ApplicationRoute.addTravelCompanion} element={<AddTravelCompanion/>} />
                        <Route path={ApplicationRoute.addTravelCompanionName} element={<AddTravelCompanionName/>} />
                        <Route
                          path={ApplicationRoute.addTravelCompanionDateOfBirth}
                          element={<AddTravelCompanionDateOfBirth/>}
                        />
                        <Route path={ApplicationRoute.addTravelGender} element={<AddTravelGender/>} />
                        <Route path={ApplicationRoute.addTravelMobilePhone} element={<AddTravelMobilePhone/>} />
                        <Route path={ApplicationRoute.addTravelEmail} element={<AddTravelEmail/>} />
                        <Route path={ApplicationRoute.addTravelNationality} element={<AddTravelNationality/>} />
                        <Route
                          path={ApplicationRoute.addTravelCompanionAddedSuccess}
                          element={<AddTravelCompanionAddedSuccess/>}
                        />
                        <Route path={ApplicationRoute.addTravelEdit} element={<EditTravel/>} />
                        <Route
                          path={ApplicationRoute.countryList}
                          element={<CountryList valueC={valueC} />}
                        />
                        <Route path={ApplicationRoute.countryDetailedInfo} element={<CountryDetailedInfo/>} />
                        {/* <Route path={ApplicationRoute.editProfile} element={<EditProfilePage/>} /> */}
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegionSelection}
                          element={<RegionSelection/>}
                        />
                        <Route path={ApplicationRoute.foreignRegistrationAddRelatives} element={<AddRelatives/>} />
                        <Route
                          path={ApplicationRoute.foreignRegistrationAddRelativesName}
                          element={<AddRelativesName/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationAddRelativesBirthdate}
                          element={<AddRelativesBirthdate/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationAddRelativesGender}
                          element={<AddRelativesGender/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationAddRelativesEmail}
                          element={<AddRelativesEmail/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationAddRelativesNumber}
                          element={<AddRelativesNumber/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationAddRelativesNationality}
                          element={<AddRelativesNationality/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationAddRelativesSuccess}
                          element={<AddRelativesSuccess/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationName}
                          element={<ForeignName/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationEmail}
                          element={<ForeignEmail/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationBirthdate}
                          element={<ForeignBirhtdate/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationCounty}
                          element={<ForeignCounty/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationGender}
                          element={<ForeignGender/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationNumber}
                          element={<ForeignNumber/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationPassport}
                          element={<ForeignPassport/>}
                        />
                        <Route
                          path={ApplicationRoute.foreignRegistrationRegistrationEmergencyContact}
                          element={<ForeignEmergencyContact/>}
                        />
                        <Route path={ApplicationRoute.foreignRegistrationEditPage} element={<ForeignEditTravel/>} />
                        <Route
                          path={ApplicationRoute.dashboardForeignRegistrationPage}
                          element={<DashboardForeignEditTravel/>}
                        />
                        <Route
                          path={ApplicationRoute.renewPassword}
                          element={<NewPassword/>}
                        />
                        <Route path={ApplicationRoute.firstPushStep} element={<FirstPushStep/>} />
                        <Route path={ApplicationRoute.secondPushStep} element={<SecondPushStep/>} />
                        <Route path={ApplicationRoute.pushEditFromDashboard} element={<PushEditFromDashBoard/>} />
                        <Route path={ApplicationRoute.pushLogin} element={<PushLogin/>} />
                        <Route path={ApplicationRoute.loginBeforePushNotf} element={<PushLoginBeforeNF/>} />
                        <Route path={ApplicationRoute.editProfileSecond} element={<EditProfileSecond/>} />
                        <Route path={ApplicationRoute.changePassword} element={<ChangePassword/>} />
                        <Route path={ApplicationRoute.editTravel} element={<EditTravelFromDashboard/>} />
                        <Route path={ApplicationRoute.addRelative1} element={<AddRelative1/>} />
                        <Route path={ApplicationRoute.addRelative2} element={<AddRelative2/>} />
                        <Route path={ApplicationRoute.addRelative3} element={<AddRelative3/>} />
                        <Route path={ApplicationRoute.addRelative4} element={<AddRelative4/>} />
                        <Route path={ApplicationRoute.addRelative5} element={<AddRelative5/>} />
                        <Route path={ApplicationRoute.addRelative6} element={<AddRelative6/>} />
                        <Route
                          path={ApplicationRoute.forgottenPasswordSecondPage}
                          element={<ForgottenPasswordSecondPage/>}
                        />
                        <Route path={ApplicationRoute.errorPage} element={<ErrorPage/>} />
                        <Route path={ApplicationRoute.errorPageVerified} element={<ErrorPageNotVerified/>} />
                        <Route path={ApplicationRoute.errorPageResetToken} element={<ErrorPageResetTokenInvalid/>} />
                        <Route path={ApplicationRoute.dataProtection} element={<DataProtection/>} />
                        <Route
                          path={ApplicationRoute.dataProtectionBeforeLogin}
                          element={<DataProtectionBeforeLogin/>}
                        />
                        <Route path={ApplicationRoute.botschaftenKonsulate} element={<BotschaftenKonsulate/>} />
                        <Route
                          path={ApplicationRoute.botschaftenKonsulateContacts}
                          element={<BotschaftenKonsulateContacts/>}
                        />
                        <Route path={ApplicationRoute.notfalltipps} element={<Notfalltipps/>} />
                        <Route path={ApplicationRoute.vertretungen} element={<Vertretungen/>} />
                        <Route path={ApplicationRoute.dashboardInfos} element={<DashboardInfos/>} />
                        <Route path={ApplicationRoute.dashboardInfosBodytext} element={<DashboardInfosBodytext/>} />
                        <Route path={ApplicationRoute.bildquellen} element={<Bildquellen/>} />
                        <Route
                          path={ApplicationRoute.allgemeineInformationen}
                          element={<AllgemeineInformationen/>}
                        />
                        <Route path={ApplicationRoute.infoReiseregistrierung} element={<InfoReiseregistrierung/>} />
                        <Route path={ApplicationRoute.infoBarrierefreiheit} element={<InfoBarrierefreiheit/>} />
                        <Route path={ApplicationRoute.infoDatenschutz} element={<InfoDatenschutz/>} />
                        <Route path={ApplicationRoute.infoPass} element={<InfoPass/>} />
                        <Route path={ApplicationRoute.countryInformation} element={<CountryInformation/>} />
                        <Route
                          path={ApplicationRoute.registrationEmailAdress}
                          element={<EmailAdressRegistration/>}
                        />
                        <Route path={ApplicationRoute.impresumInfos} element={<ImpresumInfos/>} />

                        <Route path={ApplicationRoute.redirectPage} element={<RedirectPage/>} />

                        <Route path={ApplicationRoute.activatedAccount} element={<ActivatedAccountPage/>} />
                        {isDotEnabled && <Route path={ApplicationRoute.dot+'/*'} element={<DotWebsite/>} />}

                        <Route path={ApplicationRoute.notfalltippsNested} element={<NotfalltippsRouter/>} />
                        <Route path={ApplicationRoute.faqs} element={<FAQsPage/>} />

                        <Route path="*" element={<PageNotFound/>} />
                      </Routes>
                    </BodyContainer>
                    <Footer
                      value={value}
                      setValue={setValue}
                      hidden={hidden}
                      setHidden={setHidden}
                      valueC={valueC}
                      setValueC={setValueC}
                      hiddenC={hiddenC}
                      setHiddenC={setHiddenC}
                      color={color}
                      path={location.pathname}
                    />
                  </PushContext.Provider>
                </SuccessContext.Provider>
              </TravelContext.Provider>
            </UserContext.Provider>
          </HeaderContext.Provider>
        </LoggedContext.Provider>
      </LanguageContext.Provider>
    </>
  );
}

export default App;