import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import TravelContext from "../../../../context/TravelContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { getTravelGroupMemberLastIndex_Array, isIn, pluck } from "../../../../utils/helpers";
import WeiterButton from "../../../registration/utils/WeiterButton";
import Form from "../../../travel-registration/utils/Form";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import FilteredSelectList from "../../utils/FilteredSelectList";

export function AddRelativesNationality() {
  const data = JSON.parse(localStorage.getItem("countries"));
  let countries = pluck(data, "Name");
  countries = countries ? countries.filter((n) => !isIn(n)) : countries;

  const history = useNavigate();
  const { t } = useTranslation();

  const { travel, setTravel } = useContext(TravelContext);
  const { setHeader } = useContext(HeaderContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initNationality = travel?.TravelGroupMember[groupMemberIndex]?.Nationality;

  if (!!initNationality) {
    initNationality = data.find((el) => {
      return el.Id === initNationality;
    });
  }

  const [livingIn, setLivingIn] = useState(initNationality ? initNationality.Name : "");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
        progress: parseInt((15 / 17) * 100),
      };
    });
  }, []);

  const isValid = () => {
    return !!livingIn;
  };

  const updateTravel = () => {
    const getCountryId = () => {
      if (!livingIn) {
        return null;
      } else {
        const { Id } = data.find((el) => {
          return el.Name === livingIn;
        });

        return Id;
      }
    };

    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;

    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Nationality: getCountryId(),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history(ApplicationRoute.addTravelCompanionAddedSuccess);
    }
  };

  const onChangeHandlerCountries = (value) => {
    setLivingIn(value);
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <PageTitle text="input.nationality.title" />
      <p className="page-paragraph page-paragraph--small">{t("input.relatives.text")}</p>

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="contentTitleId" className="max-width-380">
        <FilteredSelectList
          id="country"
          label="input.nationality.text"
          state={livingIn}
          options={countries}
          onChangeHandler={onChangeHandlerCountries}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />.

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesSuccess}
        onClick={onClickHandler}
        className="floating-weiter-buton"
      />
    </section>
  );
}