import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import apipath from "../../../apipath";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import fetcher from "../../../services/fetcher";
import { setApiUserObject } from "../../../utils/helpers";
import DataConfirmationForm from "../utils/DataConfirmationForm";

//HINT* REGISTRATION
const ConfirmationPage = () => {
  const history = useNavigate();
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const { setHeader } = useContext(HeaderContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const registerUser = () => {
    fetcher(
      `${apipath.path}users/register`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify(setApiUserObject(user))
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((4 / 6) * 100),
        title: t("pageTitle.registrationPages"),
        ariaLabel: t("pages.register4.ariaTitle"),
      };
    });
  }, [setHeader]);


  const submittedWithNoErrors = useCallback(
    () => {
      return !loading && !error;
    }, [loading, error]);

  //After submit
  useEffect(() => {
    if (submittedWithNoErrors()) {
      history(ApplicationRoute.registerSentEmail);
    }
  }, [loading, error, data, history, submittedWithNoErrors]);

  //After submit and changes user state
  useEffect(() => {
    if (submittedWithNoErrors()) {
      history(ApplicationRoute.registerSentEmail);
    }
  }, [user, history, submittedWithNoErrors]);

  const submittingNow = () => {
    return submitting && loading;
  };

  const onChangeHandler = () => {
    setChecked(!checked);
  };

  const handler = (e) => {
    e.preventDefault();

    registerUser();
  };

  const onClickHandler = (e) => {
    handler(e);
  };

  const onSubmitHandler = (e) => {
    handler(e);
  };

  return (
    <DataConfirmationForm
      onChangeHandler={onChangeHandler}
      onClickHandler={onClickHandler}
      submittingNow={submittingNow}
      onSubmitHandler={onSubmitHandler}
      checked={checked}
      pageTitle="pageTitle.registrationPages"
      route={ApplicationRoute.registerVerifiedEmail}
    />
  );
};

export default ConfirmationPage;