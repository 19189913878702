import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import HorizontalRule from "../../../common/HorizontalRule";
import Separater from "../../../common/Separater";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import EmbassyServiceCheckBox from "../../countryOverview/utils/EmbassyServiceCheckBox";
import {
  getCountryAliasFromUid,
  getCountryFlagFromCountryVB,
  getCountryNameFromUid,
} from "../../travel-registration/utils/countryInfo";
import CountryInfoHeader from "../utils/CountryInfoHeader";
import InfoCard from "../utils/InfoCard";

function BotschaftenKonsulateContacts() {
  const { countryUid, orgaUnitUid } = useParams();
  const { setHeader } = useContext(HeaderContext);
  const [countryName] = useState(getCountryNameFromUid(countryUid));
  const [representation, setRepresentation] = useState(null);
  const [countryAlias] = useState(getCountryAliasFromUid(countryUid));
  const { t } = useTranslation();

  useEffect(() => {
    let data = [];
    if (representation == null) {
      const arr = localStorage.getItem("countriesVB") ? JSON.parse(localStorage.getItem("countriesVB")).countries : [];

      for (const element of arr) {
        if (element.countryUid == countryUid) {
          data = element.representations.filter((repr) => repr.orgaUnitUid == orgaUnitUid);
          break;
        }
      }

      setRepresentation(data.length > 0 ? data[0] : {});
    }

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: getHeader(data.length > 0 ? t(data[0]) : null),
      };
    });
  }, []);

  const getHeader = (repr) => {
    if (!repr) return " ";
    const type = t(repr.type ?? "");
    const city = t(repr.city ?? "");
    return type + " " + city;
  };

  return (
    <div>
      <MetaDecorator title={representation ? representation.type : "pages.embassy.title"} />
      <CountryInfoHeader
        flag={getCountryFlagFromCountryVB(countryName)}
        alt=""
        Country={countryAlias.length > 0 ? t(countryAlias) + " (" + t(countryName) + ")" : countryName}
        className="country__flag-no-image"
      />
      <HorizontalRule />

      {!!representation &&
        <>
          <InfoCard type={representation.type} city={representation.city} />
          <HorizontalRule />
          <InfoCard type="pages.embassyContact.address" city={representation.address} isHidden={representation.address.length === 0} />
          <HorizontalRule isHidden={representation.address.length === 0} />

          <Separater
            text="pages.embassyContact.contact"
            isHidden={
              representation.phone.length === 0
              && representation.mobile.length === 0
              && representation.email.length === 0
              && representation.fax.length === 0
              && representation.websiteUrls.length === 0
            }
          />

          <InfoCard
            type="pages.embassyContact.phone"
            city={representation.phone.map(obj => obj.number).filter((item, i, ar) => ar.indexOf(item) === i).map((number) => {
              return (
                <Fragment key={number}>
                  <a style={{textDecoration: "none", color: "#000000"}} href={`tel:${number}`}>
                    {number}
                  </a>
                  <br />
                </Fragment>
              );
            })}
            isHidden={representation.phone.length === 0}
          />
          <HorizontalRule isHidden={representation.phone.length === 0} />

          {/* If no phone show mobile phone */}
          {representation.phone.length === 0 &&
            <>
              <InfoCard
                type="pages.embassyContact.phone"
                city={representation.mobile.map(obj => obj.number).filter((item, i, ar) => ar.indexOf(item) === i).map((number) => {
                  return (
                    <Fragment key={number}>
                      <a style={{textDecoration: "none", color: "#000000"}} href={`tel:${number}`}>
                        {number}
                      </a>
                      <br />
                    </Fragment>
                  );
                })}
                isHidden={representation.mobile.length === 0}
              />
              <HorizontalRule isHidden={representation.mobile.length === 0} />
            </>
          }

          <InfoCard
            type="pages.embassyContact.fax"
            city={representation.fax.map(obj => obj.number).filter((item, i, ar) => ar.indexOf(item) === i).map((number) => {
              return (
                <Fragment key={number}>
                  <a style={{textDecoration: "none", color: "#000000"}} href={`fax:${number}`}>
                    {number}
                  </a>
                  <br />
                </Fragment>
              );
            })}
            isHidden={representation.fax.length === 0}
          />
          <HorizontalRule isHidden={representation.fax.length === 0} />

          <InfoCard
            type="pages.embassyContact.email"
            city={representation.email.map(obj => obj.email).filter((item, i, ar) => ar.indexOf(item) === i).map((email) => {
              return (
                <Fragment key={email}>
                  <a style={{textDecoration: "none", color: "#000000"}} href={`mailto:${email}`}>
                    {email}
                  </a>
                  <br />
                </Fragment>
              );
            })}
            isHidden={representation.email.length === 0}
          />
          <HorizontalRule isHidden={representation.email.length === 0} />

          <InfoCard
            style={{ wordWrap: "break-word" }}
            type="pages.embassyContact.web"
            city={representation.websiteUrls.map(obj => obj.url).filter((item, i, ar) => ar.indexOf(item) === i).map((url) => {
              return (
                <Fragment key={url}>
                  <a
                    style={{ color: "#000000" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://${url.replace("https://", "")}`}
                  >
                    {url}
                  </a>
                  <br />
                </Fragment>
              );
            })}
            isHidden={representation.websiteUrls.length === 0}
          />
          <Separater
            text="pages.embassyContact.hours"
            isHidden={representation.opening.length === 0 && representation.info === ""}
          />
          <div
            style={{
              margin: representation.opening.length ? "24px 16px" : "0 16px 24px 16px",
            }}
          >
            {!!representation.opening.length &&
              <ul style={{ fontSize: "17px", lineHeight: "1" }}>
                {representation.opening.split(",").map((op) => {
                  return (
                    <li key={op} style={{ display: "block", margin: "8px 0" }}>
                      {op}
                      <br />
                    </li>
                  );
                })}
              </ul>
            }
            <p style={{ marginTop: "24px", fontSize: "17px", lineHeight: "1.1" }}>{t(representation.info)}</p>
          </div>
          <HorizontalRule />

          <Separater text="pages.informationen.title" />

          <div className="embassy-services--flex">
            <InfoCard city={t("pages.embassyContact.passport")} />
            <EmbassyServiceCheckBox
              id="passportAuthority"
              style={{ marginBottom: "0" }}
              active={representation.passportAuthority === true}
            />
          </div>

          <HorizontalRule />

          <div className="embassy-services--flex">
            <InfoCard city={t("pages.embassyContact.childpass")} />
            <EmbassyServiceCheckBox
              id="passportAuthorityLimited"
              style={{ marginBottom: "0" }}
              active={representation.passportAuthorityLimited}
            />
          </div>

          <HorizontalRule />

          <div className="embassy-services--flex">
            <InfoCard city={t("pages.embassyContact.emergancypass")} />
            <EmbassyServiceCheckBox
              id="passportAuthorityEmergency"
              style={{ marginBottom: "0" }}
              active={representation.passportAuthorityEmergency === true}
            />
          </div>

          <HorizontalRule />

          <div className="embassy-services--flex">
            <InfoCard city={t("pages.embassyContact.visa")} />
            <EmbassyServiceCheckBox
              id="visaAuthority"
              style={{ marginBottom: "0" }}
              active={representation.visaAuthority === true}
            />
          </div>

          <HorizontalRule />

          <div className="embassy-services--flex">
            <InfoCard city={t("pages.embassyContact.legalizationAuthority")} />
            <EmbassyServiceCheckBox
              id="legalizationAuthority"
              style={{ marginBottom: "0" }}
              active={representation.legalizationAuthority === true}
            />
          </div>

          <HorizontalRule />

          <InfoCard
            type={`${t("pages.embassyContact.officeArea")} ${representation.officeArea}`}
            isHidden={representation.officeArea.length === 0}
          />
          <p style={{ marginBottom: "56px" }} />
        </>
      }
    </div>
  );
}

export default BotschaftenKonsulateContacts;