import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import TravelContext from "../../../../context/TravelContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import {
  getEnumFromGender,
  getGenderTextFromEnum,
  getKeyByValue,
  getTravelGroupMemberLastIndex_Array,
} from "../../../../utils/helpers";
import WeiterButton from "../../../registration/utils/WeiterButton";
import ControledRadioInput from "../../../travel-registration/utils/ControledRadioInput";
import Form from "../../../travel-registration/utils/Form";
import PageTitle from "../../../travel-registration/utils/PageTitle";

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

export function AddRelativesGender() {
  const { t } = useTranslation();

  const genders = useMemo(
    () => {
      return {
        male: t("input.gender.options.male"),
        female: t("input.gender.options.female"),
        other: t("input.gender.options.other")
      }
    },
    [t]
  );

  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
        progress: parseInt((12 / 17) * 100),
      };
    });
  }, []);

  const history = useNavigate();
  const { travel, setTravel } = useContext(TravelContext);

  const index = getTravelGroupMemberLastIndex_Array(travel);
  let initGender = travel?.TravelGroupMember[index]?.Gender
    ? getGenderTextFromEnum(travel?.TravelGroupMember[index]?.Gender)
    : "";

  const [selectedValue, setSelectedValue] = useState({
    ...initSelectValue,
    [initGender]: true,
  });

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTravel = () => {
    const index =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[index] = {
      ...newArray[index],
      Gender: getEnumFromGender(getKeyByValue(selectedValue, true), t),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history(ApplicationRoute.foreignRegistrationAddRelativesEmail);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <PageTitle text="pages.addRelative3.title" />
      <p className="page-paragraph page-paragraph--small">{t("input.relatives.text")}</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabel="pages.addRelative3.formLabel"
        className="max-width-380"
      >
        <ControledRadioInput
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesEmail}
        onClick={onClickHandler}
        className="floating-weiter-buton"
      />
    </section>
  );
}