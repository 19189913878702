import GoogleMapReact from "google-map-react";
import { Component } from "react";
import { createRoot } from 'react-dom/client';
import { withTranslation } from "react-i18next";
import ApplicationRoute from "../../../config/routes";
import { compare, getUIID } from "../../../utils/helpers";
import cvb from "../../travel-registration/utils/countriesVB.json";
import { MemoPin } from "./Pin";

const getCountriesVB = () => {
  try {
    const x = JSON.parse(localStorage.getItem("countriesVB")).countries;
    if (!x || x.length === 0) {
      const vbJSON = cvb;
      vbJSON.countries.sort(compare);
      return vbJSON.countries;
    }

    return x;
  } catch (error) {
    const vbJSON = cvb;
    vbJSON.countries.sort(compare);
    return vbJSON.countries;
  }
};

const cr = getCountriesVB()
  .filter((c) => c.representations.length !== 0)
  .map((c) => {
    return { country: c.country, representations: c.representations, countryUid: c.countryUid };
  });

let lastId = "";

const onClick = (id, countryObject, representation, infoText, lat, lng, t) => {
  if (lastId && lastId !== id) {
    const prev = document.getElementById(lastId);
    if (prev) {
      prev.style.display = "none";
    }
  }

  const el = document.getElementById(id);
  if (el.style.display === "inline-block") {
    el.style.display = "none";
  } else {
    el.style.display = "inline-block";
    setPinInfo(el, countryObject, representation, infoText, lat, lng, t);
  }

  lastId = id;
};

const setPinInfo = (el, countryObject, representation, infoText, lat, lng, t) => {
  let isWien = false;
  let same = [];

  if (t(representation.city.toLowerCase()) === t("input.bundesland.options.wien").toLowerCase()) {
    for (const element of cr) {
      for (const r of element.representations) {
        if (r.latitude === lat && r.longitude === lng) {
          same.push({ ...r, country: element.country, countryUid: element.countryUid });
        }
      }
    }
    isWien = true;
  } else {
    same = cr
      .filter((c) => c.country === countryObject.country)[0]
      .representations.filter((r) => r.latitude === lat && r.longitude === lng);
  }

  const component =
    same.length === 1 ? (
      <a
        className="react-pin-text-a"
        href={
          "#" +
          ApplicationRoute.botschaftenKonsulateContacts
            .replace(":countryUid", countryObject.countryUid)
            .replace(":orgaUnitUid", representation.orgaUnitUid)
        }
      >
        {infoText.length > 51 ? (
          <>
            {t(representation.type).substring(0, 51)}
            <br />
            {`${t(representation.city)}, ${t(countryObject.country)}`}
          </>
        ) : (
          t(infoText)
        )}
      </a>
    ) : (
      <div
        className="react-pin-text__multiple"
        href={
          "#" +
          ApplicationRoute.botschaftenKonsulateContacts
            .replace(":countryUid", countryObject.countryUid)
            .replace(":orgaUnitUid", representation.orgaUnitUid)
        }
      >
        {same.map((r) => {
          if (isWien) {
            countryObject.country = r.country;
            countryObject.countryUid = r.countryUid;
          }

          const txt = `${t(r.type)} ${t(r.city)}, ${t(countryObject.country)}`;

          if (txt.length > 50) {
            //type bigger than 50 char
            if (t(r.type).length > 50) {
              const typeArray = t(r.type).match(/.{1,50}/g);
              return (
                <>
                  <a
                    href={
                      "#" +
                      ApplicationRoute.botschaftenKonsulateContacts
                        .replace(":countryUid", countryObject.countryUid)
                        .replace(":orgaUnitUid", r.orgaUnitUid)
                    }
                    className="react-pin-text__multiple__a"
                  >
                    {typeArray.map((ta) => {
                      return (
                        <>
                          {t(ta)}
                          <br />
                        </>
                      );
                    })}
                    {`${t(r.city)}, ${t(countryObject.country)}`}
                  </a>

                  <span>
                    <div className="map-hr" />
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <a
                    href={
                      "#" +
                      ApplicationRoute.botschaftenKonsulateContacts
                        .replace(":countryUid", countryObject.countryUid)
                        .replace(":orgaUnitUid", r.orgaUnitUid)
                    }
                    className="react-pin-text__multiple__a"
                  >
                    {t(r.type)}
                    <br />
                    {`${t(r.city)}, ${t(countryObject.country)}`}
                  </a>

                  <span>
                    <div className="map-hr" />
                  </span>
                </>
              );
            }
          } else {
            return (
              <>
                <a
                  href={
                    "#" +
                    ApplicationRoute.botschaftenKonsulateContacts
                      .replace(":countryUid", countryObject.countryUid)
                      .replace(":orgaUnitUid", r.orgaUnitUid)
                  }
                  className="react-pin-text__multiple__a"
                >
                  {t(txt)}
                </a>

                <span>
                  <div className="map-hr" />
                </span>
              </>
            );
          }
        })}
      </div>
    );

  const root = createRoot(el);
  root.render(component);
};

const truncate = (text) => {
  // OriginalLogik --> text.substring(0, 30) + " ...";

  return text;
};

const MapsApiKey = process.env.REACT_APP_API_KEY;

const getMarkers = (setCenter, t) => {
  const countries = getCountriesVB();

  return countries
    .filter((c) => c.representations.length !== 0)
    .map((country) => {
      return country.representations
        .filter((r) => r.latitude !== null)
        .map((sub) => {
          return (
            <MemoPin
              key={sub.locationUid}
              lat={sub.latitude}
              lng={sub.longitude}
              //pin={<MemoMapPin className="map-pin-spec-class" size="2rem" color="#9e0529" />}
              pin={<img src="./images/common/mappin.png" alt="" className="map-pin-spec-class" />}
              countryObject={country}
              representation={sub}
              tag={getUIID()}
              infoText={truncate(t(sub.type) + " " + t(sub.city) + ", " + t(country.country))}
              setState={() => setCenter(sub.latitude, sub.longitude)}
              onClick={onClick}
            />
          );
        });
    });
};

const getHeightFromLS = () => {
  try {
    const h = parseInt(localStorage.getItem("fh"));
    const w = parseInt(localStorage.getItem("w"));

    if (w >= 1248) {
      return "calc(100vh - 282px)";
    }

    if (w >= 1180) {
      return "calc(100vh - 282px)";
    }

    if (h > w) {
      return h - 130 + "px";
    } else {
      return w - 130 + "px";
    }
  } catch (error) {
    return "calc(100vh - 130px)";
  }
};

const getHeight = () => {
  let body = document.body;
  let html = document.documentElement;

  let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

  height = height - 130;
  let heightForLocalStorage = height;

  let ori = true;
  try {
    ori = localStorage.getItem("orient") === "norm";
  } catch (error) { }

  if (!ori) {
    height -= 0;
  }

  if (height) {
    height += "px";
  } else {
    height = "calc(100vh - 130px)";
  }

  localStorage.setItem("ch", heightForLocalStorage);
  return height;
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      mapCenter: this.props.center,
      zoom: 6,
      countries: [],
    };
  }

  componentDidMount() {
    const { t } = this.props;

    this.setState({
      isLoaded: true,
      countries: getMarkers(this.setCenter, t),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      mapCenter: nextProps.center,
      zoom: nextProps.center.zoom ?? 7,
    });
  }

  componentWillUnmount() {
    document.getElementById("main").classList.remove("map-style-fix");
  }

  setCenter = (lat, lng) => {
    this.setState({
      zoom: 11,
      mapCenter: { lat, lng },
    });
  };

  render() {
    const { error, isLoaded } = this.state;
    const { t } = this.props;

    if (error) {
      return <div>{`${t("pages.map.error")} ${t(error.message)}`}</div>;
    } else if (!isLoaded) {
      return <div>{t("pages.map.loading")}</div>;
    }
    return (
      // Important! Always set the container height explicitly
      <>
        <div id="google_map_c" key={this.state.center} style={{ height: getHeightFromLS(), margin: "0 -24px" }}>
          <GoogleMapReact
            //ref={this._map}
            center={this.state.mapCenter}
            zoom={this.state.zoom}
            bootstrapURLKeys={{
              key: MapsApiKey,
            }}
          >
            {this.state.countries}
          </GoogleMapReact>
        </div>
      </>
    );
  }
}

export default withTranslation()(Map);