import { MantineProvider } from '@mantine/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import ErrorModal from './Components/ErrorModal';
import Header from './Components/Header';
import LoadingComponent from './Components/LoadingComponent';
import GlobalContext from './Contexts/GlobalContext';
import { getLanguages } from './Locales/config';
import PageRoutes from './PageRoutes';
import pageTheme from './Style/pageTheme';

function App() {
    const [data, setData] = useState({} as Record<string, any>)
    const { isLoading, data: languages } = useQuery('getLanguages', () =>
        getLanguages().then(response => {
            setData(data => {
                data.languages = response
                return Object.assign({}, data)
            })
            return response
        })
    )

    return (
        <GlobalContext.Provider value={{ data, setData }}>
            <MantineProvider theme={pageTheme}>
                {
                    (isLoading || !languages) ? <LoadingComponent /> :
                        <>
                            <Header />
                        </>
                }
                <PageRoutes />
                <ErrorModal />
            </MantineProvider>
        </GlobalContext.Provider>
    )
}

export default App;
