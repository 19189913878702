import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import apipath from "../../apipath";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import PushContext from "../../context/PushContext";
import useFetch from "../../services/useFetch";
import MetaDecorator from "../../utils/MetaDecorator";
import { getIsLogged, setLocalStorage } from "../../utils/helpers";
import Loader from "../travel-registration/utils/Loader";
import FirstPushStepComponentReceivingPush from "./utils/1-push-step-component-receiving-push";

const initChoices = { needHelp: false, noHelp: false };

const FirstPushStep = () => {
  const [checked, setChecked] = useState(false);
  const [choices, setChoices] = useState(initChoices);
  const location = useLocation();

  const [cityReason, setCityReason] = useState({ city: "", reason: "" });

  const { id, longitude, latitude } = useParams();

  const history = useNavigate();

  const { setHeader } = useContext(HeaderContext);
  const { setPushNotifications } = useContext(PushContext);

  try {
    let loggedLS = getIsLogged();

    if (!(loggedLS && loggedLS.toString().toLowerCase() === "true")) {
      setLocalStorage("linkToGo", location.pathname);
      history(ApplicationRoute.loginBeforePushNotf);
    }
  } catch (error) { }

  const [updatePushError] = useState(null);
  const [updatePushLoading] = useState(true);
  const [submittingIpdatePush] = useState(false);

  const { data, error, loading } = useFetch(`${apipath.path}crisis/${id}`, "GET", null, false, true);

  useEffect(() => {
    if (updatePushError && updatePushError.status !== 401 && updatePushError.status !== 403) {
      history(ApplicationRoute.errorPage);
    }
  }, [updatePushError]);

  useEffect(() => {
    if (error && error.status !== 401 && error.status !== 403) {
      history(ApplicationRoute.errorPage);
    }
  }, [error]);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "noHeader",
      };
    });
  }, []);

  const onChoiceHandler = (e) => {
    setChoices(() => {
      return { ...initChoices, [e.target.id]: true };
    });
  };

  const onCheckedHandler = () => {
    setChecked((curr) => !curr);
  };

  const isValid = () => {
    return choices.needHelp || choices.noHelp;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isValid()) {
      const newPushObject = {
        needHelp: choices.needHelp,
        noHelp: choices.noHelp,
        shareLocation: checked,
        crisisId: id,
        longitude: longitude,
        latitude: latitude,
      };

      setPushNotifications(() => {
        return newPushObject;
      });

      history(ApplicationRoute.pushLogin);
    }
  };

  useEffect(() => {
    if (!loading) {
      setCityReason((curr) => {
        try {
          return {
            ...curr,
            reason: data.CrisisDescription,
            city: data.CrisisAreas[0].CountryName,
          };
        } catch {
          return {
            ...curr,
            reason: "",
            city: "",
          };
        }
      });
    }
  }, [loading]);

  if (loading) return <Loader />;
  if (updatePushLoading && submittingIpdatePush) return <Loader />;

  return (
    <>
      <MetaDecorator title="pageTitle.crisisWarningPage" />
      <FirstPushStepComponentReceivingPush
        onSubmit={onSubmit}
        onChoiceHandler={onChoiceHandler}
        choices={choices}
        onCheckedHandler={onCheckedHandler}
        checked={checked}
        isValid={isValid}
        weiterText="terms.weiter"
        showTopPart={true}
        city={cityReason.city}
        reason={cityReason.reason}
      />
    </>
  );
};

export default FirstPushStep;