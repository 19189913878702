import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  getEnumFromGender, getGenderFromText,
  getKeyByValue,
  getTravelGroupMemberLastIndex_Array,
} from "../../../utils/helpers";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import WeiterButton from "../../registration/utils/WeiterButton";
import Form from "../utils/Form";
import PageTitle from "../utils/PageTitle";

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

const AddTravelGender = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const genders = useMemo(
    () => {
      return {
        male: t("input.gender.options.male"),
        female: t("input.gender.options.female"),
        other: t("input.gender.options.other")
      }
    },
    [t]
  );

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((14 / 19) * 100),
      };
    });
  }, []);

  const history = useNavigate();
  const { travel, setTravel } = useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initGender = travel?.TravelGroupMember[groupMemberIndex]?.Gender
    ? getGenderFromText(travel?.TravelGroupMember[groupMemberIndex]?.Gender, t)
    : "";

  const [selectedValue, setSelectedValue] = useState({
    ...initSelectValue,
    [initGender]: true,
  });

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTravel = () => {
    const groupMemberIndex =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Gender: getEnumFromGender(getKeyByValue(selectedValue, true), t),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history(ApplicationRoute.addTravelMobilePhone);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <PageTitle id="pages.addRelative3.formLabel" text="pages.addRelative3.title" />
      <p
        className="page-paragraph page-paragraph--small"
        aria-label={t("input.mitreisende.many")}
      >
        {t("input.mitreisende.one")}
      </p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="pages.addRelative3.formLabel"
      >
        <ControledRadioInput
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelMobilePhone}
        onClick={onClickHandler}
        className="floating-weiter-buton"
      />
    </div>
  );
};

export default AddTravelGender;