import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { CacheKeyTravelInfo } from "../../../utils/const";
import { getCountryRiskWarningTranslationKey } from "../../../utils/helpers";
import Loader from "../../travel-registration/utils/Loader";
import {
  getCountryAliasFromUid,
  getCountryFlagFromCountryVB,
  getCountryNameFromUid,
  getCountryPicture,
} from "../../travel-registration/utils/countryInfo";
import CountryInfoHeader from "../utils/CountryInfoHeader";
import NavigationPattern from "../utils/NavigationPattern";

const CountryDetailedInfo = () => {
  const { countryUid } = useParams();
  const { setHeader } = useContext(HeaderContext);
  const [countries] = useState(JSON.parse(localStorage.getItem("countriesVB")).countries);
  const { t } = useTranslation();

  const [countryName] = useState(getCountryNameFromUid(countryUid));
  const [countryAlias] = useState(getCountryAliasFromUid(countryUid));
  const [hasRepresentations, setHasRepresentations] = useState(null);
  const [alternateRepresentations, setAlternateRepresentations] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: countryAlias.length > 0 ? t(countryAlias) + " (" + t(countryName) + ")" : t(countryName),
      };
    });
    fetchData();

    if (hasRepresentations == null || alternateRepresentations == null) {
      const arr = countries;
      let data = [];

      for (const element of arr) {
        if (element.countryUid === countryUid) {
          data = !!element.representations.length;

          if (!data && element.alternativeOrgaUnitUids.length) {
            setAlternateRepresentations(element.alternativeOrgaUnitUids);
          }

          break;
        }
      }
      setHasRepresentations(data);
    }
  }, []);

  // get travelInfoUrl from localStorage
  let travelInfoUrl = "";
  try {
    travelInfoUrl = `${apipath.path.replace("/api/v1/", "")}${countries.filter((c) => c.country === countryName)[0].middlewareUri}`;
  } catch (error) { }

  const [travelInfo, setTravelInfo] = useState({});

  useEffect(() => {
    localStorage.setItem("travelInfo", JSON.stringify(travelInfo));
  }, [travelInfo]);

  const fetchData = async () => {
    setLoading(() => true);

    // Enable cache and autoload with the country name as a key
    const response = await fetch(
      travelInfoUrl,
      { method: "GET" },
      true,
      true,
      "json",
      CacheKeyTravelInfo + countryName
    );
    const travelInfo = await response.json();
    setTravelInfo(travelInfo);
    setLoading(() => false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <MetaDecorator title={countryName} />
      <CountryInfoHeader
        flag={getCountryFlagFromCountryVB(countryName)}
        countryImage={getCountryPicture(countryName)}
        Country={countryAlias.length > 0 ? t(countryAlias) + " (" + t(countryName) + ")" : countryName}
        RiskLvl={getCountryRiskWarningTranslationKey(countries.filter((country) => t(country.country) === t(countryName))[0].riskLevel)}
        className="country-flag__position-relative"
      />
      {(hasRepresentations || !!alternateRepresentations?.length) &&
        <Link
          style={{ textDecoration: "none", color: "#000000" }}
          to={`/country/${countryUid}/botschaften-und-konsulate`}
          className="navigation-info-link"
        >
          <NavigationPattern to={`/country/${countryUid}/botschaften-und-konsulate`} text="pages.embassy.title" />
        </Link>
      }

      {travelInfo.data?.map((info, index) => {
        if (info.header !== "Stand") {
          return (
            <Link
              style={{ textDecoration: "none", color: "#000000" }}
              to={`/countryInfo/${countryUid}/${info.header}`}
              className="navigation-info-link"
            >
              <NavigationPattern key={index} text={info.header} />
            </Link>
          );
        }
      })}
      <p className="stand-text">
        {t(travelInfo.data?.find((info) => info.header === "Stand")?.bodytext)}
      </p>
    </>
  );
};

export default CountryDetailedInfo;
