import dateAndTime from "date-and-time";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HorizontalRule from "../../common/HorizontalRule";
import InputValidation from "../../common/InputValidaiton";
import { TravelStops } from "../../config/defaultTravelValues";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import SuccessContext from "../../context/SuccessContext";
import TravelContext from "../../context/TravelContext";
import MetaDecorator from "../../utils/MetaDecorator";
import { convertAppDateToMiddlewareDateFormat, getIsLogged, getUIID, isMobile, pluck } from "../../utils/helpers";
import FilteredSelectList from "../foreign-registration/utils/FilteredSelectList";
import WeiterButton from "../registration/utils/WeiterButton";
import AddStopButton from "./utils/AddStopButton";
import getCountries from "./utils/GetCountries";
import Input from "./utils/Input";
import InputCalendar from "./utils/InputCalendar";
import PageTitle from "./utils/PageTitle";
import PageParagraph from "./utils/Paragraph";

const numbering = [
  "input.numbering.1",
  "input.numbering.2",
  "input.numbering.3",
  "input.numbering.4",
  "input.numbering.5",
  "input.numbering.6",
  "input.numbering.7",
  "input.numbering.8",
  "input.numbering.9",
  "input.numbering.10",
  "input.numbering.11",
  "input.numbering.12",
];

const AddTravel = () => {
  const { t } = useTranslation();
  const { travel, setTravel } = useContext(TravelContext);
  const { setHeader } = useContext(HeaderContext);
  const { setSuccessBox } = useContext(SuccessContext);

  let [destinations, setDestinations] = useState([]);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);

  const [updateRef, setUpdateRef] = useState(false);
  let ref = useRef();

  useEffect(() => {
    setDestinations(() => {
      return travel.TravelStops[0].StopStart
        ? travel.TravelStops.map((el) => {
          el.StopStart = new Date(el.StopStart);
          el.StopEnd = new Date(el.StopEnd);
          return el;
        })
        : travel.TravelStops;
    });
  }, []);
  if (!countriesFetched)
    getCountries().then((data) => {
      setCountriesFetched(true);
      setCountries(
        pluck(data, "Name").filter((c) => {
          return !(c === t("terms.austria") || c === t("terms.other_countries"));
        })
      );
    });

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((1 / 19) * 100),
      };
    });
  }, []);

  const history = useNavigate();
  if (!getIsLogged()) {
    history(ApplicationRoute.home);
  }

  function alertMessage(hidden, text) {
    setSuccessBox((curr) => {
      return {
        ...curr,
        hidden,
        text: t(text),
        trigger: getUIID(),
        alert: true,
      };
    });
  }

  // Add new Destination
  const addDestination = () => {
    setUpdateRef(true);
    setDestinations([...destinations, Object.assign({}, TravelStops)]);
  };

  useEffect(() => {
    if (updateRef) {
      setUpdateRef(false);
      ref.current.focus();
    }
  });

  const deleteDestination = (targetIndex) => {
    if (targetIndex > 0) {
      destinations = destinations.filter((destination, index) => {
        return index !== targetIndex;
      });
      setDestinations([...destinations]);
    }
  };

  const destinationCountryChangeHandler = (index, value) => {
    destinations[index].CountryId = value;
    destinations[index].Country = value;
    setDestinations([...destinations]);
  };

  const destionationCityChangeHandler = (index, e) => {
    destinations[index].Comment = e.target.value;
    setDestinations([...destinations]);
  };

  const saveTravelData = (e) => {
    history(ApplicationRoute.registerName);

    //Set addTravel in localStorage

    let travelCopy = Object.assign({}, travel);

    travelCopy.StartDate = convertAppDateToMiddlewareDateFormat(
      dateAndTime.format(destinations[0].StopStart, "DD.MM.YYYY")
    );
    travelCopy.EndDate = convertAppDateToMiddlewareDateFormat(
      dateAndTime.format(destinations[destinations.length - 1].StopEnd, "DD.MM.YYYY")
    );

    travelCopy.TravelStops = [];

    destinations.forEach((destination, index) => {
      let travelStopsCopy = Object.assign({}, TravelStops);

      travelStopsCopy.CountryId = destination.CountryId;
      travelStopsCopy.Country = destination.Country;
      travelStopsCopy.Name = destination.Country;
      travelStopsCopy.StopStart = convertAppDateToMiddlewareDateFormat(
        dateAndTime.format(destination.StopStart, "DD.MM.YYYY")
      );
      travelStopsCopy.StopEnd = convertAppDateToMiddlewareDateFormat(
        dateAndTime.format(destination.StopEnd, "DD.MM.YYYY")
      );
      travelStopsCopy.Comment = destination.Comment;

      travelCopy.TravelStops.push(travelStopsCopy);
    });
    setTravel(travelCopy);
  };

  const validator = () => {
    let bool = true;
    if (destinations.length && bool) {
      for (const destination of destinations) {
        if (
          destination.CountryId === "" ||
          destination.StopStart === null ||
          !destination.CountryId ||
          !destination.StopStart
        ) {
          bool = false;
          break;
        }
      }
    }

    return bool;
  };

  function destinationsOverlapsing(destinationsArr) {
    destinationsArr.forEach((element) => (element.overlapsing = false));

    let showAlertMessage = false;

    for (let i = 0; i < destinationsArr.length; i++) {
      for (let j = i + 1; j < destinationsArr.length; j++) {
        let start1 = new Date(destinationsArr[i].StopStart);
        let start2 = new Date(destinationsArr[j].StopStart);
        let end1 = new Date(destinationsArr[i].StopEnd);
        let end2 = new Date(destinationsArr[j].StopEnd);

        if (end1 > start2 && ((start1 > start2 && start1 < end2) || (end1 > start2 && end1 < end2))) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        } else if (end2 > start1 && ((start2 > start1 && start2 < end1) || (end2 > start1 && end2 < end1))) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        } else if (
          start1 < end1 &&
          start2 < end2 &&
          start1.toString() === start2.toString() &&
          end1.toString() === end2.toString()
        ) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        }
      }
    }

    if (showAlertMessage) {
      alertMessage(false, t("pages.editTravel.alertMessage") + "%alert%");
    }

    return destinationsArr;
  }

  const receiveDataForDestination = (index, StopStart, StopEnd) => {
    StopStart.setHours(0, 0, 0);
    StopEnd.setHours(0, 0, 0);

    let destinationsArr = [...destinations];
    destinationsArr[index].StopStart = StopStart;
    destinationsArr[index].StopEnd = StopEnd;

    setDestinations(destinationsOverlapsing(destinationsArr));
  };

  function formattingForHeading(index) {
    return (index < 12 ? t(numbering[index]) : index + 1 + ".") + " " + t("input.destination.oneText");
  }

  return (
    <>
      <section role="group" className="registration-middle-container add-travel" aria-describedby="add-travel">
        <MetaDecorator title="pageTitle.createTripPages" />
        <PageTitle id="add-travel" text="pages.addTravel.pageTitle" />
        <PageParagraph text="pages.addTravel.paragraph" />
        <HorizontalRule className="horizontal-rule-desktop" />
        <form className="max-width-380">
          <div className="destination-container">
            {destinations.length > 0
              && destinations.map((destination, index) => {
                return (
                  <div className="destination-group" id={index + 1 + "-destination"} key={index + 1 + "-destination"}>
                    <h3 className="destination-group__heading">
                      {formattingForHeading(index)}
                    </h3>
                    {index == 0 ? null : (
                      <button
                        className="destination-group__delete-btn"
                        type="button"
                        onClick={(e) => deleteDestination(index)}
                      >
                        {t("pages.editTravel.deleteDestAlertBtn")}
                      </button>
                    )}
                    <FilteredSelectList
                      id={"destination-country-" + (index + 1)}
                      ariaLabelledBy="add-travel"
                      label="input.select.text"
                      state={destination.Country}
                      onChangeHandler={(value) => destinationCountryChangeHandler(index, value)}
                      options={countries}
                      className={isMobile() ? "grey-background" : ""}
                      ref={ref}
                      isTravel={true}
                      ariaLabelType="countries"
                    />

                    <Input
                      labelText="input.city.text2"
                      id={index + 1 + "-stadt"}
                      onChange={(e) => destionationCityChangeHandler(index, e)}
                      placeholder="input.city.placeholderOptional"
                      value={destination.Comment}
                    />
                    <InputCalendar
                      labelText={`${t("input.travelTime.text")} ${index < 12 ? t(numbering[index]).toLowerCase() : index + 1 + "."
                        } ${t("input.destination.oneText")}`}
                      id={index + 1 + "-reisezeitram"}
                      placeholder="input.travelTime.text"
                      startDate={destination.StopStart}
                      endDate={destination.StopEnd}
                      onChange={(startDate, endDate) => receiveDataForDestination(index, startDate, endDate)}
                      error={destination.overlapsing}
                    />
                    <InputValidation
                      text="errors.datesAreOverlapsing"
                      isHidden={!destination.overlapsing}
                    />
                  </div>
                );
              })
            }
          </div>

          <AddStopButton text="input.destination.anotherAddOne" target={addDestination} />
        </form>
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!validator()}
          path={ApplicationRoute.registerName}
          onClick={saveTravelData}
          positioned="static"
          style={{ marginTop: "5rem" }}
          className="floating-weiter-buton"
          classFix="yes"
        />
      </section>
    </>
  );
};

export default AddTravel;