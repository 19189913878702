import apipath from "../../../apipath.js";
import ApplicationRoute from "../../../config/routes.js";
import { loggedInNotRedirectedRoutes } from "../../../config/routesRole.js";
import fetcher from "../../../services/fetcher.js";
import {
  GetBundeslandNameFromId,
  removeAllSlashes,
  setIsLoggedTrue,
  setUserValuesOnLogin
} from "../../../utils/helpers.js";

export const initLoginCred = { email: false, password: false };

export function login(email, password, setNoLoginCred, setLoginData, setLoginError, setLoginLoading, setSubmittingLogin) {
  if (!email) {
    setNoLoginCred(() => {
      return { ...initLoginCred, email: true };
    });

    return;
  } else if (!password) {
    setNoLoginCred(() => {
      return { ...initLoginCred, password: true };
    });

    return;
  }

  fetcher(
    `${apipath.path}login`,
    "POST",
    setLoginData,
    setLoginError,
    setLoginLoading,
    setSubmittingLogin,
    true,
    "application/x-www-form-urlencoded;charset=UTF-8",
    new URLSearchParams({ username: email.trim(), password: password }),
    false,
    true
  );
}

export const loginUser = (loginData, setUser, setIsLogged, history, t) => {
  localStorage.removeItem("registerUser");

  let userData = { ...loginData };

  userData = {
    ...userData,
    CountyHomeRegionName: t(GetBundeslandNameFromId(userData.HomeRegion)),
    CountyHomeRegionId: userData.HomeRegion,
    //County: "00000000-0000-0000-0000-000000000000",
    HomeRegion: "00000000-0000-0000-0000-000000000000",
  };

  setUser(() => {
    return setUserValuesOnLogin(userData);
  });

  setIsLoggedTrue();
  setIsLogged(true);

  history(ApplicationRoute.TravelDashboardNoPermissions);
};

export const loginRedirects = (user, history) => {
  if (!user.GDPRAccountLatestVersion) {
    history(ApplicationRoute.dseConfirmationPage);
    return true
  }
  if (user.GDPRForeignAustrianOfficialInfoViewflag) {
    history(ApplicationRoute.infoMailPage);
    return true
  }
  if (user.GDPRForeignAustrianInofficialInfoViewflag) {
    history(ApplicationRoute.infoMailPage);
    return true
  }

  return false;
};

export const loginRedirectsChecked = (user, history, urlToCheck) => {
  let redirect = true;
  Object.values(loggedInNotRedirectedRoutes).forEach((r) => {
    if (urlToCheck.includes(removeAllSlashes(r))) {
      redirect = false;
    }
  });
  if (!redirect) {
    return false;
  }

  return loginRedirects(user, history);
};

export const isLoginRedirected = (user) => {
  return !user.GDPRAccountLatestVersion ||
    !!user.GDPRForeignAustrianOfficialInfoViewflag ||
    !!user.GDPRForeignAustrianInofficialInfoViewflag;
}