import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router";
import apipath from "../../apipath";
import AlertBox from "../../common/AlertBox";
import ClearContanctDiv from "../../common/ClearContactDiv";
import EditInput from "../../common/EditInput";
import HorizontalRule from "../../common/HorizontalRule";
import InputValidation from "../../common/InputValidaiton";
import MainButton from "../../common/MainButton";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import SuccessContext from "../../context/SuccessContext";
import UserContext from "../../context/UserContext";
import fetcher from "../../services/fetcher";
import useFetch from "../../services/useFetch";
import MetaDecorator from "../../utils/MetaDecorator";
import {
  FormatPhone,
  GetBundeslandIdFromName,
  convertAppDateToMiddlewareDateFormat,
  dateFormatter,
  getCountryNameFromId__countries_from_arg,
  getEnumFromGenderText,
  getGenderTextFromEnum,
  getKeyByValue,
  getSecondAfterSpace,
  getUIID,
  getYMDfromMiddleware,
  getYMDfromMiddlewarePlusOneDay,
  getYMDfromMiddleware__FOR__APP__USE,
  getYearMonthDateFromNewDate,
  getYearMonthDateFromNewDateMiddlewareFormat,
  hasMiddleName,
  isIn,
  isValidContactPerson,
  lengthForTravelName,
  logMessage,
  phoneFormatter,
  pluck,
  setApiUserObjectUpdate
} from "../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasAtSymbolEmailValidation,
  hasMinLettersValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isOlderOrIsEighteenYearsOlds,
  isPassnumberValidation,
  isPersonalNumberValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
  travelGroupMemberValidation,
} from "../../utils/validation";
import Form from "../registration/utils/Form";
import InputCalendar from "../travel-registration/utils/InputCalendar";
import Loader from "../travel-registration/utils/Loader";
import { getCountryPicture } from "../travel-registration/utils/countryInfo";
import AddTravel from "./utils/AddTravel";
import InfoContainer from "./utils/InfoContainer";
import Relative from "./utils/Relative";
import StopsSeparater from "./utils/StopsSeparater";

const countriesSelectOptions = [
  "input.bundesland.options.burgenland",
  "input.bundesland.options.kaernten",
  "input.bundesland.options.niederoesterreich",
  "input.bundesland.options.oberoesterreich",
  "input.bundesland.options.salzburg",
  "input.bundesland.options.steiermark",
  "input.bundesland.options.tirol",
  "input.bundesland.options.vorarlberg",
  "input.bundesland.options.wien",
];

const EditTravel = () => {
  let countries_full_data_LS = JSON.parse(localStorage.getItem("countries"));

  let countriesLS = pluck(countries_full_data_LS, "Name");
  countriesLS = countriesLS ? countriesLS.filter((n) => !isIn(n)) : countriesLS;

  const { id } = useParams();
  const { t } = useTranslation();
  const history = useNavigate();

  const location = useLocation();

  const [alertBoxHidden, setAlertBoxHidden] = useState(true);
  const [alertBoxHiddenStopsSep, setAlertBoxHiddenStopsSep] = useState(true);
  const [leftButton, setLeftButton] = useState("");

  const [relativeAlertBox, setRelativeAlertBox] = useState({
    hidden: true,
    func: "",
    text: "",
  });

  const [tempData, setTempData] = useState("");

  const [travelTourInfo, setTravelTourInfo] = useState({
    isTourGroup: t("input.jaNein.nein"),
    tourOperator: "",
  });

  const [travelBoxHidden, setTravelBoxHiddenStatus] = useState(true);

  const { user, setUser } = useContext(UserContext);

  const { ContactPerson: contactPersonInfo, ...userInfo } = user;

  let pn;
  try {
    pn = userInfo.PhoneNumber.length > 5 ? userInfo.PhoneNumber : "";
  } catch (error) { }

  const [userInfoState, setUserInfoState] = useState({
    ...userInfo,
    PhoneNumber: pn,
  });
  const [contactPersonInfoState, setContactPersonInfoState] = useState({
    ...contactPersonInfo,
  });

  const [travelStartStop, setTravelStartStop] = useState("");
  const [travelImage, setTravelImage] = useState("");

  const [unsavedChangesAlertHidden, setUnsavedChangesAlertHidden] = useState(true);

  let [destinations, setDestinations] = useState([]);

  const [changed, setChanged] = useState(true);

  const relationshipEnums = useMemo(
    () => {
      const rel = {};

      rel[t("input.relative.options.parent")] = 100000000;
      rel[t("input.relative.options.sibling")] = 100000001;
      rel[t("input.relative.options.child")] = 100000002;
      rel[t("input.relative.options.partner")] = 100000003;
      rel[t("input.relative.options.etc")] = 100000004;

      return rel;
    },
    [t]
  );

  const countriesId = useMemo(
    () => {
      const rel = {};

      rel[t("input.bundesland.options.optional")] = null;
      rel[t("input.bundesland.options.burgenland")] = "c166d9bb-3862-eb11-a829-000d3a46d73b";
      rel[t("input.bundesland.options.kaernten")] = "c266d9bb-3862-eb11-a829-000d3a46d73b";
      rel[t("input.bundesland.options.niederoesterreich")] = "3275fbd4-1c6d-eb11-a838-000d3a46d73b";
      rel[t("input.bundesland.options.oberoesterreich")] = "3475fbd4-1c6d-eb11-a838-000d3a46d73b";
      rel[t("input.bundesland.options.salzburg")] = "3675fbd4-1c6d-eb11-a838-000d3a46d73b";
      rel[t("input.bundesland.options.steiermark")] = "3875fbd4-1c6d-eb11-a838-000d3a46d73b";
      rel[t("input.bundesland.options.tirol")] = "3a75fbd4-1c6d-eb11-a838-000d3a46d73b";
      rel[t("input.bundesland.options.vorarlberg")] = "3c75fbd4-1c6d-eb11-a838-000d3a46d73b";
      rel[t("input.bundesland.options.wien")] = "3e75fbd4-1c6d-eb11-a838-000d3a46d73b";

      return rel;
    },
    [t]
  );

  useEffect(() => {
    let dates = [];
    destinations.forEach((d) => {
      dates.push(d.StopEnd);
      dates.push(d.StopStart);
    });
    let maxDate = new Date(Math.max(...dates));
    maxDate = getYearMonthDateFromNewDate(maxDate);

    let minDate = new Date(Math.min(...dates));
    minDate = getYearMonthDateFromNewDate(minDate);

    setTravelStartStop(minDate + " - " + maxDate);

    setTitle(getTravelStopNamesFromName());

    setTravelImage(getImage);
  }, [destinations]);

  const onUserInfoChangeHandler = (e) => {
    setChanged(true);
    if (e.target.id === "isTourGroup") {
      setTravelTourInfo((curr) => {
        let tp = e.target.value === t("input.jaNein.ja") ? curr.tourOperator : "";
        return { ...curr, tourOperator: tp, isTourGroup: e.target.value };
      });

      return;
    }

    setUserInfoState((curr) => {
      if (e.target.id === "CountyHomeRegionName") {
        curr.CountyHomeRegionName = e.target.value;
        curr.CountyHomeRegionId = GetBundeslandIdFromName(e.target.value, t);
      }

      if (e.target.id === "HomeRegion") {
        return {
          ...curr,
          [e.target.id]: countriesId[e.target.value],
        };
      }

      if (e.target.id === "BirthDate") {
        return {
          ...curr,
          [e.target.id]: dateFormatter(userInfoState.BirthDate, e.target.value),
        };
      }

      if (e.target.id === "PhoneNumber") {
        return {
          ...curr,
          [e.target.id]: phoneFormatter(userInfoState.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onTourGroupChangeHandler = (e) => {
    setChanged(true);
    setTravelTourInfo((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onContactPersonInfoChangeHandler = (e) => {
    setChanged(true);
    setContactPersonInfoState((curr) => {
      let key = e.target.id.replace("Notfalkontakt", "");

      if (key === "PhoneNumber") {
        return {
          ...curr,
          [key]: phoneFormatter(contactPersonInfoState.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [key]: e.target.value };
    });
  };

  const { data, loading } = useFetch(`${apipath.path}users/CURRENT/travels/${id}`, "GET");

  //Travelstops

  const receiveDataForDestination = (index, StopStart, StopEnd) => {
    setChanged(true);
    let newarr = destinations;
    newarr[index].StopStart = StopStart;
    newarr[index].StopEnd = StopEnd;

    setDestinations(() => destinationsOverlapsing([...newarr]));
  };

  const onChangeCommentTravelStops = (e, index) => {
    let newarr = [...destinations];
    newarr[index].Comment = e.target.value;

    setDestinations([...newarr]);
  };

  const { setSuccessBox } = useContext(SuccessContext);
  const { setHeader } = useContext(HeaderContext);

  const [, setDeleteTravelData] = useState(null);
  const [deleteTravelError, setDeleteTravelError] = useState(null);
  const [deleteTravelLoading, setDeleteTravelLoading] = useState(true);
  const [deleteTravelSubmitting, setDeleteTravelSubmitting] = useState(false);

  const deleteTravel = () => {
    fetcher(
      `${apipath.path}users/CURRENT/travels/${id}`,
      "DELETE",
      setDeleteTravelData,
      setDeleteTravelError,
      setDeleteTravelLoading,
      setDeleteTravelSubmitting,
      false,
      "application/json"
    );
  };

  const onDeleteTravelHandler = () => {
    setAlertBoxHidden(false);
  };

  const onClickLeftButtonAlertBoxHandler = () => {
    deleteTravel();
  };

  const onClickAlertBoxRightButtonAlertBoxHandler = () => {
    setAlertBoxHidden(true);
  };

  useEffect(() => {
    if (!deleteTravelLoading && !deleteTravelError) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.editTravel.deleteTravelSuccess"),
          trigger: getUIID(),
        };
      });
      history(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [deleteTravelError, deleteTravelLoading]);

  const getTravelStopNamesFromName = () => {
    if (!destinations) {
      return "";
    }
    let title = "";
    destinations.forEach((ts) => {
      title += ` ${t(ts.Name)},`;
    });

    title = title.substring(0, title.length - 1);

    title = lengthForTravelName(title);

    return title;
  };

  const getTravelNameWithCut = () => {
    //ADD CUT ***
    let title = getTravelName();
    return lengthForTravelName(title);
  };

  const getTravelName = () => {
    if (!tempData) {
      return "";
    }
    let title = "";
    tempData.TravelStops.forEach((ts) => {
      title += ` ${t(ts.Name)},`;
    });

    title = title.substring(0, title.length - 1).trim();

    if (title.length > 400) {
      title = title.substring(0, 396).concat(" ...");
    }

    return title;
  };

  const getImage = () => {
    if (!destinations || destinations.length === 0) {
      return "";
    }

    const name = t(destinations[0].Name);
    return getCountryPicture(name);
  };

  const setTravelBoxHidden = (boolean) => {
    setTravelBoxHiddenStatus(boolean);

    if (document.getElementsByTagName("body").item(0).classList.contains("overflow-hidden")) {
      document.getElementsByTagName("body").item(0).classList.remove("overflow-hidden");
    } else {
      document.getElementsByTagName("body").item(0).classList.add("overflow-hidden");
    }
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "editAO",
        progress: "no",
        title: getTravelStopNamesFromName(),
        customGoBackFunc: () => goBackHeaderFunc(ref),
      };
    });
  }, [destinations]);

  //When data obj is changed useEffect will be exec. If there is no obj -> [] than it is exec only once after page loading
  //Bad useEffect! -> Needs refactoring in order to enable React.StrictMode again.
  useEffect(() => {
    if (data) {
      //IF not added TGM -> TravelGroupMember
      const check = !!localStorage.getItem("addedTGM");

      if (!check) {
        setDestinations(
          data.TravelStops.map((el) => {
            const ss = getYMDfromMiddleware(el.StopStart);
            el.StopStart = new Date(ss.year, ss.month, ss.day);
            const se = getYMDfromMiddleware(el.StopEnd);
            el.StopEnd = new Date(se.year, se.month, se.day);

            return el;
          })
        );

        setTravelTourInfo((curr) => {
          return {
            ...curr,
            isTourGroup: data.IsTourGroup ? t("input.jaNein.ja") : t("input.jaNein.nein"),
            tourOperator: data.Comment ?? "",
          };
        });

        const newTGMarray =
          "TravelGroupMember" in data
            ? data.TravelGroupMember.map((tgm) => {
              return {
                ...tgm,
                Birthdate: tgm.Birthdate ? getYMDfromMiddleware__FOR__APP__USE(tgm.Birthdate) : "",
                MobilePhone: tgm.MobilePhone ? FormatPhone(tgm.MobilePhone) : "",
                DegreeOfRelationship: tgm.DegreeOfRelationship
                  ? getKeyByValue(relationshipEnums, tgm.DegreeOfRelationship)
                  : "",
                Nationality: tgm.Nationality
                  ? getCountryNameFromId__countries_from_arg(tgm.Nationality, countries_full_data_LS)
                  : "",
              };
            })
            : [];

        setTempData({ ...data, TravelGroupMember: newTGMarray });
        localStorage.setItem("tempTravel", JSON.stringify({ ...data, TravelGroupMember: newTGMarray }));
        localStorage.setItem("tempUser", JSON.stringify(user));
      }
      ///
      else {
        //IF added TGM
        try {
          //Set added tgm

          let td = JSON.parse(localStorage.getItem("tempData"));

          let tgmFromLS = JSON.parse(localStorage.getItem("newTGM"));

          const newTGM = [{ ...tgmFromLS }];

          if ("Nationality" in tgmFromLS) {
            //should be added normally new TGM
            if ("TravelGroupMember" in td) {
              td = {
                ...td,
                TravelGroupMember: td.TravelGroupMember.concat(newTGM),
              };
            } else {
              td = { ...td, TravelGroupMember: newTGM };
            }
          }

          setDestinations(
            td.TravelStops.map((el) => {
              const ss = getYMDfromMiddlewarePlusOneDay(el.StopStart);
              el.StopStart = new Date(ss.year, ss.month, ss.day);
              const se = getYMDfromMiddlewarePlusOneDay(el.StopEnd);
              el.StopEnd = new Date(se.year, se.month, se.day);

              return el;
            })
          );

          const tti = JSON.parse(localStorage.getItem("travelTourInfo"));

          setTravelTourInfo((curr) => {
            return {
              ...curr,
              isTourGroup: tti.isTourGroup ? t("input.jaNein.ja") : t("input.jaNein.nein"),
              tourOperator: tti.tourOperator ?? "",
            };
          });
          setTempData(td);

          const uis = JSON.parse(localStorage.getItem("userInfoState"));

          setUserInfoState(() => uis);

          localStorage.setItem("addedTGM", "");
          localStorage.setItem("userInfoState", "");
          localStorage.setItem("tempData", "");
          localStorage.setItem("travelTourInfo", "");
          localStorage.setItem("addTGMURL", "");
          localStorage.setItem("addTGMTitle", "");
          localStorage.setItem("newTGM", "");
        } catch (error) {
          //
          //remove temps
          localStorage.setItem("addedTGM", "");
          localStorage.setItem("userInfoState", "");
          localStorage.setItem("tempData", "");
          localStorage.setItem("travelTourInfo", "");
          localStorage.setItem("addTGMURL", "");
          localStorage.setItem("addTGMTitle", "");
          localStorage.setItem("newTGM", "");
          //
          setDestinations(
            data.TravelStops.map((el) => {
              const ss = getYMDfromMiddleware(el.StopStart);
              el.StopStart = new Date(ss.year, ss.month, ss.day);
              const se = getYMDfromMiddleware(el.StopEnd);
              el.StopEnd = new Date(se.year, se.month, se.day);

              return el;
            })
          );

          setTravelTourInfo((curr) => {
            return {
              ...curr,
              isTourGroup: data.IsTourGroup ? t("input.jaNein.ja") : t("input.jaNein.nein"),
              tourOperator: data.Comment ?? "",
            };
          });

          const newTGMarray =
            "TravelGroupMember" in data
              ? data.TravelGroupMember.map((tgm) => {
                return {
                  ...tgm,
                  Birthdate: getYMDfromMiddleware__FOR__APP__USE(tgm.Birthdate ?? ""),
                  MobilePhone: FormatPhone(tgm.MobilePhone ?? ""),
                  Gender: getGenderTextFromEnum(tgm.Gender ?? "", t),
                  DegreeOfRelationship: getKeyByValue(relationshipEnums, tgm.DegreeOfRelationship ?? ""),
                  Nationality: getCountryNameFromId__countries_from_arg(
                    tgm.Nationality ?? "",
                    countries_full_data_LS
                  ),
                };
              })
              : [];

          setTempData({ ...data, TravelGroupMember: newTGMarray });
        }
      }
    }
  }, [data, t]);

  useEffect(() => {
    if (tempData) {
      setDestinations(() => destinationsOverlapsing(tempData.TravelStops));
    }
  }, [tempData]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (isValid()) updateUserMiddleware();
  };

  const [, setUpdateProfile] = useState(null);
  const [updateProfileError, setUpdateProfileError] = useState(null);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(true);
  const [submttingUpdateProfile, setSubmttingUpdateProfile] = useState(false);

  const [, setUpdateTravel] = useState(null);
  const [updateTravelError, setUpdateTravelError] = useState(null);
  const [updateTravelLoading, setUpdateTravelLoading] = useState(true);
  const [submittingUpdateTravel, setSubmittingUpdateTravel] = useState(false);

  const [title, setTitle] = useState("");

  const updateUserMiddleware = () => {
    let toSend = { ...user, ContactPerson: contactPersonInfoState };
    toSend = Object.assign(toSend, userInfoState);

    fetcher(
      `${apipath.path}users/CURRENT`,
      "PUT",
      setUpdateProfile,
      setUpdateProfileError,
      setUpdateProfileLoading,
      setSubmttingUpdateProfile,
      false,
      "application/json",
      JSON.stringify(setApiUserObjectUpdate(toSend, t))
    );

    let toSetUser = {
      ...toSend,
      EmergencyEmail: contactPersonInfoState.Email ?? "",
      EmergencyFirstLastName: contactPersonInfoState.FirstLastName ?? "",
      EmergencyPhoneNumber: contactPersonInfoState.PhoneNumber ?? "",
    };

    setUser(() => toSetUser);
  };

  useEffect(() => {
    if (!updateProfileLoading && !updateProfileError) {
      updateTravelApi();
    }
  }, [updateProfileLoading]);

  const updateTravelApi = () => {
    let countryLS = JSON.parse(localStorage.getItem("countries"));

    let newTravelStopArray = destinations.map((d) => {
      let cId = countryLS.filter((c) => c.Name === d.Name);
      cId = cId?.[0].Id;

      return {
        ...d,
        CountryId: cId,
        StopEnd: getYearMonthDateFromNewDateMiddlewareFormat(new Date(d.StopEnd)),
        StopStart: getYearMonthDateFromNewDateMiddlewareFormat(new Date(d.StopStart)),
      };
    });

    let dates = [];
    destinations.forEach((d) => {
      dates.push(d.StopEnd);
      dates.push(d.StopStart);
    });

    const maxDate = getYearMonthDateFromNewDateMiddlewareFormat(new Date(Math.max(...dates)));
    const minDate = getYearMonthDateFromNewDateMiddlewareFormat(new Date(Math.min(...dates)));

    let newTGM_array = [...tempData.TravelGroupMember];
    newTGM_array = newTGM_array.map((tgm) => {
      return {
        ...tgm,
        Birthdate: convertAppDateToMiddlewareDateFormat(tgm.Birthdate),
        DegreeOfRelationship: relationshipEnums[tgm.DegreeOfRelationship],
        Nationality: countries_full_data_LS.filter((c) => c.Name === tgm.Nationality)[0].Id,
        MobilePhone: tgm.MobilePhone?.replace(/\s/g, "") || null,
        Gender: getEnumFromGenderText(tgm.Gender, t),
        Email: tgm.Email || null,
        LastName: getSecondAfterSpace(tgm.Name.trim()).trim(),
      };
    });

    let toSend = {
      ...data,
      Name: getTravelName(),
      StartDate: minDate,
      EndDate: maxDate,
      IsTourGroup: travelTourInfo.isTourGroup === t("input.jaNein.ja"),
      Comment: travelTourInfo.tourOperator,
      TravelStops: newTravelStopArray,
      TravelGroupMember: newTGM_array,
    };

    logMessage("*****API OBJECT TO SEND** TRAVEL *** EDIT TRAVEL ******", "Red");

    fetcher(
      `${apipath.path}users/CURRENT/travels/${id}`,
      "PUT",
      setUpdateTravel,
      setUpdateTravelError,
      setUpdateTravelLoading,
      setSubmittingUpdateTravel,
      false,
      "application/json",
      //GDPR*
      JSON.stringify({ ...toSend, GDPRTravel: true })
    );
  };

  useEffect(() => {
    if (!updateTravelLoading && !updateTravelError) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.editTravel.editTravelSuccess"),
          trigger: getUIID(),
        };
      });
      history(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [updateTravelLoading]);

  ///
  const saveChangeHeaderFunc = () => {
    setUnsavedChangesAlertHidden(true);

    window.scrollTo(0, document.body.scrollHeight);
  };

  const goBackHeaderFunc = (data) => {
    const { contactPerson, destinations, tempData, travelTourInfo, userInfo } = data.current;

    const lsTravel = JSON.parse(localStorage.getItem("tempTravel"));
    const lsUser = JSON.parse(localStorage.getItem("tempUser"));

    const sameContactPerson = JSON.stringify(lsUser.ContactPerson) === JSON.stringify(contactPerson);
    delete lsUser.ContactPerson;

    try {
      const sameDestinations =
        JSON.stringify(destinationsOverlapsing(lsTravel.TravelStops)) ===
        JSON.stringify(destinationsOverlapsing(destinations));
      const sameUserInfo = JSON.stringify(lsUser) === JSON.stringify(userInfo);
      const sameTData = JSON.stringify(lsTravel) === JSON.stringify(tempData);

      const isTourGroup = travelTourInfo.isTourGroup === t("input.jaNein.ja");
      const travelComment = lsTravel.Comment ?? "";
      const sameTourInfo =
        (isTourGroup === false && lsTravel.IsTourGroup === false) ||
        (lsTravel.IsTourGroup === isTourGroup && travelComment === travelTourInfo.tourOperator);

      const noChanges = sameContactPerson && sameDestinations && sameUserInfo && sameTData && sameTourInfo;
      if (noChanges) {
        history(ApplicationRoute.TravelDashboardNoPermissions);
      } else {
        setUnsavedChangesAlertHidden(false);
      }
    } catch (error) {
      setUnsavedChangesAlertHidden(false);
    }
  };

  const ref = useRef({});

  useEffect(() => {
    ref.current.contactPerson = contactPersonInfoState;
  }, [contactPersonInfoState]);

  useEffect(() => {
    ref.current.userInfo = userInfoState;
  }, [userInfoState]);

  useEffect(() => {
    ref.current.tempData = tempData;
  }, [tempData]);

  useEffect(() => {
    ref.current.destinations = destinations;
  }, [destinations]);

  useEffect(() => {
    ref.current.travelTourInfo = travelTourInfo;
  }, [travelTourInfo]);

  ///////

  const onClickLeftButtonAlertBoxHandlerStopsSeparater = () => {
    if (destinations.length === 1) {
      deleteTravel();
    } else {
      leftButton();

      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.editTravel.deleteDestSuccess"),
          ariaLabel: t("pages.editTravel.deleteDestSuccess"),
          trigger: getUIID(),
        };
      });

      setAlertBoxHiddenStopsSep(true);
    }
  };

  const onAddTravelHandler = (value) => {
    setChanged(true);
    let newarr = [...tempData.TravelStops];
    newarr.push(value);

    setDestinations(() => destinationsOverlapsing(newarr, true));

    setTempData((curr) => {
      return { ...curr, TravelStops: newarr };
    });
  };

  ///

  const optional = {
    FirstLastName: false,
    PhoneNumber: false,
    Email: false,
  };

  const setOptionalPhoneToTrue = (phone, email) => {
    if (email) {
      return true;
    }
    return isPhoneValidation(phone);
  };
  
  const [optionals, setOptionals] = useState(optional);
  useEffect(() => {
    setOptionals((curr) => {
      return {
        ...curr,
        FirstLastName: !!contactPersonInfoState.FirstLastName,
        PhoneNumber: setOptionalPhoneToTrue(contactPersonInfoState.PhoneNumber, contactPersonInfoState.Email),
        Email: !!contactPersonInfoState.Email,
      };
    });
  }, [contactPersonInfoState]);

  const isValidPhone = () => {
    return contactPersonInfoState.PhoneNumber ? isPhoneValidation(contactPersonInfoState.PhoneNumber, true) : true;
  };

  const isValidEmail = () => {
    return contactPersonInfoState.Email ? isEmailValidation(contactPersonInfoState.Email) : true;
  };

  const isValidContactPerson__to_use_in_isValid = () => {
    return (
      (hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
        contactPersonInfoState.Email.length === 0) &&
      isValidFirstAndLastName(contactPersonInfoState.FirstLastName) &&
      (isValidPhone() || contactPersonInfoState.PhoneNumber.length === 0) &&
      (isValidEmail() || contactPersonInfoState.Email.length === 0) &&
      isValidContactPerson(optionals)
    );
  };
  //

  const isValid = () => {
    let validTGM = true;
    if (typeof tempData === "object" && "TravelGroupMember" in tempData && tempData.TravelGroupMember.length > 0) {
      tempData.TravelGroupMember.forEach((tgm) => {
        if (!travelGroupMemberValidation({ ...tgm, FirstLastName: tgm.Name })) validTGM = false;
      });
    }

    const validDate1 = IsDateBiggerThanTodayValidation(userInfoState.BirthDate);
    const validDate2 = !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate);
    const validDate3 = isDateValidation(userInfoState.BirthDate);

    const validDate = validDate3 && validDate2 && validDate1;

    return (
      validTGM &&
      validDate &&
      isValidContactPerson__to_use_in_isValid() &&
      (hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
        contactPersonInfoState.Email.length === 0) &&
      hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) &&
      isValidContactPerson(optionals) &&
      hasMinLettersValidation(userInfoState.FirstName) &&
      hasMinLettersValidation(userInfoState.LastName) &&
      isPassnumberValidation(userInfoState.PassNumber) &&
      isPersonalNumberValidation(userInfoState.IdNumber) &&
      isEmailValidation(userInfoState.Email) &&
      isPhoneValidation(userInfoState.PhoneNumber) &&
      isValidFirstAndLastName(contactPersonInfoState.FirstLastName) &&
      (isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
        contactPersonInfoState.PhoneNumber.length === 0) &&
      (isEmailValidation(contactPersonInfoState.Email) || contactPersonInfoState.Email.length === 0)
    );
  };

  function alertMessage(hidden, text) {
    setSuccessBox((curr) => {
      return {
        ...curr,
        hidden,
        text: t(text),
        trigger: getUIID(),
        alert: true,
      };
    });
  }

  function destinationsOverlapsing(destinationsArr, show = false) {
    destinationsArr.forEach((element) => (element.overlapsing = false));

    let showAlertMessage = false;

    for (let i = 0; i < destinationsArr.length; i++) {
      for (let j = i + 1; j < destinationsArr.length; j++) {
        let start1 = new Date(destinationsArr[i].StopStart);
        let start2 = new Date(destinationsArr[j].StopStart);
        let end1 = new Date(destinationsArr[i].StopEnd);
        let end2 = new Date(destinationsArr[j].StopEnd);

        if (end1 > start2 && ((start1 > start2 && start1 < end2) || (end1 > start2 && end1 < end2))) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        } else if (end2 > start1 && ((start2 > start1 && start2 < end1) || (end2 > start1 && end2 < end1))) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        } else if (
          start1 < end1 &&
          start2 < end2 &&
          start1.toString() === start2.toString() &&
          end1.toString() === end2.toString()
        ) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        }
      }
    }

    if (showAlertMessage && show) {
      alertMessage(false, t("pages.editTravel.alertMessage") + "%alert%");
    }

    return destinationsArr;
  }

  if (
    loading ||
    !tempData ||
    (deleteTravelSubmitting && deleteTravelLoading) ||
    (updateProfileLoading && submttingUpdateProfile) ||
    (updateTravelLoading && submittingUpdateTravel)
  ) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={onSubmitHandler} className="specialAlertContainer">
        <div className="edit-page__title-image mobile-hidden">
          <img alt="" src={travelImage} />
        </div>

        <div className="edit-travel-container registration-middle-container">
          <MetaDecorator title={getTravelStopNamesFromName()} />

          <AlertBox
            id="alertBox"
            level="attention"
            hidden={alertBoxHidden}
            onClickRightButton={onClickLeftButtonAlertBoxHandler}
            onClickLeftButton={onClickAlertBoxRightButtonAlertBoxHandler}
            title="pages.editTravel.deleteTravelAlertTitle"
            message="pages.editTravel.deleteTravelAlertMessage"
            rightButtonText="pageTitle.eraseTrip"
          />

          <AlertBox
            id="alertBox2"
            level="attention"
            hidden={alertBoxHiddenStopsSep}
            onClickRightButton={onClickLeftButtonAlertBoxHandlerStopsSeparater}
            onClickLeftButton={() => setAlertBoxHiddenStopsSep(true)}
            title="pages.editTravel.deleteDestAlertTitle"
            message="pages.editTravel.deleteDestAlertMessage"
            rightButtonText="pages.editTravel.deleteDestAlertBtn"
          />

          <AlertBox
            id="alertBox3"
            level="attention"
            hidden={relativeAlertBox.hidden}
            onClickRightButton={() => {
              setSuccessBox((curr) => {
                return {
                  ...curr,
                  hidden: false,
                  text: t("pages.editTravel.deleteCompanionAlert"),
                  trigger: getUIID(),
                };
              });

              relativeAlertBox.func();

              setRelativeAlertBox((curr) => {
                return { ...curr, hidden: true };
              });
            }}
            onClickLeftButton={() =>
              setRelativeAlertBox((curr) => {
                return { ...curr, hidden: true };
              })
            }
            title="input.mitreisende.deletedPerson"
            message={relativeAlertBox.text}
            rightButtonText="input.mitreisende.deletePerson"
          />

          <AlertBox
            id="alertBox4"
            level="attention"
            hidden={unsavedChangesAlertHidden}
            onClickLeftButton={() => history(ApplicationRoute.TravelDashboardNoPermissions)}
            onClickRightButton={saveChangeHeaderFunc}
            title="text.save_changes_title"
            message=" "
            rightButtonText="text.save_changes"
          />

          <div className="edit-page__title-image desktop-hidden">
            <img alt="" src={travelImage} />
          </div>

          <InfoContainer title={title} date={travelStartStop} onClick={onDeleteTravelHandler} />

          {tempData.TravelStops.map((ts, index) => {
            let separatorCommentText = tempData.TravelStops[index].Comment ? `(${tempData.TravelStops[index].Comment})` : "";

            return (
              <div key={index}>
                <StopsSeparater
                  text={`${t("input.destination.oneText")} ${tempData.TravelStops[index].Name} ${separatorCommentText}`}
                  onClick={() => {
                    setAlertBoxHiddenStopsSep(false);

                    setLeftButton(
                      () => () =>
                        setTempData((curr) => {
                          setChanged(true);
                          let newarr = [...curr.TravelStops];
                          newarr.splice(index, 1);

                          return { ...curr, TravelStops: newarr };
                        })
                    );
                  }}
                />

                <div className="edit-travel-container__input-calendar-container">
                  <InputCalendar
                    labelText="input.travelTime.text"
                    id={index + 1 + "-reisezeitram"}
                    placeholder="input.travelTime.text"
                    startDate={destinations[index].StopStart}
                    endDate={destinations[index].StopEnd}
                    onChange={(startDate, endDate) => receiveDataForDestination(index, startDate, endDate)}
                    cImg="images/common/editpen.png"
                    cClassName="edit-travel-container__calendar-container"
                    cImgClassName="edit-travel-container__custom-img"
                    error={ts.overlapsing}
                  />
                  <InputValidation
                    text="errors.datesAreOverlapsing"
                    isHidden={!ts.overlapsing}
                  />

                  <HorizontalRule />
                  <EditInput
                    id={`Stadt${index}`}
                    placeholder="input.city.text2"
                    value={destinations[index].Comment}
                    labelText="input.city.text2"
                    ariaRequired={true}
                    onChange={(e) => onChangeCommentTravelStops(e, index)}
                  />
                </div>
              </div>
            );
          })}
          {!travelBoxHidden &&
            <AddTravel hidden={travelBoxHidden} setHidden={setTravelBoxHidden} onSubmit={onAddTravelHandler} />
          }

          <HorizontalRule className="edit-travel-spec-hr" />
          <div className="edit-travel-container__add-destination">
            <button
              onClick={() => {
                setTravelBoxHidden(false);
              }}
              type="button"
            >
              + {t("input.destination.anotherAddOne")}
            </button>
          </div>
          <h2 className="raise-data-separater">
            <div className="raise-data-separater__text">{t("pages.editTravel.travelData")}</div>
          </h2>

          <EditInput
            id="isTourGroup"
            placeholder="pages.editTravel.travelOrg"
            value={travelTourInfo.isTourGroup}
            labelText="pages.editTravel.travelOrg"
            ariaRequired={true}
            onChange={(value) =>
              onUserInfoChangeHandler({
                target: {
                  id: "isTourGroup",
                  value: value,
                },
              })
            }
            options={[t("input.jaNein.ja"), t("input.jaNein.nein")]}
          />

          {travelTourInfo.isTourGroup && travelTourInfo.isTourGroup.toString().toLowerCase() === t("input.jaNein.ja").toLowerCase() &&
            <>
              <HorizontalRule />
              <EditInput
                id="tourOperator"
                placeholder="input.travelOrg.text"
                value={travelTourInfo.tourOperator}
                labelText="input.travelOrg.text"
                ariaRequired={false}
                onChange={onTourGroupChangeHandler}
              />
            </>
          }

          <HorizontalRule />
          <EditInput
            id="FirstName"
            placeholder="input.vorname.text"
            value={userInfoState.FirstName}
            labelText="input.vorname.text"
            ariaRequired={true}
            onChange={onUserInfoChangeHandler}
          />
          <InputValidation
            text={
              hasNumber(userInfoState.FirstName)
                ? "errors.nameHasNumber"
                : "errors.firstNameLessCharacters"
            }
            isHidden={hasMinLettersValidation(userInfoState.FirstName)}
          />
          <HorizontalRule />
          <EditInput
            id="LastName"
            placeholder="input.nachname.text"
            value={userInfoState.LastName}
            labelText="input.nachname.text"
            ariaRequired={true}
            onChange={onUserInfoChangeHandler}
          />
          <InputValidation
            text={
              hasNumber(userInfoState.LastName)
                ? "errors.nameHasNumber"
                : "errors.lastNameLessCharacters"
            }
            isHidden={hasMinLettersValidation(userInfoState.LastName)}
          />

          <HorizontalRule />
          <EditInput
            id="Gender"
            placeholder="input.gender.text"
            value={getGenderTextFromEnum(userInfoState.Gender, t)}
            labelText="input.gender.text"
            ariaRequired={true}
            onChange={(value) =>
              onUserInfoChangeHandler({
                target: { id: "Gender", value: getEnumFromGenderText(value, t) },
              })
            }
            options={[t("input.gender.options.male"), t("input.gender.options.female"), t("input.gender.options.other")]}
          />

          <HorizontalRule />
          <EditInput
            id="BirthDate"
            placeholder="input.date.birthdate"
            value={userInfoState.BirthDate}
            labelText="input.date.birthdate"
            ariaRequired={true}
            onChange={onUserInfoChangeHandler}
          />
          <InputValidation
            text="errors.notValidDate"
            isHidden={isDateValidation(userInfoState.BirthDate)}
          />
          <InputValidation
            text="errors.ageLessThan_18"
            isHidden={
              !IsDateBiggerThanTodayValidation(userInfoState.BirthDate) ||
              !isDateValidation(userInfoState.BirthDate) ||
              isOlderOrIsEighteenYearsOlds(userInfoState.BirthDate)
            }
          />
          <InputValidation
            text="errors.dateBiggerThanToday"
            isHidden={
              !isDateValidation(userInfoState.BirthDate) || IsDateBiggerThanTodayValidation(userInfoState.BirthDate)
            }
          />
          <InputValidation
            text="errors.dateOfBirthLessThan_01_01_1900"
            isHidden={
              !isDateValidation(userInfoState.BirthDate) ||
              !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate)
            }
          />
          <HorizontalRule />
          <EditInput
            id="PassNumber"
            placeholder="input.passport.text"
            value={userInfoState.PassNumber}
            labelText="input.passport.text"
            ariaRequired={false}
            onChange={onUserInfoChangeHandler}
          />
          <InputValidation
            text="errors.notValidAustrianPassportOrIdentityCardNumberFormat"
            isHidden={isPassnumberValidation(userInfoState.PassNumber)}
          />
          <HorizontalRule />

          <EditInput
            id="IdNumber"
            value={userInfoState.IdNumber}
            onChange={onUserInfoChangeHandler}
            placeholder="input.personalIdCard.text"
            ariaRequired={false}
            labelText="input.personalIdCard.text"
            showValidationBorder={!isPersonalNumberValidation(userInfoState.IdNumber)}
          />

          <InputValidation
            isHidden={isPersonalNumberValidation(userInfoState.IdNumber)}
            text="errors.notValidPersonalNumber"
          />

          <HorizontalRule />
          <EditInput
            id="Email"
            placeholder="input.email.text"
            value={userInfoState.Email}
            labelText="input.email.text"
            ariaRequired={true}
            onChange={onUserInfoChangeHandler}
          />
          <InputValidation
            isHidden={
              !hasAtSymbolEmailValidation(userInfoState.Email) ||
              !hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) ||
              isEmailValidation(userInfoState.Email)
            }
            text="errors.notValidEmail"
          />
          <InputValidation
            isHidden={hasAtSymbolEmailValidation(userInfoState.Email)}
            text="errors.missingAtSymbolEmail"
          />
          <InputValidation
            isHidden={
              !hasAtSymbolEmailValidation(userInfoState.Email) ||
              hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email)
            }
            text="errors.charactersAfterAtSymbolLessThanTwoEmail"
          />
          <HorizontalRule />
          <EditInput
            id="PhoneNumber"
            placeholder="input.mobilenum.placeholder"
            value={userInfoState.PhoneNumber}
            labelText="input.mobilenum.text"
            ariaRequired={true}
            onChange={onUserInfoChangeHandler}
          />
          <InputValidation
            text="errors.notValidPhoneNumber"
            isHidden={isPhoneValidation(userInfoState.PhoneNumber)}
          />
          <HorizontalRule />

          <EditInput
            id="CountyHomeRegionName"
            placeholder="input.bundesland.text"
            value={userInfoState.CountyHomeRegionName}
            labelText="input.bundesland.text"
            ariaRequired={true}
            onChange={(value) =>
              onUserInfoChangeHandler({
                target: { id: "CountyHomeRegionName", value: value },
              })
            }
            options={countriesSelectOptions}
            optional={true}
          />

          {typeof tempData === "object" && "TravelGroupMember" in tempData &&
            tempData.TravelGroupMember.map((tg, index) => {
              return (
                <div key={index}>
                  <StopsSeparater
                    text={t("input.mitreisende.one") + contactPersonInfo.FirstLastName ? ` (${tg.Name})` : ""}
                    onClick={() => {
                      const func = () =>
                        setTempData((curr) => {
                          let newarr = [...curr.TravelGroupMember];
                          newarr.splice(index, 1);

                          return { ...curr, TravelGroupMember: newarr };
                        });

                      setRelativeAlertBox((curr) => {
                        return {
                          ...curr,
                          hidden: false,
                          func: func,
                          text: `${tg.Name} ${t("input.mitreisende.deletedAsPerson")}`,
                        };
                      });
                    }}
                  />

                  <Relative
                    onChange={(e) => {
                      setTempData((curr) => {
                        let newarr = [...curr.TravelGroupMember];
                        let i = parseInt(e.target.id.substring(2));
                        let prop = e.target.id.substring(e.target.id.indexOf("-") + 1);

                        if (prop === "Birthdate") {
                          newarr[i][prop] = dateFormatter(newarr[i][prop], e.target.value);

                          return { ...curr, TravelGroupMember: newarr };
                        }

                        if (prop === "MobilePhone") {
                          newarr[i][prop] = phoneFormatter(newarr[i][prop], e.target.value);

                          return { ...curr, TravelGroupMember: newarr };
                        }

                        newarr[i][prop] = e.target.value;

                        return { ...curr, TravelGroupMember: newarr };
                      });
                    }}
                    index={index}
                    FirstLastName={tg.Name ?? ""}
                    Email={tg.Email ?? ""}
                    PhoneNumber={tg.MobilePhone ?? ""}
                    BirthDate={tg.Birthdate ?? ""}
                    Nationality={tg.Nationality ?? ""}
                    DegreeOfRelationship={tg.DegreeOfRelationship ?? ""}
                    Gender={tg.Gender ?? ""}
                    countries={countriesLS}
                  />
                </div>
              );
            })}

          <HorizontalRule className="edit-travel-spec-hr" />
          <div className="edit-travel-container__add-destination">
            <button
              type="button"
              aria-label={t("input.mitreisende.addTitle")}
              onClick={() => {
                localStorage.setItem("addedTGM", "added");
                localStorage.setItem("tempData", JSON.stringify(tempData));
                localStorage.setItem("travelTourInfo", JSON.stringify(travelTourInfo));
                localStorage.setItem("userInfoState", JSON.stringify(userInfoState));
                localStorage.setItem("addTGMURL", location.pathname);

                localStorage.setItem("addTGMTitle", getTravelNameWithCut());

                history(ApplicationRoute.addRelative1);
              }}
            >
              + {t("input.mitreisende.addTitle")}
            </button>
          </div>

          <>
            <ClearContanctDiv
              text={
                contactPersonInfo.FirstLastName
                  ? `${t("terms.crisisContact")} (${contactPersonInfo.FirstLastName})`
                  : "pages.editProfile.clearContactText"
              }
            />

            <HorizontalRule />
            <EditInput
              id="NotfalkontaktFirstLastName"
              placeholder="input.vorNachname.text"
              value={contactPersonInfoState.FirstLastName}
              labelText="input.vorNachname.text"
              ariaRequired={true}
              onChange={onContactPersonInfoChangeHandler}
            />
            <InputValidation
              text={
                hasNumber(contactPersonInfoState.FirstLastName)
                  ? "errors.nameHasNumber"
                  : "errors.notValidContactPersonFirstAndLastName"
              }
              isHidden={
                isValidFirstAndLastName(contactPersonInfoState.FirstLastName) ||
                hasMiddleName(contactPersonInfoState.FirstLastName)
              }
            />
            <InputValidation
              isHidden={!hasMiddleName(contactPersonInfoState.FirstLastName)}
              text="errors.middleName"
              ariaLabel="errors.middleName"
            />
            <HorizontalRule />
            <EditInput
              id="NotfalkontaktPhoneNumber"
              placeholder="input.contact.placeholder"
              value={contactPersonInfoState.PhoneNumber}
              labelText="input.mobilenum.text"
              ariaRequired={true}
              onChange={onContactPersonInfoChangeHandler}
            />
            <InputValidation
              text="errors.notValidPhoneNumber"
              isHidden={
                isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
                contactPersonInfoState.PhoneNumber.length === 0
              }
            />
            <HorizontalRule />
            <EditInput
              id="NotfalkontaktEmail"
              placeholder="input.email.text"
              value={contactPersonInfoState.Email}
              labelText="input.email.text"
              ariaRequired={false}
              onChange={onContactPersonInfoChangeHandler}
            />
            <InputValidation
              isHidden={
                contactPersonInfoState.Email.length === 0 ||
                !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
                !hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
                isEmailValidation(contactPersonInfoState.Email)
              }
              text="errors.notValidEmail"
            />
            <InputValidation
              isHidden={
                contactPersonInfoState.Email.length === 0 || hasAtSymbolEmailValidation(contactPersonInfoState.Email)
              }
              text="errors.missingAtSymbolEmail"
            />
            <InputValidation
              isHidden={
                contactPersonInfoState.Email.length === 0 ||
                !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
                hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email)
              }
              text="errors.charactersAfterAtSymbolLessThanTwoEmail"
            />
          </>

          {/* <p style={{ marginTop: "2.4rem" }}>
            {t("pages.editTravel.saveFuture")}
          </p>
          <ControledRadioInput
            role="radio"
            id="gdprdataretention_yes"
            value="input.jaNein.ja"
            name="gdprdataretention"
            textInfo="input.jaNein.ja"
            ariaLabel="input.jaNein.gdprLabel"
            checked={GDPRDataRetention}
            onChange={onGDPRDataRetentionChange}
            ariaChecked={GDPRDataRetention}
          />
          <ControledRadioInput
            role="radio"
            id="gdprdataretention_no"
            value="input.jaNein.nein"
            name="gdprdataretention"
            textInfo="input.jaNein.nein"
            ariaLabel="input.jaNein.gdprLabel"
            checked={!GDPRDataRetention}
            onChange={onGDPRDataRetentionChange}
            ariaChecked={!GDPRDataRetention}
          /> */}
          <p style={{ marginTop: "1.2rem" }}>
            {t("pages.dsgvo.infoText")}{" "}
            <a
              href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
              target="_blank"
              style={{ color: "black" }}
              aria-label={t("pages.dsgvo.title2")}
              rel="noopener noreferrer"
            >
              {t("pages.dsgvo.title")}
            </a>
            .
          </p>

          {changed && <div style={{ paddingBottom: "1.6rem" }} />}
          <div className={changed ? "fixed" : ""}>
            <MainButton
              buttonId="submit"
              text="text.save_changes"
              disabled={
                (updateProfileLoading && submttingUpdateProfile) ||
                (submittingUpdateTravel && updateTravelLoading) ||
                !isValid()
              }
              positioned="static"
              onClick={onSubmitHandler}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default EditTravel;