import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { GetBundeslandIdFromName } from "../../../../utils/helpers";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Form from "../../../registration/utils/Form";
import WeiterButton from "../../../registration/utils/WeiterButton";
import CustomSelectList from "../../utils/CustomSelectList";

const countriesSelectOptions = [
  "input.bundesland.options.burgenland",
  "input.bundesland.options.kaernten",
  "input.bundesland.options.niederoesterreich",
  "input.bundesland.options.oberoesterreich",
  "input.bundesland.options.salzburg",
  "input.bundesland.options.steiermark",
  "input.bundesland.options.tirol",
  "input.bundesland.options.vorarlberg",
  "input.bundesland.options.wien",
];

//HINT* NOT REGISTRATION
export function ForeignCounty() {
  const history = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();

  const [livingIn, setLivingIn] = useState(user.CountyHomeRegionName);

  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((4 / 19) * 100),
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
      };
    });
  }, []);

  const isValid = () => {
    //originalLogik --> return !!livingIn;
    return true;
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        CountyHomeRegionName: livingIn,
        CountyHomeRegionId: GetBundeslandIdFromName(livingIn, t),
      };
    });
  };

  const onChangeHandler = (value) => {
    setLivingIn(value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history(ApplicationRoute.foreignRegistrationRegistrationGender);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <ContentTitle
        id="contentTitle"
        text="pages.county.wohnsitz"
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="foreign-registration-form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="contentTitle"
        className="max-width-380"
      >
        <CustomSelectList
          id="county"
          label="input.bundesland.placeholder"
          state={livingIn}
          onChangeHandler={onChangeHandler}
          options={countriesSelectOptions}
          ariaLabelledBy="contentTitle"
          optional={true}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationGender}
        onClick={onClickHandler}
        className=" floating-weiter-buton"
      />
    </section>
  );
}