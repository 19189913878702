import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import defaultUserValues from "../config/defaultUserValues.js";
import ApplicationRoute from "../config/routes.js";
import LoggedContext from "../context/LoggedContext.js";
import UserContext from "../context/UserContext.js";
import { removeIsLoggedStorage } from "../utils/helpers.js";

const useFetch = (url, method, body, redirect = true, zeroRedirection = false) => {
  const isMounted = useRef(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const { setUser } = useContext(UserContext);
  const { setIsLogged } = useContext(LoggedContext);
  const history = useNavigate();

  useEffect(() => {
    isMounted.current = true;
    let response;
    const init = async () => {
      try {
        response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body,
        });

        if (response?.ok) {
          const json = await response.json();
          if (isMounted.current) setData(json);
        } else {
          throw response;
        }
      } catch (e) {
        if (isMounted.current) setError(e);
      } finally {
        if (isMounted.current) setLoading(false);

        if (!zeroRedirection) {
          if (redirect && (response?.status === 401 || response?.status === 403)) {
            setUser(() => defaultUserValues);
            setIsLogged(() => false);
            removeIsLoggedStorage();
            history(ApplicationRoute.home);
          } else if (!response?.ok && redirect) {
            history(ApplicationRoute.errorPage);
          }
        }
      }
    };
    init();

    return () => {
      isMounted.current = false;
    };
  }, [url]);

  return { data, error, loading };
};

export default useFetch;