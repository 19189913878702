import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { getTravelGroupMemberLastIndex_Array } from "../../../utils/helpers";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
} from "../../../utils/validation";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageDescription from "../utils/Description";
import Form from "../utils/Form";
import PageTitle from "../utils/PageTitle";

const AddTravelEmail = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  const { travel, setTravel } = useContext(TravelContext);
  const { setHeader } = useContext(HeaderContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initEmail = travel?.TravelGroupMember[groupMemberIndex]?.Email;

  const [email, setEmail] = useState(initEmail || "");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((16 / 19) * 100),
      };
    });
  }, []);

  //
  //
  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.documentElement.addEventListener("click", activate);

    return () => document.documentElement.removeEventListener("click", activate);
  }, []);
  //
  //

  const isValid = () => {
    return (isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0;
  };

  const updateTravel = () => {
    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Email: email || null,
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const onClickHandler = () => {
    updateTravel();

    history(ApplicationRoute.addTravelNationality);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history(ApplicationRoute.addTravelNationality);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <PageTitle id="contactperson-email" text="pages.addRelative5.title" />

      <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p>

      <PageDescription text="input.mitreisende.extraDataPerson" />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onsubmitHandler} ariaLabelledBy="contactperson-email">
        <Input
          id="email"
          name="email"
          type="text"
          htmlFor="email"
          value={email}
          onChange={onChangeHandler}
          placeholder="input.email.text"
          labelText="input.email.text"
          showValidationBorder={
            email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isValid()
          }
          text="errors.notValidEmail"
        />

        <InputValidation
          isHidden={!active || !email.length > 0 || hasAtSymbolEmailValidation(email)}
          text="errors.missingAtSymbolEmail"
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelNationality}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default AddTravelEmail;