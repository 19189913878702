import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import apipath from "../apipath";
import HorizontalRule from "../common/HorizontalRule";
import InputValidation from "../common/InputValidaiton";
import MainButton from "../common/MainButton";
import ApplicationRoute from "../config/routes";
import HeaderContext from "../context/HeaderContext";
import fetcher from "../services/fetcher";
import MetaDecorator from "../utils/MetaDecorator";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
} from "../utils/validation";
import Input from "./registration/utils/Input";
import Loader from "./travel-registration/utils/Loader";

const ForgottenPasword = () => {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const { setHeader } = useContext(HeaderContext);

  const history = useNavigate();

  const [, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const submittingNow = () => {
    return submitting && loading;
  };

  const resetPassword = () => {
    fetcher(
      `${apipath.path}users/passwordreset`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify({ Email: email })
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: "no",
        title: t("pages.passwortVergessen.title"),
      };
    });
  }, []);

  const onChange = (e) => {
    setEmail(() => e.target.value);
  };

  const onClickHandler = () => {
    if (isValid()) {
      resetPassword();
    }
  };

  const isValid = () => {
    return isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email);
  };

  useEffect(() => {
    if (!loading && !error) {
      localStorage.setItem("restoreEmail", email);

      history(ApplicationRoute.forgottenPasswordSecondPage);
    }
  }, [loading, error]);

  if (submittingNow()) return <Loader />;

  return (
    <div className="forgotten-password-container registration-middle-container">
      <MetaDecorator title="pageTitle.forgottenPassword" />
      <h1 className="forgotten-password-container__heading">{t("pages.passwortVergessen.title")}</h1>

      <p className="forgotten-password-container__info">{t("pages.passwortVergessen.info")}</p>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="max-width-380">
        <Input
          id="email"
          value={email}
          onChange={onChange}
          placeholder="input.email.text"
          type="email"
          labelText="input.email.text"
          showValidationBorder={
            email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
          labelStyle={{ backgroundColor: "#f0f4f5" }}
        />

        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isValid()
          }
          text="errors.notValidEmail"
        />

        <InputValidation
          isHidden={!email.length > 0 || hasAtSymbolEmailValidation(email)}
          text="errors.missingAtSymbolEmail"
        />

        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />
      </div>
      <HorizontalRule className="horizontal-rule-desktop" />

      <MainButton
        path={ApplicationRoute.renewPassword}
        text="pages.passwortVergessen.senden"
        ariaLabel="pages.passwortVergessen.senden"
        positioned="static"
        className="forgotten-password-container__weiter-button"
        onClick={onClickHandler}
        disabled={submittingNow() || !isValid()}
      />
    </div>
  );
};

export default ForgottenPasword;