import axios from "axios"
import { asyncResponse, httpDemoResponses } from "../components/DotWebsite/HttpServices/Helpers/HttpDemoResponses"
import { isDemo } from "../components/DotWebsite/HttpServices/HttpClient"
import i18n, { defaultLanguage } from "./config"

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  headers: {
    "Content-type": "application/json",
    "Cache-Control": "no-cache, no-store",
    "Expires": "0",
    "Pragma": "no-cache"
  }
})
  
const fetchLanguages = async () => {
  if (isDemo) {
    return httpDemoResponses.langauges
  }

  const localLanguages = {
    data: [
        {
            language: "de",
        }
    ]
  }

  if(process.env?.REACT_APP_USE_DB_TRANSLATIONS === 'false') {
    return localLanguages
  }

  return httpClient.get<Array<LanguageResponse>>('/languages').catch(() => httpDemoResponses.langauges)
}

export const getLanguages = async () => {
  const response = await fetchLanguages()

  const languages = response.data.map((resp) => resp.language)
  i18n.languages = languages

  const languageOptions = languages.map(language => {
    return {
      value: language.toLowerCase(),
      label: language.toUpperCase()
    }
  })

  await getTranslations(defaultLanguage.get())

  return languageOptions
}

function getLocalTranslationFile(language: string) {
  return asyncResponse<Record<string, any>>({
    data: require('./' + language.toLowerCase() + '.json')
  })
}

const fetchTranslations = async (language: string) => {
  if (isDemo) {
    return getLocalTranslationFile(language)
  }

  if(process.env?.REACT_APP_USE_DB_TRANSLATIONS === 'false') {
    return getLocalTranslationFile(language)
  }

  return httpClient.get('/translations/' + language).catch(() => getLocalTranslationFile(language))
}

export const getTranslations = async (language: string) => {
  const response = await fetchTranslations(language)
  if (!response) {
    return
  }

  i18n.addResourceBundle(defaultLanguage.get(), 'translation', response.data, true, true)
}
