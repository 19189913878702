import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import { goToNext } from "../../../utils/helpers";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
} from "../../../utils/validation";
import Form from "../../registration/utils/Form";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageDescription from "../../travel-registration/utils/Description";
import PageTitle from "../../travel-registration/utils/PageTitle";

const AddRelative5 = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  const { setHeader } = useContext(HeaderContext);

  //
  //
  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.documentElement.addEventListener("click", activate);

    return () => document.documentElement.removeEventListener("click", activate);
  }, []);
  //
  //

  let initemail = "";
  try {
    initemail = JSON.parse(localStorage.getItem("newTGM")).Email || "";
  } catch (error) { }

  const [email, setEmail] = useState(initemail);

  useEffect(() => {
    let title = "input.mitreisende.addTitle";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) { }

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t(title),
        progress: "no",
      };
    });
  }, []);

  const isValid = () => {
    return (isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0;
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        ...tgm,
        Email: email || null,
      })
    );
  };

  const onClickHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative6, history);
  };

  const onsubmitHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative6, history);
  };
  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title="pageTitle.editTrip" />
      <PageTitle id="add-relatives-email" text="pages.addRelative5.title" />

      <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p>

      <PageDescription text="input.mitreisende.extraDataPerson" />

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onsubmitHandler} ariaLabelledBy="add-relatives-email" className="max-width-380">
        <Input
          id="email"
          value={email}
          onChange={onChangeHandler}
          placeholder="input.email.text"
          type="email"
          labelText="input.email.text"
          showValidationBorder={
            email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isValid()
          }
          text="errors.notValidEmail"
        />

        <InputValidation
          isHidden={!active || !email.length > 0 || hasAtSymbolEmailValidation(email)}
          text="errors.missingAtSymbolEmail"
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative6}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default AddRelative5;