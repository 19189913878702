import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  getEnumFromGender,
  getGenderFromText,
  getGenderTextFromEnum,
  getKeyByValue,
  prefilledUserData
} from "../../../utils/helpers";
import ContentTitle from "../utils/ContentTitle";
import ControledRadioInput from "../utils/ControledRadioInput";
import Form from "../utils/Form";
import WeiterButton from "../utils/WeiterButton";

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

//HINT* NOT REGISTRATION
const GenderPage = () => {
  const history = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((5 / 19) * 100),
        title: t("pageTitle.createTripPages"),
      };
    });
  }, []);

  const [selectedValue, setSelectedValue] = useState(initSelectValue);

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        Gender: getEnumFromGender(getKeyByValue(selectedValue, true), t),
      };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history(ApplicationRoute.registerMobilePhone);
    }
  };

  useEffect(() => {
    if (user && user.Gender) {
      setSelectedValue(() => {
        return { ...initSelectValue, [getGenderFromText(getGenderTextFromEnum(user.Gender, t), t)]: true };
      });
    }
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <Form
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy="register-gender"
      >
        <ContentTitle
          id="register-gender"
          text={prefilledUserData("prefilledUserData.geschlecht")}
        />
        <HorizontalRule className="horizontal-rule-desktop" />
        <ControledRadioInput
          id="male"
          name="gender"
          htmlFor="male"
          value="100000000"
          textInfo="input.gender.options.male"
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          name="gender"
          htmlFor="female"
          value="100000001"
          textInfo="input.gender.options.female"
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          name="gender"
          htmlFor="other"
          value="100000002"
          textInfo="input.gender.options.other"
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerMobilePhone}
          onClick={onClickHandler}
          className="floating-weiter-buton"
        />
      </Form>
    </div>
  );
};

export default GenderPage;