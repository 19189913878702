const ApplicationRouteOrig = {
  home: "/",
  register: "/registrierung",
  registerName: "/registrierung/name",
  registerGender: "/registrierung/geschlecht",
  registerForgottenPassword: "/forgottenPassword",
  forgottenPasswordSecondPage: "/forgottenPasswordinfo",
  registerMobilePhone: "/registrierung/mobiltelefon",
  registerBirthday: "/registrierung/geburtstag",
  registerEmail: "/registrierung/emailadresse",
  registerSentEmail: "/registrierung/sentemail",
  registerVerifiedEmail: "/registrierung/verifiedemail/:token",
  registerPassword: "/registrierung/passwort",
  registerNationality: "/registrierung/staatsangehoerigkeit",
  registerForeignCountry: "/registrierung/ausland",
  registerLivingIn: "/registrierung/wohnhaft",
  registerPassNumber: "/registrierung/passnummer",
  registerContactPerson: "/registrierung/kontaktperson",
  registerUserInfo: "/registrierung/userinfo",
  registerConfirmationPage: "/registrierung/einwilligung",
  registerCitizenship: "/registrierung/staatsbürgerschaft",
  activatedAccount: "/registrierung/activated",
  loginPage: "/login",
  dseConfirmationPage: "/dse/confirmation",
  infoMailPage: "/info/confirmation",
  idAustria: "/id-austria",
  linkAccountExisting: "/id-austria/link/existing",
  linkAccountManual: "/id-austria/link/manual",
  linkAccountLogin: "/id-austria/link/login",
  idaEmailPage: "/id-austria/email",
  idaWelcomePage: "/id-austria/welcome",
  travelDashboard: "/travel-dashboard/:hasPermissions?",
  TravelDashboardNoPermissions: "/travel-dashboard",
  addTravel: "/add-travel",
  addTravelTourOperator: "/add-travel-tour-operator",
  addTravelCompanion: "/add-travel-step-1",
  addTravelCompanionName: "/add-travel-step-2",
  addTravelCompanionDateOfBirth: "/add-travel-step-3",
  addTravelGender: "/add-travel-step-4",
  addTravelMobilePhone: "/add-travel-step-5",
  addTravelEmail: "/add-travel-step-6",
  addTravelNationality: "/add-travel-step-7",
  addTravelCompanionAddedSuccess: "/add-travel-step-8",
  addTravelEdit: "/add-travel-step-9",
  editProfile: "/edit-profile",
  countryList: "/country-overview",
  countryDetailedInfo: "/country/:countryUid",
  countryInformation: "/countryInfo/:countryUid/:title",
  botschaftenKonsulate: "/country/:countryUid/botschaften-und-konsulate",
  botschaftenKonsulateContacts: "/country-city/:countryUid/:orgaUnitUid",
  notfalltipps: "/notfalltipps",
  notfalltippsNested: "/notfalltipps/*",

  vertretungen: "/vertretungen",
  dashboardInfos: "/dashboard-infos",
  dashboardInfosBodytext: "/dashboard-infos/:text",
  bildquellen: "/bildquellen",
  impresumInfos: "/impressum",

  erreichbarkeitInNotfallen: "erreichbarkeit-in-Notfallen",
  verloreneFahrzeugpapiereFuhrerschein: "verlorene-fahrzeugpapiere-fuhrerschein",
  unfallErkrankungUndSterbefalle: "unfall-Erkrankung-und-Sterbefalle",
  passverlust: "passverlust",
  finanzielleNotlage: "finanzielle-notlage",
  vermissteAngehorige: "vermisste-angehorige",
  katastrophenPolitischeUnruhen: "katastrophen-politische-unruhen",
  regressforderungenSchutzmassnahmen: "regressforderungenSchutzmaßnahmen",
  ministeriumAuswartige: "ministerium-auswartige",
  reisewarnungen: "reisewarnungen",

  allgemeineInformationen: "/allgemeineInformationen",
  infoReiseregistrierung: "/infoAusland",
  infoBarrierefreiheit: "/barrierefreiheit",
  infoDatenschutz: "/datenschutz",
  infoPass: "/gruenerPass",
  foreignRegistrationRegionSelection: "/region-selection",
  foreignRegistrationRegistrationName: "/auslandsregistrierung/name",
  foreignRegistrationRegistrationBirthdate: "/auslandsregistrierung/birthdate",
  foreignRegistrationRegistrationCounty: "/auslandsregistrierung/county",
  foreignRegistrationRegistrationGender: "/auslandsregistrierung/gender",
  foreignRegistrationRegistrationNumber: "/auslandsregistrierung/number",
  foreignRegistrationRegistrationEmail: "/auslandsregistrierung/email",
  foreignRegistrationRegistrationPassport: "/auslandsregistrierung/passport",
  foreignRegistrationRegistrationEmergencyContact: "/auslandsregistrierung/emergency-contact",
  foreignRegistrationAddRelatives: "/add-relatives",
  foreignRegistrationAddRelativesName: "/add-relatives/name",
  foreignRegistrationAddRelativesBirthdate: "/add-relatives/birthdate",
  foreignRegistrationAddRelativesGender: "/add-relatives/gender",
  foreignRegistrationAddRelativesEmail: "/add-relatives/email",
  foreignRegistrationAddRelativesNumber: "/add-relatives/number",
  foreignRegistrationAddRelativesNationality: "/add-relatives/nationality",
  foreignRegistrationAddRelativesSuccess: "/add-relatives/success",
  foreignRegistrationEditPage: "/auslandsregistrierung/edit",
  dashboardForeignRegistrationPage: "/foreign-edit-page",
  renewPassword: "/newpassword/:token",
  firstPushStep: "/pushnotification/:id/:longitude/:latitude",
  secondPushStep: "/pushnotification-conf",
  pushEditFromDashboard: "/pushnotification-edit",
  pushLogin: "/pushlogin-cred",
  loginBeforePushNotf: "/pushlogin-bf",
  editProfileSecond: "/editprofile",
  changePassword: "/changepassword",
  editTravel: "/edit-travel/:id",
  addRelative1: "/edittravel/addRelative1",
  addRelative2: "/edittravel/addRelative2",
  addRelative3: "/edittravel/addRelative3",
  addRelative4: "/edittravel/addRelative4",
  addRelative5: "/edittravel/addRelative5",
  addRelative6: "/edittravel/addRelative6",
  errorPage: "/error",
  errorPageVerified: "/registrierung/error",
  errorPageResetToken: "/newpassword-error",
  dataProtection: "/dataProtection",
  dataProtectionBeforeLogin: "/dateProtectionBFLG",
  googleMapURL: "https://www.google.com/maps?q=%lat%,%long%",
  registrationEmailAdress: "/registrierung/registerEmail",
  redirectPage: "/rp",
  dot: "/dot",
  faqs: "/faqs"
};

const ApplicationRoute = { ...ApplicationRouteOrig };

export default ApplicationRoute;