import dateAndTime from "date-and-time";
import { hasMiddleName } from "./helpers.js";

export const hasOnlyLettersValidation = (text) => {
  const letters = /^[a-z]*$/i;
  return text.match(letters);
};

//chang added to have also number validation - qf
export const hasMinLettersValidation = (text, minLength = 2) => {
  if (!text || text.length === 0) return false;

  if (hasNumber(text)) return false;

  return text.length >= minLength;
};

export const hasMinLettersValidation__normal = (text, minLength = 2) => {
  return text.length >= minLength;
};

export const hasMaxLettersValidation = (text, maxLength) => {
  return text.length <= maxLength;
};

export const isInLengthRangeValidation = (text, min, max) => {
  return hasMinLettersValidation__normal(text, min) && hasMaxLettersValidation(text, max);
};

export const isDateValidation = (date, dateFormat = "DD.MM.YYYY") => {
  return dateAndTime.isValid(date, dateFormat) && hasMinLettersValidation__normal(date, dateFormat.length);
};

//IS NOT DATE BIGGER THAN TODAY!
export const IsDateBiggerThanTodayValidation = (inputDate) => {
  if (inputDate.length !== "DD.MM.YYYY".length) {
    return false;
  }

  let day = parseInt(inputDate.substring(0, 2));
  let month = parseInt(inputDate.substring(3, 5)) - 1;
  let year = parseInt(inputDate.substring(6));

  let userDate = new Date(year, month, day);

  let now = new Date();

  now.setHours(0, 0, 0, 0);

  return now >= userDate;
};

export const IsDateSmallerThan_1900_1_1_Validation = (inputDate) => {
  if (inputDate.length !== "DD.MM.YYYY".length) {
    return false;
  }

  let day = parseInt(inputDate.substring(0, 2));
  let month = parseInt(inputDate.substring(3, 5)) - 1;
  let year = parseInt(inputDate.substring(6));

  let userDate = new Date(year, month, day);
  let checkDate = new Date(1899, 11, 31);

  return userDate < checkDate;
};

export const isOlderOrIsEighteenYearsOlds = (inputDate) => {
  return true;
};

export const isPhoneValidation = (text, isOptional) => {
  if (isOptional) {
    if (text.trim().replace(/\s/g, "").replace("+", "").length >= 7 || text.trim() === "") {
      return true;
    }
  }

  text = text.replace(/\s/g, "");
  text = text.replace("+", "");

  return hasOnlyDigitsValidation(text) &&
    isInLengthRangeValidation(text, 7, 18);
};

export const isEmailValidation = (text) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(text.toLowerCase());
};

export const hasCapitalLetterValidation = (text) => {
  const regex = /(?=.*[A-Z]).+/;
  return regex.test(text);
};

export const hasSmallLetterValidation = (text) => {
  const regex = /(?=.*[a-z]).+/;
  return regex.test(text);
};

export const hasDigitValidation = (text) => {
  return /\d/.test(text);
};

export const hasSpecialSymbolValidation = (text) => {
  const regex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~§]/;
  return regex.test(text);
};

export const isPassnumberValidation = (text) => {
  return !text || text.length === 0 || /^[a-zA-Z]{1,2}(\d{7})$/.test(text);
};

export const isPersonalNumberValidation = (text) => {
  return text.length === 0 || /^(\d{8})$/.test(text) || /^([a-zA-Z]{2}\d{7})$/.test(text);
};

export const hasOnlyDigitsValidation = (text) => {
  return /^\d+$/.test(text);
};

export const hasAtSymbolEmailValidation = (text) => {
  if (!text) {
    return false;
  }
  return text.includes("@");
};

export const hasTwoCharactersAfterDotEmailValidation = (text) => {
  return true;
};

export const hasTwoCharactersAfterAtSymbolEmailValidation = (text) => {
  const atPosition = text.lastIndexOf("@");
  let subString = text.substring(atPosition + 1);
  const firstDotPosition = subString.indexOf(".");

  let final = subString.substring(0, firstDotPosition);

  return hasMinLettersValidation__normal(final, 1);
};

export const isValidFirstAndLastName = (FirstLastName) => {
  if (!FirstLastName || FirstLastName.length === 0) return true;

  if (hasNumber(FirstLastName)) return false;

  const names = FirstLastName.trim().split(" ");

  if (names.length < 2) return false;

  let patt =
    /^(\w|\.|-|\s|A|Á|á|Ă|ă|Ä|ä|Ǟ|ǟ|À|à|Æ|æ|Ā|ā|Ą|ą|Å|å|Ã|ã|Â|â|C|Ć|ć|Č|č|Ç|ç|Ċ|ċ|Ĉ|ĉ|D|Ď|ď|Ḑ|ḑ|Đ|đ|E|É|é|Ě|ě|Ë|ë|È|è|Ē|ē|Ę|ę|Ė|ė|Ê|ê|G|Ğ|ğ|Ģ|ģ|Ġ|ġ|Ĝ|ĝ|H|Ħ|ħ|Ĥ|ĥ|I|Í|í|Ï|ï|Ì|ì|Ĳ|ĳ|Ī|ī|Į|į|İ|ı|Î|î|J|Ĵ|ĵ|K|Ķ|ķ|L|Ĺ|ĺ|Ľ|ľ|Ļ|ļ|Ŀ|ŀ|Ł|ł|N|Ń|ń|Ň|ň|Ņ|ņ|Ñ|ñ|O|Ó|ó|Ő|ő|Ö|ö|Ȫ|ȫ|Ò|ò|Œ|œ|Ō|ō|Ọ|ọ|Ȯ|ȯ|Ø|ø|Õ|õ|Ȭ|ȭ|Ǫ|ǫ|Ô|ô|R|Ŕ|ŕ|Ř|ř|Ŗ|ŗ|S|Ś|ś|Š|š|Ş|ş|Ș|ș|ß|Ŝ|ŝ|T|Ť|ť|Ţ|ţ|Ț|ț|Ŧ|ŧ|U|Ú|ú|Ű|ű|Ŭ|ŭ|Ü|ü|Ù|ù|Ū|ū|Ų|ų|Ů|ů|Û|û|Y|Ý|ý|Ÿ|ÿ|Ȳ|ȳ|Z|Ź|ź|Ž|ž|Ż|ż)+$/;
  if (!patt.test(FirstLastName.replace(" ", "")) || FirstLastName.includes("_")) return false;

  const validFirstName = hasMinLettersValidation__normal(names[0], 2);

  const validSecondName = hasMinLettersValidation__normal(names[1], 2);

  return validFirstName && validSecondName;
};

let x = {
  Name: "lyubomri afsa",
  FirstName: "lyubomri",
  LastName: "afsa",
  Birthdate: "1999-02-21T23:59:59",
  MobilePhone: "+4333333333333",
  Gender: 100000000,
  Email: "af1766@abv.bg",
  Nationality: "9ae6e990-e35f-eb11-a825-000d3a46d73b",
  DegreeOfRelationship: 100000000,
};

export const travelGroupMemberValidation = (tgm) => {
  if (!tgm) {
    return false;
  }

  let firstName = tgm.FirstLastName ?? "";
  let date = tgm.Birthdate ?? "";
  let email = tgm.Email ?? "";
  let phone = tgm.MobilePhone ?? "";

  const validFirstName = isValidFirstAndLastName(firstName) && !hasMiddleName(firstName);

  const validDate =
    isDateValidation(date) && !IsDateSmallerThan_1900_1_1_Validation(date) && IsDateBiggerThanTodayValidation(date);

  const validEmail =
    (isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0;

  const validPhone = isPhoneValidation(phone, true) || phone === 0 || phone === "+43";

  return validFirstName && validDate && validEmail && validPhone;
};

export const CountryByIdOrSame = (data, name) => {
  try {
    return data.filter((c) => c.Name === name)[0].Id;
  } catch (error) {
    return name;
  }
};

export function hasNumber(str) {
  return /\d/.test(str);
}

export function isPasswordValid(password) {
  return (
    isInLengthRangeValidation(password, 8, 9999) &&
    !password.includes(" ") &&
    hasCapitalLetterValidation(password) &&
    hasDigitValidation(password) &&
    hasSpecialSymbolValidation(password) &&
    hasSmallLetterValidation(password)
  )
}

export function arePasswordsValid(passwords) {
  return (
    isPasswordValid(passwords.password) &&
    passwords.confirmPassword === passwords.password
  )
}
