import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { getKeyByValue, prefilledUserData } from "../../../utils/helpers";
import { hasMinLettersValidation, hasNumber, hasOnlyLettersValidation } from "../../../utils/validation";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import Input from "../utils/Input";
import WeiterButton from "../utils/WeiterButton";

//HINT* NOT REGISTRATION
const Name = () => {
  const history = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((2 / 19) * 100),
        title: t("pageTitle.createTripPages"),
      };
    });
  }, []);

  const [fullName, setFullName] = useState({
    firstName: "",
    lastName: "",
  });

  const [errors, setErrors] = useState({
    firstName: { hasOnlyLetters: false, hasMinTwoLetters: false },
    lastName: { hasOnlyLetters: false, hasMinTwoLetters: false },
  });

  const isValid = () => {
    return !(getKeyByValue(errors.firstName, false) || getKeyByValue(errors.lastName, false));
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        FirstName: fullName.firstName,
        LastName: fullName.lastName,
      };
    });
  };

  useEffect(() => {
    setFullName((curr) => {
      return {
        ...curr,
        firstName: user.FirstName ?? "",
        lastName: user.LastName ?? "",
      };
    });
  }, [user]);

  useEffect(() => {
    Object.keys(fullName).forEach((key) => {
      setErrors((currErrs) => {
        return {
          ...currErrs,
          [key]: {
            ...currErrs[key],
            hasOnlyLetters: hasOnlyLettersValidation(fullName[key]) || true,
            hasMinTwoLetters: hasMinLettersValidation(fullName[key], 2),
          },
        };
      });
    });
  }, [fullName]);

  const onChangeHandler = (e) => {
    setFullName((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history(ApplicationRoute.registerBirthday);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <ContentTitle text={prefilledUserData("prefilledUserData.name")} id="register-name" />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="register-username-form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="register-name"
      >
        <Input
          id="firstName"
          name="firstName"
          type="text"
          htmlFor="firstName"
          placeholder="input.vorname.text"
          value={fullName.firstName}
          onChange={onChangeHandler}
          labelText="input.vorname.text"
          showValidationBorder={fullName.firstName.length > 0 && !hasMinLettersValidation(fullName.firstName)}
        />
        <InputValidation
          text={
            hasNumber(fullName.firstName)
              ? "errors.nameHasNumber"
              : "errors.firstNameLessCharacters"
          }
          isHidden={!fullName.firstName.length > 0 || hasMinLettersValidation(fullName.firstName)}
        />

        <Input
          id="lastName"
          name="lastName"
          type="text"
          htmlFor="lastName"
          placeholder="input.nachname.text"
          value={fullName.lastName}
          onChange={onChangeHandler}
          labelText="input.nachname.text"
          showValidationBorder={fullName.lastName.length > 0 && !hasMinLettersValidation(fullName.lastName)}
        />
        <InputValidation
          text={
            hasNumber(fullName.lastName)
              ? "errors.nameHasNumber"
              : "errors.lastNameLessCharacters"
          }
          isHidden={!fullName.lastName.length > 0 || hasMinLettersValidation(fullName.lastName)}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.registerBirthday}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default Name;