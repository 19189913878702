import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { phoneFormatter, prefilledUserData } from "../../../utils/helpers";
import { isPhoneValidation } from "../../../utils/validation";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import Input from "../utils/Input";
import WeiterButton from "../utils/WeiterButton";

//HINT* NOT REGISTRATION
const MobilePhonePage = () => {
  const history = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.documentElement.addEventListener("click", activate);

    return () => document.documentElement.removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((6 / 19) * 100),
        title: t("pageTitle.createTripPages"),
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber);
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, PhoneNumber: phoneNumber || null };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history(ApplicationRoute.registerEmail);
    }
  };

  useEffect(() => {
    setPhoneNumber(user.PhoneNumber ?? "");
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <ContentTitle id="register-phone" text={prefilledUserData("prefilledUserData.mobilenum")} />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="register-phone">
        <Input
          id="phoneNumber"
          name="phoneNumber"
          htmlFor="phoneNumber"
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder="input.mobilenum.placeholder"
          type="tel"
          labelText="input.mobilenum.text"
          labelStyle={{ display: "inline-block" }}
          showValidationBorder={active && !isValid()}
        />
        <InputValidation
          isHidden={!active || isValid()}
          text="errors.notValidPhoneNumber"
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.registerEmail}
        onClick={onClickHandler}
        className="floating-weiter-buton"
      />
    </div>
  );
};

export default MobilePhonePage;